export class MFASendCodeData {
    constructor(phoneMasked: string, data: MFASendCodeFormData) {
        this.phoneMasked = phoneMasked;
        this.fieldName = data.fieldName;
        this.phoneContactMethod = data.phoneContactMethod;
    }
    
    phoneMasked: string = '';
    fieldName: string = '';
    phoneContactMethod: string = '';
}

export class MFASendCodeFormData {
    fieldName: string = '';
    phoneContactMethod: string = '';
}