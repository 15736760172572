// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// DSS Documentation on Angular Reuse - http://tiny.sc/cgangularreuse
// DSS Documentation on SocoAlertService - http://tiny.sc/cgsocoalertservice
import { SocoAlertService } from '@soco/alert';

@Component({
  templateUrl: 'validate-email-later.component.html'
})
export class ValidateEmailLaterComponent implements OnInit {
    counter : number = 5;
    alertId: string = 'later-validate-email-alert';

    constructor(
        private router: Router,
        private socoAlertService: SocoAlertService
    ) { }

    ngOnInit() {
        setTimeout(() => {
            this.socoAlertService.caution({ id: this.alertId, scrollToAlert: true, message: 'Validating your email address is important. You will continue to receive this page each time you login until you have done so.' });
        });
        let countInterval = window.setInterval(() => {
            this.counter--;
            if (this.counter <= 0) {
                window.clearInterval(countInterval);
                this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
            }
        }, 1000.0);
    }
}