<div class="container">
    <soco-alert [alertId]="alertId"></soco-alert>
    <soco-system-message *ngIf="isLoading" messageType="Loading" message="Loading your configuration..."></soco-system-message> 
    <div *ngIf="!isLoading" class="mb-3">
        <h2><i class="fa mr-2 fa-check-circle text-success"></i>{{otpStateSlotDesc}} Two-Factor Method Configured</h2>
        <dt>{{userMessage}}</dt>
    </div>
    <div *ngIf="!isLoading" class="text-center">
        <button mat-raised-button color="primary" type="submit" class="mb-3 w-100" (click)="continueOtp()">Next</button>
    </div>
</div>
