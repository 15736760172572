// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MFAService } from '../../mfa.service';
import { MFA2FAMethod } from '../../mfa-2fa-method.enum';
import { MFA2FASlot } from '../../mfa-2fa-slot.enum';
import { MFA2FAConfigItem, MFA2FAConfigResult } from '../../mfa-2fa-config-data';

@Component({
    templateUrl: 'otp-slots.component.html'
})
export class OtpSlotsComponent implements AfterViewInit, OnDestroy {
    form: FormGroup;
    alertId: string = 'otp-slots-alert';
    isLoading: boolean = false;
    otpSlots = [MFA2FASlot.Primary, MFA2FASlot.Secondary];
    mfa2faConfig: MFA2FAConfigResult = null;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private mfaService: MFAService,
        private formBuilder: FormBuilder,
        private router: Router) { }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.isLoading = true;
            //initialize the form
            this.form = this.formBuilder.group({
                slot: [null, Validators.required]
            });
            this.load2faConfig();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    otpMethodPhrase(otpSlot: MFA2FASlot): string {
        let slotConfigItem: MFA2FAConfigItem;
        if (otpSlot === MFA2FASlot.Primary) {
            slotConfigItem = this.mfa2faConfig.primary;
        } else if (otpSlot === MFA2FASlot.Secondary) {
            slotConfigItem = this.mfa2faConfig.secondary;
        } else {
            return '';
        }
        switch (slotConfigItem.method) {
            case MFA2FAMethod.Sms:
                return 'Send me a text at ' + slotConfigItem.maskedPhoneNumber;
            case MFA2FAMethod.Totp:
                return 'Enter code from Authenticator App';
            case MFA2FAMethod.Voice:
                return 'Call my phone at ' + slotConfigItem.maskedPhoneNumber;
            default: return '';
        }
    }

    otpDescription(otpSlot: MFA2FASlot): string {
        if (otpSlot === MFA2FASlot.Primary) {
            return this.mfa2faConfig.primary.description;
        }
        if (otpSlot === MFA2FASlot.Secondary) {
            return this.mfa2faConfig.secondary.description;
        }
        return '';
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    formSubmitted(): void {
        if (this.form.valid) {
            this.isLoading = true;
            let otpSlot: MFA2FASlot = this.form.controls['slot'].value;
            let otpMethod: MFA2FAMethod = otpSlot === MFA2FASlot.Primary ? this.mfa2faConfig.primary.method : this.mfa2faConfig.secondary.method;
            this.router.navigate(['/mfa/otp/validate'], { queryParamsHandling: 'preserve', state: { data: { otpStateSlot: otpSlot, otpStateMethod: otpMethod } } });
        }
    }

    cancel(): void {
        this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private load2faConfig(): void {
        this.mfaService.get2faConfig({ alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.mfa2faConfig = result.data;
            this.isLoading = false;
        }, error => {
            this.form = null;
            this.isLoading = false;
        });
    }
}
