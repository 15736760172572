<div class="container">
    <h2 *ngIf="form" class="page-title">Edit Email Address</h2>
    <soco-alert [alertId]="alertId"></soco-alert>
    <soco-form *ngIf="form" [formGroup]="form" [form]="form" (formSubmitted)="formSubmitted($event)"
        [appFormValuesAreEqualCrossValidator]="[{controls:[form.get('emailAddress'), form.get('retypeEmailAddress')],ignoreCase:true}]">
        <soco-form-body>
            <soco-form-section>
                <p>Please validate your email address below. Once you have done so, you will receive an email with a link to validate your updated email address.</p>
                <mat-form-field>
                    <mat-label>Current Email</mat-label>
                    <input matInput formControlName="currentEmailAddress" readonly>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>New Email</mat-label>
                    <input matInput formControlName="emailAddress" maxlength="64" appEmailValidator>
                    <mat-error *ngIf="form.get('emailAddress').hasError('required')">Email Address is required</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('maxlength')">Email Address must be {{ form.get('emailAddress').errors.maxlength.requiredLength }} characters or less</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('email') || form.get('emailAddress').hasError('socoEmailValidation')">Please enter a valid email address</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('socoEmail')">Southern Company employees are already registered. Enter your Southern Company email and password at the Login page.</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('formValuesAreEqualCrossValidator')">Email addresses must match</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('server')">{{form.get('emailAddress').errors.server}}</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Retype Email</mat-label>
                    <input matInput formControlName="retypeEmailAddress">
                    <mat-error *ngIf="form.get('retypeEmailAddress').hasError('required')">Please retype your email address to ensure correct entry</mat-error>
                    <mat-error *ngIf="form.get('retypeEmailAddress').hasError('formValuesAreEqualCrossValidator')">Email addresses must match</mat-error>
                    <mat-error *ngIf="form.get('retypeEmailAddress').hasError('server')">{{ form.get('retypeEmailAddress').errors.server }}</mat-error>
                </mat-form-field>
            </soco-form-section>
        </soco-form-body>
        <soco-form-footer>
            <button mat-raised-button color="primary" type="submit" [disabled]="(formSubmitAttempted || !form.valid)">Submit</button>
            <a mat-button (click)="goBack()">Go Back</a>
        </soco-form-footer>
    </soco-form>
    <app-customer-service></app-customer-service>
    <soco-system-message *ngIf="isLoading" messageType="Loading" message="Sending your validation email..."></soco-system-message>
</div>