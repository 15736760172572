// DSS Documentation on Angular - http://tiny.sc/cgangular
import { Directive, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { CrossValidatorDefinition } from './cross-validator-definition';

const ErrorName = 'formValuesNotEqualCrossValidator';

@Directive({
  selector: '[appFormValuesNotEqualCrossValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: FormValuesNotEqualCrossValidatorDirective, multi: true}]
})
export class FormValuesNotEqualCrossValidatorDirective implements Validator {
  @Input() private appFormValuesNotEqualCrossValidator: CrossValidatorDefinition[];

  validate(control: AbstractControl): ValidationErrors {
    this.appFormValuesNotEqualCrossValidator.forEach(notEqualSet => {
      const a = notEqualSet.controls[0];
      const b = notEqualSet.controls[1];
      let aValue: any = a.value;
      if (typeof aValue === 'string' && notEqualSet.ignoreCase)
        aValue = (<string>aValue).toLowerCase();
      let bValue: any = b.value;
      if (typeof bValue === 'string' && notEqualSet.ignoreCase)
        bValue = (<string>bValue).toLowerCase();
      if (!!aValue && !!bValue && aValue === bValue) {
        let errors: ValidationErrors
        errors = b.errors || {};
        errors[ErrorName] = a;
        b.setErrors(errors);
      } else {
        if (b.hasError(ErrorName)) {
          delete b.errors[ErrorName];
          b.updateValueAndValidity();
        }
      }
    });
    return null;
  }

}
