// DSS Documentation on Angular - http://tiny.sc/cgangular
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Params } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';

import { AppType } from './app/app-type.enum';
import { environment } from '../environments/environment';
import { QueryStringService } from './core/query-string.service';
import { TargetPage } from './core/target-page.enum';
import { TokenService } from './core/token.service';
import { TokenResult } from './core/token-data';
import { MFAService } from './mfa/mfa.service';
import { MFARequiredMultiFactorTypeData, MFAType } from './mfa/mfa-required-multi-factor-type-data';
import { MFA2FAMethod } from './mfa/mfa-2fa-method.enum';
import { MFA2FASlot } from './mfa/mfa-2fa-slot.enum';

@Injectable({
    providedIn: 'root'
})
export class AppGuard implements CanActivate {
    constructor(
        private queryStringService: QueryStringService,
        private tokenService: TokenService,
        private mfaService: MFAService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        if (!route.data) {
            return true;
        }

        if (route.data.validateQueryStringParameters) {
            if (!this.queryStringService.isValid()) {
                this.router.navigate(['/request-not-valid'], { queryParams: route.queryParams });
                return false;
            }
        }

        if (route.data.validateOAuthConsentQueryStringParameters) {
            if (!this.queryStringService.oAuthConsentIsValid()) {
                this.router.navigate(['/request-not-valid'], { queryParams: route.queryParams, state: { isOAuthConsentValidationFailure: true } });
                return false;
            }
        }

        if (route.data.validate2FAConfigured) {
            // if the page requires 2fa to be configured
            // 1. validate the user is in an authenticated state, if not they will route to login
            // 2. validate the user has set up 2fa on the account, if not they will be taken to start 2fa setup
            return this.validateAuthentication(route).pipe(mergeMap(isAuthenticated =>
                !isAuthenticated ? of(false) : this.validate2FAConfigured(route)));
        }

        if (route.data.validate2FAOr2FANotConfigured || (route.data.validate2FAOr2FANotConfiguredOnlyIfB2B && this.queryStringService.type == AppType.B2B)) {
            // if the page requires 2fa to be complete or 2fa to not be configured yet
            // 1. validate the user is in an authenticated state, if not they will route to login
            // 2. check if the user has not configured 2fa completely yet, if so, early exit
            // 3. validate the user has completed 2fa, if not, they will be taken to primary 2fa validation
            return this.validateAuthentication(route).pipe(mergeMap(isAuthenticated =>
                !isAuthenticated ? of(false) : this.validate2FANotConfigured().pipe(mergeMap(is2faNotConfigured =>
                    is2faNotConfigured ? of(true) : this.validate2FA(route)))));
        }

        if (route.data.validateAuthentication) {
            // validate the user is in an authenticated state, if not they will route to login
            return this.validateAuthentication(route);
        }

        return true;
    }

    private validateAuthentication(route: ActivatedRouteSnapshot): Observable<boolean> {
        let queryParams = this.getQueryParamsForRoute(route);
        let targetPage: string = route.data.targetPage;
        return this.tokenService.get().pipe(map((tokenResult: TokenResult) => {
            let userIsAuthenticated = tokenResult != null && tokenResult.token != null;
            let oauthCookieFound = tokenResult != null && tokenResult.hasOAuthCookie;
            if (!userIsAuthenticated || (route.data.validateHasOAuthCookie && !oauthCookieFound)) {
                if (TargetPage[targetPage] === TargetPage.OAuthConsent) {
                    window.location.href = environment.oauthBaseUrl + this.queryStringService.oAuthConsentReturnUrl;
                    return false;
                } else {
                    this.router.navigate(['/login'], { queryParams });
                }
            }
            return userIsAuthenticated;
        }),
        catchError((err: string) => {
            if (TargetPage[targetPage] === TargetPage.OAuthConsent) {
                window.location.href = environment.oauthBaseUrl + this.queryStringService.oAuthConsentReturnUrl;
                return of(false);
            } else {
                this.router.navigate(['/login'], { queryParams });
            }
            return of(false);
        }));
    }

    private validate2FA(route: ActivatedRouteSnapshot): Observable<boolean> {
        const requiredMfaData: MFARequiredMultiFactorTypeData = { mfaConfigInternal: 4, mfaConfigUnknown: 4, mfaExcludeInternal: '', mfaExcludeUnknown: '' };
        return this.mfaService.getRequiredMultiFactorType(requiredMfaData)
            .pipe(map(mfaResult => MFAType[mfaResult.data] == MFAType.None), catchError(() => of(false)))
            .pipe(mergeMap(validated2fa => {
                if (!validated2fa) {
                    return this.mfaService.get2faConfig().pipe(map(configResult => {
                        let queryParams = this.getQueryParamsForRoute(route);
                        if (configResult.data.primary.method === MFA2FAMethod.None) {
                            // go to configure primary
                            this.router.navigate(['/mfa/otp/selection'], { queryParams, state: { data: { otpStateSlot: MFA2FASlot.Primary } } });
                        } else if (configResult.data.secondary.method === MFA2FAMethod.None) {
                            // go to configure secondary
                            this.router.navigate(['/mfa/otp/selection'], { queryParams, state: { data: { otpStateSlot: MFA2FASlot.Secondary } } });
                        } else {
                            // go to validate primary
                            this.router.navigate(['/mfa/otp/validate'], { queryParams, state: { data: { otpStateSlot: MFA2FASlot.Primary, otpStateMethod: configResult.data.primary.method } } });
                        }
                        return false;
                    }));
                }
                return of(true);
            }));
    }

    private validate2FAConfigured(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.mfaService.get2faConfig().pipe(map(configResult => {
            let isConfigured: boolean = configResult.data.primary.method !== MFA2FAMethod.None && configResult.data.secondary.method !== MFA2FAMethod.None;
            if (!isConfigured) {
                let queryParams = this.getQueryParamsForRoute(route);
                if (configResult.data.primary.method === MFA2FAMethod.None) {
                    // go to configure primary
                    this.router.navigate(['/mfa/otp/selection'], { queryParams, state: { data: { otpStateSlot: MFA2FASlot.Primary } } });
                } else if (configResult.data.secondary.method === MFA2FAMethod.None) {
                    // go to configure secondary
                    this.router.navigate(['/mfa/otp/selection'], { queryParams, state: { data: { otpStateSlot: MFA2FASlot.Secondary } } });
                }
            }
            return isConfigured;
        }));
    }

    private validate2FANotConfigured(): Observable<boolean> {
        return this.mfaService.get2faConfig().pipe(map(configResult => {
            let isNotConfigured: boolean = configResult.data.primary.method === MFA2FAMethod.None || configResult.data.secondary.method === MFA2FAMethod.None;
            return isNotConfigured;
        }));
    }

    private getQueryParamsForRoute(route: ActivatedRouteSnapshot): Params {
        let queryParams = Object.assign({}, route.queryParams);
        let targetPage: string = route.data.targetPage;
        if (route.data.targetPage) {
            if (TargetPage[targetPage] === TargetPage.OAuthGrants) {
                queryParams['WL_ReturnUrl'] = environment.oauthBaseUrl + '/OAuth/LogonToken?OAuthReturnUrl=' + encodeURIComponent(environment.baseUrl + '/oauth/grants');
            } else {
                queryParams['WL_TargetPage'] = route.data.targetPage;
            }
        }
        return queryParams;
    }
}
