// DSS Documentation on Angular - http://tiny.sc/cgangular
import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';

import { isValidEmailAddress } from './email-validator.directive';

@Directive({
  selector: '[appUsernameValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: UsernameValidatorDirective, multi: true}]
})
export class UsernameValidatorDirective implements Validator {
  @Input() private isNewUsername: boolean = false;

  validate(control: AbstractControl): ValidationErrors {
    const allowedChars: string = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890 /-_.&*,\'>#@$!*+?~();^=:';
    const username: string = control.value;
    if (!username) {
      return null;
    }
    for (var i = 0; i < username.length; i++) {
      if (allowedChars.indexOf(username.charAt(i)) < 0) {
        return { 'username': { value: username } };
      }
    }
    if (this.isNewUsername) {
      if (!isValidEmailAddress(username) && /[^a-z0-9]/i.test(username)) {
        return { 'username': { value: username } };
      } else if (/@southernco(mpany)?(\.com)?$/i.test(username)) {
        let atIndex = username.indexOf('@');
        let suffix = username.substr(atIndex, username.length - atIndex);
        return { 'socoEmail': { 'suffixUsed': suffix } };
      }
    }
    return null;
  }
}
