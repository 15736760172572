// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MFAValidateOTPData } from '../../mfa-validate-otp-data';
import { MFAService } from '../../mfa.service';
import { MFA2FAMethod } from '../../mfa-2fa-method.enum';
import { MFA2FASlot } from '../../mfa-2fa-slot.enum';

@Component({
    templateUrl: 'otp-validate.component.html'
})
export class OtpValidateComponent implements AfterViewInit, OnDestroy {
    form: FormGroup;
    alertId: string = 'otp-validate-alert';
    isLoading: boolean = false;
    title: string = '';
    isInitialValidation: boolean = false;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private mfaService: MFAService,
        private formBuilder: FormBuilder,
        private router: Router) { }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.isLoading = true;
            let otpSecretResult = this.mfaService.otpStateSecretResult();
            this.isInitialValidation = !!otpSecretResult;
            this.title = 'Enter Code';
            if (this.isInitialValidation) {
                switch (this.mfaService.otpStateMethod()) {
                    case MFA2FAMethod.Sms:
                    case MFA2FAMethod.Voice:
                        {
                            this.title = 'Enter Code Received at ' + otpSecretResult.phoneNumber;
                            break;
                        }
                    default:
                        break;
                }
            } else {
                switch (this.mfaService.otpStateMethod()) {
                    case MFA2FAMethod.Sms:
                    case MFA2FAMethod.Voice:
                        {
                            this.mfaService.get2faConfig({ alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
                                let maskedPhoneNumber: string;
                                switch (this.mfaService.otpStateSlot())
                                {
                                    case MFA2FASlot.Primary:
                                        maskedPhoneNumber = result.data.primary.maskedPhoneNumber;
                                        break;
                                    case MFA2FASlot.Secondary:
                                        maskedPhoneNumber = result.data.secondary.maskedPhoneNumber;
                                        break;
                                    default: return;
                                }
                                this.title = 'Enter Code Received at ' + maskedPhoneNumber;
                            });
                            break;
                        }
                    case MFA2FAMethod.Totp:
                        {
                            this.title = 'Enter Code Received by Authenticator App';
                            break;
                        }
                    default:
                        break;
                }
            }
            //initialize the form
            this.form = this.formBuilder.group({
                slot: [this.mfaService.otpStateSlot()],
                method: [this.mfaService.otpStateMethod()],
                password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
                description: [this.mfaService.otpStateDescription()],
                phoneNumber: [otpSecretResult?.phoneNumber],
                secret: [otpSecretResult?.secret],
                mac: [otpSecretResult?.mac]
            });
            if (!this.isInitialValidation && (this.mfaService.otpStateMethod() === MFA2FAMethod.Sms || this.mfaService.otpStateMethod() === MFA2FAMethod.Voice)) {
                this.sendPhoneOtp();
            } else {
                this.isLoading = false;
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    formSubmitted(data: MFAValidateOTPData): void {
        if (this.form.valid) {
            this.form.get('password').setValue('');
            this.isLoading = true;
            this.validateOtp(data);
        }
    }

    cancel(): void {
        this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private sendPhoneOtp(): void {
        this.mfaService.sendPhoneOtp({ slot: this.mfaService.otpStateSlot() }, { alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
        });
    }

    private validateOtp(data: MFAValidateOTPData): void {
        this.mfaService.validateOtp(data, { alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            if (this.isInitialValidation) {
                // if this is initial validation, go to the confirmation page and continue the setup process
                this.router.navigate(['/mfa/otp/confirmation'], { queryParamsHandling: 'preserve' , state: { data: { otpStateSlot: this.mfaService.otpStateSlot() } } });
            } else {
                // otherwise complete login as normal with data cleared
                this.router.navigate(['/login'], { queryParamsHandling: 'preserve' , state: { data: { } } });
            }
        }, error => {
            this.isLoading = false;
        });
    }
}
