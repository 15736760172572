import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SocoAlertService } from '@soco/alert';

// DSS Documentation on Angular Reuse - http://tiny.sc/cgangularreuse
// DSS Documentation on SocoFormService - http://tiny.sc/cgsocoformservice
import { SocoFormService } from '@soco/forms';

import { ApiResultService } from '../../core/api-result.service';
import { UserPasswordChangeResult, UserPasswordChangeData } from '../../user/user-password-change-data';
import { UserService } from '../../user/user.service';

@Component({
    templateUrl: 'password-change.component.html'
})
export class PasswordChangeComponent implements OnInit, OnDestroy {
    form: FormGroup;
    alertId: string = 'password-change-alert';
    isLoading: boolean = false;
    result: UserPasswordChangeResult = null;

    private unsubscribe$ = new Subject<void>();
    
    constructor(
        private apiResultService: ApiResultService,
        private userService: UserService,
        private socoAlertService: SocoAlertService,
        private socoFormService: SocoFormService,
        private el: ElementRef,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        this.form = this.formBuilder.group({
            username: ['', Validators.required],
            oldPassword: ['', Validators.required],
            password: ['', [Validators.required, Validators.maxLength(32), Validators.pattern(/^\S*$/)]],
            confirmPassword: ['', Validators.required]
        });
        
        this.checkForExpiredPassword();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    formSubmitted(data: UserPasswordChangeData): void {
        if (!this.form.valid ) {
            this.socoFormService.scrollToFirstInvalidControl(this.el);
            return;
        }

        this.changePassword(data);
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private changePassword(data: UserPasswordChangeData): void {
        this.isLoading = true;
        this.userService.changePassword(data).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.result = result.data;
            if (this.result) {
                this.socoAlertService.success({ id: this.alertId, message: 'Your password has been changed!' });
            }
            this.isLoading = false;
        }, result => {
            this.isLoading = false;
            this.apiResultService.handleHttpErrorResponse(result, { alertId: this.alertId, form: this.form, el: this.el });
        });
    }

    //function to check history.state for Expired password information.
    private checkForExpiredPassword(): void{
        if(!!history.state){ //Make sure state is used.
            if(!!history.state.data){ //Make sure data is used.
                if(!!history.state.data.userId && !!history.state.data.expireMessage){ //make sure it's the data we want.
                    //pre-populate username with the Expired userID.
                    let userId: string = history.state.data.userId
                    this.form.controls['username'].setValue(userId);
                    //Display error message stating that user's password is expired. 
                    setTimeout(() => {
                        this.socoAlertService.error({
                            id: this.alertId,
                            scrollToAlert: false,
                            message: history.state.data.expireMessage,
                            type: 1
                        });
                    });
                }
            }
        }
    }
}