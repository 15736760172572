// DSS Documentation on Angular - http://tiny.sc/cgangular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// DSS Documentation on ApiResult - http://tiny.sc/cgapiresult
import { ApiResult } from '@soco/core';

import { ApiResultFailureOptions } from '../../core/api-result.service';
import { environment } from '../../../environments/environment';
import { OAuthGrantsView } from './oauth-grants-view';
import { WebAuthHttpService } from '../../core/webauth-http.service';

@Injectable({
    providedIn: 'root'
})
export class OAuthGrantsService {
    private baseUrl = environment.oauthBaseUrl + '/api/Grants';

    constructor(
        private http: WebAuthHttpService
    ) { }

    /**
     *  Gets the data needed to display the grants the user has previously given to various applications
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    viewModel(failureOptions?: ApiResultFailureOptions): Observable<ApiResult<OAuthGrantsView>> {
        return this.http.get<OAuthGrantsView>({
            url: this.baseUrl + '/ViewModel',
            excludeWebApiBaseUrl: true,
            withCredentials: true,
            failureOptions: failureOptions
        });
    }

    /**
     *  Revokes the clients access to the specified scopes
     *  @param clientId The id of the client to revoke access to
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    revoke(clientId: string, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<string>> {
        return this.http.post<string>({
            url: this.baseUrl + '/Revoke',
            excludeWebApiBaseUrl: true,
            excludeDataAddCommonData: true,
            withCredentials: true,
            data: clientId,
            failureOptions: failureOptions
        });
    }
}