// DSS Documentation on Angular - http://tiny.sc/cgangular
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { TokenResult } from './token-data';
import { WebAuthHttpService } from './webauth-http.service';

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    baseUrl: string = 'Token';
    private cached: boolean = false;
    private token: string = null;
    private hasOAuthCookie: boolean = false;

    constructor(private http: WebAuthHttpService) { }

    get(decrypt: boolean = false): Observable<TokenResult> {
        // decrypted token is not cached by the service
        if (!decrypt) {
            if (this.token != null) {
                return of({ token: this.token, hasOAuthCookie: this.hasOAuthCookie });
            }
            if (this.cached) {
                return of({ token: null, hasOAuthCookie: false });
            }
        }
        return this.http.get<TokenResult>({
            url: this.baseUrl,
            params: { decrypt },
            token: this.token,
            withCredentials: true
        }).pipe(map(result => {
            if (!decrypt) {
                this.cached = true;
                this.token = result.data.token;
                this.hasOAuthCookie = result.data.hasOAuthCookie;
            }
            return { token: result.data.token, hasOAuthCookie: result.data.hasOAuthCookie };
        }), catchError(err => {
            if (!decrypt) {
                this.cached = true;
            }
            throw 'failed to get token';
        }));
    }

    invalidate(): void {
        this.cached = true;
        this.token = null;
    }

    set(token: string): void {
        this.cached = true;
        this.token = token;
    }
}
