import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

//DSS Documentation on SocoModalService - http://tiny.sc/cgsocomodalservice
import { SocoModalService } from '@soco/modal';

import { MFA2FASlot } from '../../mfa-2fa-slot.enum';
import { MFA2FAMethod } from '../../mfa-2fa-method.enum';
import { MFAService } from '../../mfa.service';

@Component({
    templateUrl: 'otp-selection.component.html'
})
export class OtpSelectionComponent implements AfterViewInit, OnDestroy {
    form: FormGroup;
    alertId: string = 'otp-selection-alert';
    otpMethods: Array<OTPMethod>;
    isLoading: boolean = false;
    otpStateSlot: MFA2FASlot;
    otpStateSlotString: string;
    primaryOtpConfigMethod: MFA2FAMethod;
    secondaryOtpConfigMethod: MFA2FAMethod;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private socoModalService: SocoModalService,
        private mfaService: MFAService,
        private formBuilder: FormBuilder,
        private router: Router
    ) { }


    ngAfterViewInit(): void {
        // perform a sso check each time the user lands on /login after the view initializes
        setTimeout(() => {
            this.form = this.formBuilder.group({
                preferredOTPMethod: [null, Validators.required]
            });

            this.isLoading = true;

            this.otpStateSlot = this.mfaService.otpStateSlot();
            if(this.otpStateSlot == MFA2FASlot.Primary ){
                this.otpStateSlotString = "Primary";
            }
            if(this.otpStateSlot == MFA2FASlot.Secondary ){
                this.otpStateSlotString = "Secondary";
            }
            this.getOTPConfigData();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    cancel(): void {
        history.back();
    }

    formSubmitted(): void {
        if (this.form.valid) {
            let methodSelected: MFA2FAMethod = this.form.get('preferredOTPMethod').value;
            let selectedRoute: string;
            switch (methodSelected) {
                case MFA2FAMethod.Voice:
                case MFA2FAMethod.Sms: { 
                    selectedRoute = "/mfa/otp/phone/new";
                    break; 
                } 
                case MFA2FAMethod.Totp: { 
                    selectedRoute = "/mfa/otp/authenticator/new";
                    break;
                }
                default: return;
            }
            //Navigate to Confirmation OTP by passing the Current State.
            this.router.navigate([selectedRoute], { queryParamsHandling: 'preserve' , state: { data: { otpStateSlot: this.mfaService.otpStateSlot(), otpStateMethod: methodSelected } } });
        }
    }

    showWhy(): void {
        let whyBody = ` <div>
                          <p>Two-Factor Authentication (2FA) is required to use this application.  2FA is a feature that adds an extra layer of security to your login.</p>
                        </div>
                        <span></span>
                        <div>
                          <p>
                          <b>How does it work?</b>
                          When you try to login, 2FA sends you a unique security code. When you enable 2FA, you can choose to receive the security code by text message, voice call, or authenticator app.
                          </p>
                        </div>`;
        this.socoModalService.info({
            title: 'Why do I need this?',
            body: whyBody,
        });
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private getOTPConfigData(): void {
        //Display the ability to modify 2FA and if they currently have it enabled or disabled. 
        this.mfaService.get2faConfig({ alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.primaryOtpConfigMethod = result.data?.primary.method;
            this.secondaryOtpConfigMethod = result.data?.secondary.method;
            this.populateList();
            this.isLoading = false;
        });
    }

    //=============================================================================================================
    //HELPER FUNCTIONS
    //=============================================================================================================
    private populateList(): void {
        this.otpMethods = new Array<OTPMethod>();
        let voiceMethod = new OTPMethod();
        voiceMethod.methodName = 'Receive code via voice call';
        voiceMethod.methodType = MFA2FAMethod.Voice;

        let textMethod = new OTPMethod();
        textMethod.methodName = 'Receive code via text';
        textMethod.methodType = MFA2FAMethod.Sms;

        let totpMethod = new OTPMethod();
        totpMethod.methodName = 'Use your Authenticator app';
        totpMethod.methodType = MFA2FAMethod.Totp;

        //for the case of editing existing, we want to select what the user currently has selected for Primary.
        if (this.otpStateSlot == MFA2FASlot.Primary && this.primaryOtpConfigMethod != null) {
            switch (this.primaryOtpConfigMethod) {
                case MFA2FAMethod.Voice: { 
                    voiceMethod.current = true;
                    break; 
                } 
                case MFA2FAMethod.Sms: { 
                    textMethod.current = true;
                    break; 
                } 
                case MFA2FAMethod.Totp: { 
                    totpMethod.current = true;
                    break; 
                }
                default: break;  
            } 
        }
        //for the case of editing existing, we want to select what the user currently has selected for Secondary.
        if (this.otpStateSlot == MFA2FASlot.Secondary && this.secondaryOtpConfigMethod != null) {
            switch (this.secondaryOtpConfigMethod) {
                case MFA2FAMethod.Voice: { 
                    voiceMethod.current = true;
                    break; 
                } 
                case MFA2FAMethod.Sms: { 
                    textMethod.current = true;
                    break; 
                } 
                case MFA2FAMethod.Totp: { 
                    totpMethod.current = true;
                    break; 
                }
                default: break;
            } 
        }
        //Always show these options, but make sure you user doesn't select the same phone number down the road.
        this.otpMethods.push(voiceMethod);
        this.otpMethods.push(textMethod);
        if(this.otpStateSlot == MFA2FASlot.Primary && this.secondaryOtpConfigMethod != MFA2FAMethod.Totp){
            //If creating or editing Primary make sure Secondary is not totp, before displaying totp options to the user.
            this.otpMethods.push(totpMethod);
        }
        if(this.otpStateSlot == MFA2FASlot.Secondary && this.primaryOtpConfigMethod != MFA2FAMethod.Totp){
            //If creating or editing Secondary make sure Secondary is not totp, before displaying totp options to the user.
            this.otpMethods.push(totpMethod);
        }
    }
    
}

class OTPMethod {
    methodName: string = '';
    methodType: MFA2FAMethod = MFA2FAMethod.None;
    current: boolean = false;
}
