// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// DSS Documentation on Angular Reuse - http://tiny.sc/cgangularreuse
// DSS Documentation on SocoFormService - http://tiny.sc/cgsocoformservice
import { SocoFormService } from '@soco/forms';

import { ApiResultService } from '../../core/api-result.service';
import { UserData } from '../../user/user-data';
import { UserEditData } from '../../user/user-edit-data';
import { UserEmailEditData } from '../../user/user-email-edit-data';
import { UserService } from '../../user/user.service';

@Component({
  templateUrl: 'validate-email-edit.component.html'
})

export class ValidateEmailEditComponent implements OnInit, OnDestroy {
    form: FormGroup;
    alertId: string = 'edit-validate-email-alert';
    isLoading: boolean = false;
  
    private unsubscribe$ = new Subject<void>();

    constructor(
        private apiResultService: ApiResultService,
        private userService: UserService,
        private socoFormService: SocoFormService,
        private el: ElementRef,
        private formBuilder: FormBuilder,
        private router: Router
    ) { }

    ngOnInit() {
        this.getUserData();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    init(userData: UserData) {
        this.form = this.formBuilder.group({
            currentEmailAddress: [userData.emailAddress],
            emailAddress: ['', [Validators.required, Validators.maxLength(64), Validators.email]],
            retypeEmailAddress: ['', Validators.required]
        });
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    formSubmitted(data: UserEmailEditData): void {
        if (!this.form.valid ) {
            this.socoFormService.scrollToFirstInvalidControl(this.el);
            return;
        }

        let userEditData = new UserEditData();
        userEditData.emailAddress = data.emailAddress;
        this.editUserData(userEditData);
    }

    goBack(): void {
        history.back();
    }
  
    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private getUserData(): void {
        this.userService.get({ alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.init(result.data);
        });
    }

    private editUserData(userData: UserEditData): void {
        this.isLoading = true;
        this.userService.edit(userData).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.router.navigate(['/validate-email/send'], { queryParamsHandling: 'preserve' });
        }, result => {
            this.isLoading = false;
            this.apiResultService.handleHttpErrorResponse(result, { alertId: this.alertId, form: this.form, el: this.el });
        });
    }
}
