// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PasswordComplexityRule } from './password-complexity-rule';
import { UserService } from '../../user/user.service';

@Component({
    selector: 'app-password-complexity',
    templateUrl: 'password-complexity.component.html'
})
export class PasswordComplexityComponent implements OnDestroy, OnInit {
    rules: PasswordComplexityRule[] = [];

    private unsubscribe$ = new Subject<void>();

    constructor(private userService: UserService) {}

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {
        this.userService.getPasswordComplexityRules().pipe(takeUntil(this.unsubscribe$)).subscribe(rulesResult => {
            this.rules = rulesResult.data;
        });
    }
  
    @Input() set password(value: string) {
        for (const rule of this.rules) {
            rule.validate(value);
        }
    }
}
