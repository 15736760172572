// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MFAValidateOTPData } from '../../../mfa-validate-otp-data';
import { MFA2FAMethod } from '../../../mfa-2fa-method.enum';
import { MFAService } from '../../../mfa.service';
import { UserService } from '../../../../user/user.service';

@Component({
    templateUrl: 'otp-authenticator-new.component.html'
})
export class OtpAuthenticatorNewComponent implements AfterViewInit, OnDestroy {
    form: FormGroup;
    alertId: string = 'otp-authenticator-new-alert';
    isLoading: boolean = false;
    qrdata: string = null;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private mfaService: MFAService,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private sanitizer: DomSanitizer,
        private router: Router) { }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.isLoading = true;
            this.loadNewOtpSecret();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    formSubmitted(data: MFAValidateOTPData): void {
        if (this.form.valid) {
            this.form.get('password').setValue('');
            this.isLoading = true;
            this.validateOtp(data);
        }
    }

    goBack(): void {
        history.back();
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private loadNewOtpSecret(): void {
        this.mfaService.newOtpSecret({
            method: MFA2FAMethod.Totp,
            slot: this.mfaService.otpStateSlot(),
            phoneNumber: null
        }, { alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(otpSecretResult => {
            //initialize the form
            this.form = this.formBuilder.group({
                slot: [this.mfaService.otpStateSlot()],
                method: [MFA2FAMethod.Totp],
                password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
                description: ['', Validators.maxLength(30)],
                secret: [otpSecretResult.data.secret],
                mac: [otpSecretResult.data.mac]
            });
            this.userService.get({ alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(userDataResult => {
                this.qrdata = this.mfaService.otpAuthUri('totp', userDataResult.data.username, otpSecretResult.data.secret);
                this.isLoading = false;
            });
        });
    }

    private validateOtp(data: MFAValidateOTPData): void {
        this.mfaService.validateOtp(data, { alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.router.navigate(['/mfa/otp/confirmation'], { queryParamsHandling: 'preserve' , state: { data: { otpStateSlot: this.mfaService.otpStateSlot() } } });
        }, error => {
            this.isLoading = false;
        });
    }
}
