//DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserData } from '../user/user-data';
import { UserService } from '../user/user.service';

@Component({
  templateUrl: 'validate-email.component.html'
})
export class ValidateEmailComponent  implements OnInit, OnDestroy  {
    form: FormGroup;
    alertId: string = 'validate-email-alert';
    profile: UserData = null;
    emailRecentlyChangedViaEditProfile: boolean = false;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private userService: UserService
    ) {}

    ngOnInit() {
        //determine if the user recently changed their email address via edit profile
        let state = window.history.state;
        if (state && state.emailRecentlyChangedViaEditProfile) {
            this.emailRecentlyChangedViaEditProfile = true;
        }

        //load the user's data
        this.getUserData();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private getUserData(): void {
        this.userService.get({ alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.profile = result.data;
        });
    }
}
