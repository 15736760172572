// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// DSS Documentation on Angular Reuse - http://tiny.sc/cgangularreuse
// DSS Documentation on SocoAlertService - http://tiny.sc/cgsocoalertservice
import { SocoAlertService } from '@soco/alert';

import { OAuthGrantsService } from './oauth-grants.service';
import { OAuthGrantsView } from './oauth-grants-view';
import { OAuthGrantView } from './oauth-grant-view';

@Component({
  templateUrl: 'oauth-grants.component.html'
})
export class OAuthGrantsComponent implements OnInit, OnDestroy {
    alertId: string = 'oauth-grants-alert';
    grantsView: OAuthGrantsView = null;
    isLoading: boolean = true;
    private unsubscribe$ = new Subject<void>();

    constructor(
        private oAuthGrantsService: OAuthGrantsService,
        private socoAlertService: SocoAlertService
    ) { }

    ngOnInit() {
        this.isLoading = true;
        this.getGrantsViewModel();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    revoke(grant: OAuthGrantView): void {
        this.revokeClient(grant.clientId);
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private getGrantsViewModel(): void {
        this.oAuthGrantsService.viewModel().pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.isLoading = false;
            this.grantsView = result.data;
            if (!this.grantsView.grants || this.grantsView.grants.length < 1) {
                setTimeout(() => {
                    this.socoAlertService.info({ id: this.alertId, scrollToAlert: true, message: 'You have not given access to any applications.' });
                });
            }
        });
    }

    private revokeClient(clientId: string): void {
        this.isLoading = true;
        this.oAuthGrantsService.revoke(clientId).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.getGrantsViewModel();
        });
    }
}