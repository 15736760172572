export enum AppType {
    None,
    Internal,
    External,
    B2B
}

export namespace AppType {
    export function parse(val: string): AppType {
        if (!val) {
            return AppType.External;
        }
        switch (val.charAt(0).toUpperCase()) {
            case 'I': return AppType.Internal;
            case 'B': return AppType.B2B;
            default: return AppType.External;
        }
    }
    export function toQueryStringValue(type: AppType): string {
        switch (type) {
            case AppType.Internal: return 'I';
            case AppType.B2B: return 'B';
            default: return 'E';
        }
    }
}