//DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn, NgForm, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// DSS Documentation on Angular Reuse - http://tiny.sc/cgangularreuse
// DSS Documentation on SocoAlertService - http://tiny.sc/cgsocoalertservice
import { SocoAlertService } from '@soco/alert';

import { MFA2FAMethod } from '../../mfa/mfa-2fa-method.enum';
import { MFAService } from '../../mfa/mfa.service';
import { ApiResultService } from '../../core/api-result.service';
import { QueryStringService } from '../../core/query-string.service';
import { Tel } from '../../core/tel-input/tel-input.component';
import { UserEditData, UserEditFormData } from '../../user/user-edit-data';
import { UserData } from '../../user/user-data';
import { UserSendEmailValidationCodeData, UserSendEmailValidationCodeResult } from '../../user/user-send-email-validation-code-data';
import { UserService } from '../../user/user.service';
import { AppService } from '../../app/app.service';

@Component({
    templateUrl: 'profile-edit.component.html'
})
export class ProfileEditComponent implements OnInit, OnDestroy {
    form: FormGroup;
    userData: UserData;
    alertId: string = 'profile-edit-alert';
    isLoading: boolean = false;
    isSouthernCo: boolean = false;
    isSocoB2b: boolean = false;
    is2faEnabled: boolean = false;
    visibleSection: string = null;
    title: string = 'Edit Profile';
    private userSendEmailValidationCodeResult: UserSendEmailValidationCodeResult = null;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private appService: AppService,
        private apiResultService: ApiResultService,
        private queryStringService: QueryStringService,
        private userService: UserService,
        private mfaService: MFAService,
        private socoAlertService: SocoAlertService,
        private formBuilder: FormBuilder,
        private el: ElementRef,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.getUserData();
    }
    
    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    init(userData: UserData) {
        this.setVisibleSection(null);
        this.userSendEmailValidationCodeResult = null;
        this.title = `Edit Profile`;
        this.userData = userData;
        let phone1Parts: Tel = null;
        if (userData.phone1) {
            let parts = userData.phone1.split('-')
            if (parts && parts.length === 3) {
                phone1Parts = {
                    area: parts[0],
                    exchange: parts[1],
                    subscriber: parts[2]
                };
            }
        }
        this.form = this.formBuilder.group({
            username: [userData.username, [Validators.required, Validators.maxLength(42)]],
            currentPassword: ['', [this.passwordRequiredValidator()]],
            password: ['', [this.passwordRequiredValidator(), Validators.maxLength(32), Validators.pattern(/^\S*$/)]],
            retypePassword: ['', this.passwordRequiredValidator()],
            firstName: [userData.firstName, [Validators.required, Validators.maxLength(50)]],
            lastName: [userData.lastName, [Validators.required, Validators.maxLength(60)]],
            emailAddress: [userData.emailAddress, [Validators.required, Validators.maxLength(64), Validators.email]],
            phone1: [userData.phone1, [Validators.required]],
            phone1Parts: [phone1Parts, [Validators.required]],
            emailValidationCode: [null, [Validators.required]]
        });
        this.isLoading = false;
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    formSubmitted(data: UserEditFormData, form: NgForm): void {
        if (!this.visibleSection) {
            this.router.navigate(['/login'], { queryParams: this.queryStringService.getQueryParams() });
        }
        else if (!this.formValid()) {
            return;
        }
        else {
            let userEditData = new UserEditData();
            switch (this.visibleSection) {
                case 'Name':
                    userEditData.firstName = data.firstName;
                    userEditData.lastName = data.lastName;
                    break;
                case 'Phone Number':
                    // validate that the tel values match the expected patterns
                    if (!data.phone1Parts || !/\d{3}/.test(data.phone1Parts.area) || !/\d{3}/.test(data.phone1Parts.exchange) || !/\d{4}/.test(data.phone1Parts.subscriber)) {
                        this.socoAlertService.error({ message: 'Please enter a valid phone number.', id: this.alertId, scrollToAlert: true });
                        return;
                    }
                    userEditData.phone1 = `${data.phone1Parts.area}-${data.phone1Parts.exchange}-${data.phone1Parts.subscriber}`;
                    break;
                case 'Email':
                    this.sendEmailValidationCode(new UserSendEmailValidationCodeData(data.emailAddress));
                    return;
                case 'Email Validation Code':
                    //if the username is currently the same as the email address and they change their email address, update username as well
                    if (this.isSocoB2b && this.userData.emailAddress === this.userData.username) {
                        userEditData.username = data.emailAddress;
                    }
                    userEditData.emailAddress = data.emailAddress;
                    userEditData.emailValidationCode = data.emailValidationCode;
                    userEditData.emailValidationCodeExpirationInUnixTimeSeconds = this.userSendEmailValidationCodeResult.emailValidationCodeExpirationInUnixTimeSeconds;
                    userEditData.emailValidationEncryptedSecret = this.userSendEmailValidationCodeResult.encryptedSecret;
                    userEditData.emailValidationMac = this.userSendEmailValidationCodeResult.mac;
                    break;
                case 'Username':
                    userEditData.username = data.username;
                    break;
                case 'Password':
                    userEditData.currentPassword = data.currentPassword;
                    userEditData.password = data.password;
                    break;
            }
            this.editUserData(userEditData, form);
        }
    }

    passwordChanged(): void {
        this.form.get('currentPassword').updateValueAndValidity();
        this.form.get('retypePassword').updateValueAndValidity();
        this.form.get('password').updateValueAndValidity();
    }

    isSectionVisible(sectionName: string): boolean {
        return this.visibleSection === sectionName;
     }
 
     setVisibleSection(sectionName: string): void {
        if (sectionName === 'Email' && !this.visibleSection) {
            this.form.get('emailAddress').setValue('');
        }
        this.visibleSection = sectionName;
        if (!this.visibleSection) {
            this.title = 'Edit Profile';
        }
        else {
            if (this.visibleSection === 'Email Validation Code') {
                this.title = 'Verify Email Address';
            }
            else {
                this.title = `Edit ${sectionName}`;
            }
            setTimeout(() => {
                const inputs: HTMLElement[] = this.el.nativeElement.querySelectorAll('input');
                if (inputs && inputs.length > 0) {
                    inputs[0].focus();
                }
            });
        }
     }

     cancel(form: NgForm): void {
        if (!this.visibleSection) {
            this.goToUrl(this.queryStringService.cancelUrl);
        }
        else {
            this.init(this.userData);
            this.socoAlertService.hide();
            form.resetForm();
        }
    }

    resendCodeOrEditEmail(): void {
        this.setVisibleSection('Email');
        this.form.get('emailValidationCode').reset();
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private getUserData(form?: NgForm): void {
        this.userService.get({ alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            // check for nameSpace == null first to account for CoolWebProfile behavior
            if (result.data.nameSpace == null || result.data.nameSpace.toUpperCase() == 'SOUTHERNCO') {
                this.isSouthernCo = true;
            } else if(result.data.nameSpace.toUpperCase() == 'SOCOB2B') {
                this.isSocoB2b = true;

                //Display the ability to modify 2FA and if they currently have it enabled or disabled. 
                this.mfaService.get2faConfig({ alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
                    if(result.data.primary.method != MFA2FAMethod.None)
                    {
                        if(result.data.secondary.method != MFA2FAMethod.None)
                        {
                            this.is2faEnabled = true;
                        }
                    }
                });
            }

            //if we are re-retrieving the user's data after they edited some portion of their profile,
            //reset the form so we reset the submitted state of the form
            if (form) {
                form.resetForm();
            }
            this.init(result.data);
        });
    }
    
    private editUserData(data: UserEditData, form: NgForm): void {
        this.isLoading = true;
        this.userService.edit(data).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            //If your application deep links to the WebAuth edit profile page and the user will always be authenticated
            //when they are editing their profile, there is no reason to POST the token back to your application. To
            //indicate to WebAuth that it just needs to redirect back to your application simply uncomment the line below
            //to set the ReturnMethod to Redirect and add the necessary imports to resolve any references
            //this.queryStringService.returnMethod = ReturnMethod.Redirect;
            this.socoAlertService.success({ id: this.alertId, message: 'Profile Updated', toast: true });
            this.getUserData(form);
        }, result => {
            this.isLoading = false;
            this.apiResultService.handleHttpErrorResponse(result, { alertId: this.alertId, form: this.form, el: this.el, showAlertIfModelErrorsDontResultInErrorMessagesBeingDisplayed: true });
        });
    }

    private sendEmailValidationCode(data: UserSendEmailValidationCodeData): void {
        this.isLoading = true;
        this.userService.sendEmailValidationCode(data).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.userSendEmailValidationCodeResult = result.data;
            this.setVisibleSection('Email Validation Code');
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this.apiResultService.handleHttpErrorResponse(error, { alertId: this.alertId, form: this.form, el: this.el });
        });
    }


    //=============================================================================================================
    //VALIDATION
    //=============================================================================================================
    passwordRequiredValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.parent) {
                return null;
            }
              
            if (control.parent.get('currentPassword').value || control.parent.get('password').value || control.parent.get('retypePassword').value) {
                return Validators.required(control); 
            }
            return null;
        };
    }

    formValid(): boolean {
        let isValid = false;
        switch (this.visibleSection) {
            case 'Name':
                isValid = this.form.get('firstName').valid;
                isValid = isValid && this.form.get('lastName').valid;
                break;
            case 'Phone Number':
                isValid = this.form.get('phone1Parts').valid;
                break;
            case 'Email':
                isValid = this.form.get('emailAddress').valid;
                break;
            case 'Email Validation Code':
                isValid = this.form.get('emailValidationCode').valid;
                break;
            case 'Username':
                isValid = this.form.get('username').valid;
                break;
            case 'Password':
                isValid = this.form.get('currentPassword').valid;
                isValid = isValid && this.form.get('password').valid;
                isValid = isValid && this.form.get('retypePassword').valid;
                break;
        }
        return isValid;
    }

    //=============================================================================================================
    //HELPER FUNCTIONS
    //=============================================================================================================
    private goToUrl(url: string): void {
        window.location.href = url;
    }
}
