// DSS Documentation on Angular - http://tiny.sc/cgangular
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppGuard } from './app-guard.service';

import { TargetPage } from './core/target-page.enum';

import { ValidateEmailSendComponent } from './validate-email/send/validate-email-send.component';
import { ValidateEmailEditComponent } from './validate-email/edit/validate-email-edit.component';
import { ForgotPasswordComponent } from './forgot/password/forgot-password.component';
import { ForgotUsernameComponent } from './forgot/username/forgot-username.component';
import { LoginComponent } from './login/login.component';
import { MFAPhonePassCodeComponent } from './mfa/phone/pass-code/mfa-phone-pass-code.component';
import { MFAPhoneSelectionComponent } from './mfa/phone/selection/mfa-phone-selection.component';
import { MFARsaPassCodeComponent } from './mfa/rsa/mfa-rsa-pass-code.component';
import { OtpSelectionComponent } from './mfa/otp/selection/otp-selection.component';
import { OtpAuthenticatorNewComponent } from './mfa/otp/authenticator/new/otp-authenticator-new.component';
import { OtpPhoneNewComponent } from './mfa/otp/phone/new/otp-phone-new.component';
import { OtpEditMainComponent } from './mfa/otp/edit/otp-edit-main.component';
import { OtpConfirmationComponent } from './mfa/otp/confirmation/otp-confirmation.component';
import { OtpValidateComponent } from './mfa/otp/validate/otp-validate.component';
import { OAuthConsentComponent } from './oauth/consent/oauth-consent.component';
import { OAuthGrantsComponent } from './oauth/grants/oauth-grants.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { PasswordChangeComponent } from './password/change/password-change.component';
import { PasswordResetComponent } from './password/reset/password-reset.component';
import { ProfileCreateComponent } from './profile/create/profile-create.component';
import { ProfileEditComponent } from './profile/edit/profile-edit.component';
import { ValidateEmailLaterComponent } from './validate-email/later/validate-email-later.component';
import { RequestNotValidComponent } from './core/request-not-valid/request-not-valid.component';
import { TermsOfServiceUpdateComponent } from './tos/terms-of-service-update.component';
import { ValidateEmailComponent } from './validate-email/validate-email.component';
import { ValidateEmailCompleteComponent } from './validate-email/complete/validate-email-complete.component';
import { OtpSlotsComponent } from './mfa/otp/slots/otp-slots.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'forgot/password',
        component: ForgotPasswordComponent, 
        data: {
            title: 'Forgot Password'
        }
    },
    {
        path: 'forgot/username',
        component: ForgotUsernameComponent, 
        data: {
            title: 'Forgot Username'
        }
    },
    {
        path: 'login',
        component: LoginComponent, 
        data: {
            title: 'Login'
        }
    },
    {
        path: 'mfa/rsa',
        component: MFARsaPassCodeComponent,
        canActivate: [AppGuard],
        data: {
            title: 'RSA Passcode',
            validateAuthentication: true,
            validateQueryStringParameters: true
        }
    },
    {
        path: 'mfa/phone',
        component: MFAPhonePassCodeComponent,
        canActivate: [AppGuard],
        data: {
            title: 'SMS Passcode',
            validateAuthentication: true,
            validateQueryStringParameters: true
        }
    },
    {
        path: 'mfa/phone-selection',
        component: MFAPhoneSelectionComponent,
        canActivate: [AppGuard],
        data: {
            title: 'Phone Numbers',
            validateAuthentication: true,
            validateQueryStringParameters: true
        }
    },
    {
        path: 'mfa/otp/selection',
        component: OtpSelectionComponent,
        canActivate: [AppGuard],
        data: {
            title: 'Two-Factor Method Selection',
            validateAuthentication: true,
            validateQueryStringParameters: true
        }
    },
    {
        path: 'mfa/otp/authenticator/new',
        component: OtpAuthenticatorNewComponent,
        canActivate: [AppGuard],
        data: {
            title: 'Configure Authenticator App',
            validateAuthentication: true,
            validate2FAOr2FANotConfigured: true
        }
    },
    {
        path: 'mfa/otp/phone/new',
        component: OtpPhoneNewComponent,
        canActivate: [AppGuard],
        data: {
            title: 'Enter Phone Number',
            validateAuthentication: true,
            validate2FAOr2FANotConfigured: true
        }
    },
    {
        path: 'mfa/otp/validate',
        component: OtpValidateComponent,
        canActivate: [AppGuard],
        data: {
            title: 'Enter Code',
            validateAuthentication: true
        }
    },
    {
        path: 'mfa/otp/edit',
        component: OtpEditMainComponent,
        canActivate: [AppGuard],
        data: {
            title: 'Edit Two-Factor Authentication',
            validateAuthentication: true,
            validate2FAOr2FANotConfigured: true,
            targetPage: TargetPage[TargetPage.ProfileEdit]
        }
    },
    {
        path: 'mfa/otp/slots',
        component: OtpSlotsComponent,
        canActivate: [AppGuard],
        data: {
            title: 'Two-Factor Slot Selection',
            validateAuthentication: true,
            validate2FAConfigured: true
        }
    },
    {
        path: 'mfa/otp/confirmation',
        component: OtpConfirmationComponent,
        canActivate: [AppGuard],
        data: {
            title: 'Edit Two-Factor Confirmation'
        }
    },
    {
        path: 'oauth/consent',
        component: OAuthConsentComponent,
        canActivate: [AppGuard],
        data: {
            title: 'Consent',
            validateAuthentication: true,
            validateHasOAuthCookie: true,
            validateOAuthConsentQueryStringParameters: true,
            targetPage: TargetPage[TargetPage.OAuthConsent]
        }
    },
    {
        path: 'oauth/grants',
        component: OAuthGrantsComponent,
        canActivate: [AppGuard],
        data: {
            title: 'Grants',
            validateAuthentication: true,
            validateHasOAuthCookie: true,
            targetPage: TargetPage[TargetPage.OAuthGrants]
        }
    },
    {
        path: 'password/change',
        component: PasswordChangeComponent,
        data: {
            title: 'Change Password'
        }
    },
    {
        path: 'password/reset',
        component: PasswordResetComponent,
        data: {
            title: 'Reset Password'
        }
    },
    {
        path: 'profile/create',
        component: ProfileCreateComponent,
        canActivate: [AppGuard],
        data: {
            title: 'Create Profile',
            validateQueryStringParameters: true
        }
    },
    {
        path: 'profile/edit',
        component: ProfileEditComponent,
        canActivate: [AppGuard],
        data: {
            title: 'Edit Profile',
            validateAuthentication: true,
            validateQueryStringParameters: true,
            validate2FAOr2FANotConfiguredOnlyIfB2B: true,
            targetPage: TargetPage[TargetPage.ProfileEdit]
        }
    },
    {
        path: 'request-not-valid',
        component: RequestNotValidComponent, 
        data: {
            title: 'Request Not Valid'
        }
    },
    {
        path: 'tos/update',
        component: TermsOfServiceUpdateComponent,
        canActivate: [AppGuard],
        data: {
            title: 'Terms of Service',
            validateAuthentication: true,
            validateQueryStringParameters: true,
            targetPage: TargetPage[TargetPage.TermsOfService]
        }
    },
    {
        path: 'validate-email',
        component: ValidateEmailComponent,
        canActivate: [AppGuard],
        data: {
            title: 'Validate Email',
            validateAuthentication: true,
            validateQueryStringParameters: true
        }
    },
    {
        path: 'validate-email/send',
        component: ValidateEmailSendComponent,
        canActivate: [AppGuard], 
        data: {
            title: 'Send Email Validation',
            validateAuthentication: true,
            validateQueryStringParameters: true
        }
    },
    {
        path: 'validate-email/edit',
        component: ValidateEmailEditComponent,
        canActivate: [AppGuard], 
        data: {
            title: 'Edit Email Address',
            validateAuthentication: true,
            validateQueryStringParameters: true
        }
    },
    {
        path: 'validate-email/later',
        component: ValidateEmailLaterComponent,
        canActivate: [AppGuard], 
        data: {
            title: 'Validate Email Later',
            validateAuthentication: true,
            validateQueryStringParameters: true
        }
    },
    {
        path: 'validate-email/complete',
        component: ValidateEmailCompleteComponent,
        canActivate: [AppGuard],
        data: {
            title: 'Validate Email Complete',
            validateAuthentication: true,
            targetPage: TargetPage[TargetPage.ValidateEmailComplete]
        }
    },
    {
        path: '**',
        component: PageNotFoundComponent,
        data: { title: 'Page Not Found' }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }

