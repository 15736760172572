// DSS Documentation on Angular - http://tiny.sc/cgangular
import { Injectable, ElementRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

// DSS Documentation on Angular Reuse - http://tiny.sc/cgangularreuse
// DSS Documentation on ApiResult - http://tiny.sc/cgapiresult
import { ApiResult } from '@soco/core';
// DSS Documentation on SocoAlertService - http://tiny.sc/cgsocoalertservice
import { SocoAlertService } from '@soco/alert';
// DSS Documentation on SocoFormService - http://tiny.sc/cgsocoformservice
import { SocoFormService } from '@soco/forms';

@Injectable({
    providedIn: 'root'
})
export class ApiResultService {

    constructor(
        // DSS Documentation on SocoAlertService - http://tiny.sc/cgsocoalertservice
        private socoAlertService: SocoAlertService,
        //DSS Documentation on SocoFormService - http://tiny.sc/cgsocoformservice
        private socoFormService: SocoFormService,
    ) { }

    handleHttpErrorResponse<T>(error: HttpErrorResponse, options: ApiResultFailureOptions): void {
        let result = error.error as ApiResult<T>;
        let errMsg = '';

        // No ApiResult returned
        if (!result || !(result.hasOwnProperty('status') && result.hasOwnProperty('statusCode'))) {
            if (options.alertId) {
                // server returned unsuccessful response code
                switch (error.status) {
                    case 403:
                        this.showFailureMessage('Access denied', options.alertId);
                        break;
                    default:
                        this.showFailureMessage('An error occurred', options.alertId);
                        break;
                }
            }
        } else {
            let alertInUse = false;

            //verify if the ApiResult contains any model errors indicating the request was invalid
            if (result.modelErrors) {

                //ideally if there are model errors, a form would be specified so we can mark the appropriate form controls as invalid
                if (options.form) {
                
                    //iterate over the model errors, find the form control for the fields with errors, and set a server error
                    this.socoFormService.invalidateForm(options.form, result);

                    //depending on the size of the form, it might be nice to scroll the user back up to the first invalid control
                    if (options.el) {

                        //wait some time to let the error classes get applied and the error messages display then scroll to the first form control with an error
                        setTimeout(() => {
                            this.scrollToFirstInvalidControlOrShowAlertIfNoInvalidControl(options.el, options.showAlertIfModelErrorsDontResultInErrorMessagesBeingDisplayed, options.alertId, result);
                        });
                    }
                }
                //if no form was specified, display all the model errors in an alert
                else if (options.alertId) {
                    alertInUse = true;
                    for (const modelError of result.modelErrors) {
                        errMsg += modelError.errorMessage + '<br />';
                    }
                    this.showFailureMessage(errMsg, options.alertId);
                }
            }

            //verify if there is a message we need to display, display the message even if there are also model errors unless the model errors are already using the alert
            if (result.message) {

                //if no alert id was specified they might be handling errors on their own instead of letting this service handle them
                if (options.alertId && !alertInUse) {
                    this.showFailureMessage(result.message, options.alertId);
                }
            }
        } 

    }

    //=============================================================================================================
    //HELPER FUNCTIONS
    //=============================================================================================================
    private showFailureMessage(message: string, alertId: string) {
        if (!alertId) {
            // DSS Documentation on SocoAlertService - http://tiny.sc/cgsocoalertservice
            alertId = this.socoAlertService.getDefaultAlertId();
        }
        if (!!document.getElementById(alertId)) {
            // DSS Documentation on SocoAlertService - http://tiny.sc/cgsocoalertservice
            this.socoAlertService.error({ message, id: alertId, scrollToAlert: true });
        } else {
            // simply log the failure to the console
            console.error(message);
        }
    }

    private scrollToFirstInvalidControlOrShowAlertIfNoInvalidControl<T>(el: ElementRef, showAlertIfModelErrorsDontResultInErrorMessagesBeingDisplayed: boolean, alertId: string, result: ApiResult<T>) {
        const firstInvalidControl: HTMLElement = el.nativeElement.querySelector("form .ng-invalid");
        if (firstInvalidControl) {
            firstInvalidControl.scrollIntoView(); //without smooth behavior
        }
        else if(showAlertIfModelErrorsDontResultInErrorMessagesBeingDisplayed) {
            let errMsg = '';
            for (const modelError of result.modelErrors) {
                errMsg += modelError.errorMessage + '<br />';
            }
            this.showFailureMessage(errMsg, alertId);
        }
    }
}


export interface ApiResultFailureOptions {
    /**
     *  The id of the alert element to use if an error occurs and we want to display it to the user
     */
    alertId?: string;
    /**
     * A reference to the elements on the page that can be used to find any sub elements with validation
     * errors so that they can be focused on
     */
    el?: ElementRef;
    /**
     * A reference to the form being validated as part of the API call. Used to invalidate the form based
     * on the ApiResult returned. 
     */
    form?: FormGroup;
    /**
     * Will attempt to use the SocoFormService to invalidate the form. However, if the form doesn't match
     * the error message to any form control, it will fallback to showing the error messages within an alert.
     */
    showAlertIfModelErrorsDontResultInErrorMessagesBeingDisplayed?: boolean;
}