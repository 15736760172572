<div class="container" >
        <soco-alert [alertId]="alertId"></soco-alert>
        <div *ngIf="!isLoading && emailAddressValidated">
            <p>
                Thank you for validating your email address.
                This email address will be used in the event you forget your password.
                If you forget your password, you may use the “Forgot Password?” link on the login page.
                This link will allow you to reset your password for your Username.
            </p>
            <p>
                If you edit the email address in your profile, you will be asked to participate in the email validation process again.
            </p>
            <p>
                You may now close this window.
            </p>
        </div>
    <soco-system-message *ngIf="isLoading" messageType="Loading" message="Validating your email address..."></soco-system-message>
</div>
