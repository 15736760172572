// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, OnInit } from '@angular/core';

// DSS Documentation on Angular Reuse - http://tiny.sc/cgangularreuse
// DSS Documentation on SocoAlertService - http://tiny.sc/cgsocoalertservice
import { SocoAlertService } from '@soco/alert';

import { QueryStringService } from '../../core/query-string.service';

@Component({
    templateUrl: 'request-not-valid.component.html'
})
export class RequestNotValidComponent implements OnInit {
    alertId: string = 'request-not-valid-alert';

    constructor(
        private queryStringService: QueryStringService, 
        private socoAlertService: SocoAlertService
        ) { }

    ngOnInit(): void {
        let state = window.history.state;
        if (state && state.isOAuthConsentValidationFailure) {
            setTimeout(() => {
                this.socoAlertService.error({
                    id: this.alertId,
                    message: 'returnUrl is required'
                });
            });
        }
        else if (!this.queryStringService.returnUrl) {
            setTimeout(() => {
                this.socoAlertService.error({
                    id: this.alertId,
                    message: 'WL_ReturnURL is required'
                });
            });
        }
        else if (!this.queryStringService.appId) {
            setTimeout(() => {
                this.socoAlertService.error({
                    id: this.alertId,
                    message: 'WL_AppId is required'
                });
            });
        }
        else {
            setTimeout(() => {
                this.socoAlertService.error({
                    id: this.alertId,
                    message: 'Request not valid'
                });
            });
        }
    }
}