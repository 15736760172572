// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// DSS Documentation on Angular Reuse - http://tiny.sc/cgangularreuse
// DSS Documentation on SocoFormService - http://tiny.sc/cgsocoformservice
import { SocoFormService } from '@soco/forms';
//DSS Documentation on SocoModalService - http://tiny.sc/cgsocomodalservice
import { SocoModalService } from '@soco/modal';

import { ApiResultService } from '../../core/api-result.service';
import { AppService } from '../../app/app.service';
import { UserCreateData, UserCreateFormData } from '../../user/user-create-data';
import { UserService } from '../../user/user.service';

@Component({
    templateUrl: 'profile-create.component.html'
})
export class ProfileCreateComponent implements OnInit, OnDestroy {
    form: FormGroup;
    alertId: string = 'profile-create-alert';
    isLoading: boolean = false;
    formSubmitAttempted: boolean = false;
    termsOfService: string = null;
    profileCreated: boolean = false;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private appService: AppService,
        private apiResultService: ApiResultService,
        private userService: UserService,
        private socoFormService: SocoFormService,
        private socoModalService: SocoModalService,
        private el: ElementRef,
        private formBuilder: FormBuilder,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.getTermsOfService();
        this.form = this.formBuilder.group({
            username: ['', [Validators.required, Validators.maxLength(42)]],
            password: ['', [Validators.required, Validators.maxLength(32), Validators.pattern(/^\S*$/)]],
            retypePassword: ['', Validators.required],
            firstName: ['', [Validators.required, Validators.maxLength(50)]],
            lastName: ['', [Validators.required, Validators.maxLength(60)]],
            emailAddress: ['', [Validators.required, Validators.maxLength(64), Validators.email]],
            retypeEmailAddress: ['', Validators.required],
            acceptTOS: false
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    formSubmitted(data: UserCreateFormData): void {
        this.formSubmitAttempted = true;

        if (!this.form.valid || !data.acceptTOS) {
            this.socoFormService.scrollToFirstInvalidControl(this.el);
            return;
        }

        this.createUser(new UserCreateData(data));
    }

    showTOS(): void {
        let tosPrefix = `<div>
                            <p>
                                <b>
                                    In order to register to use this Web site, you must review and accept the Terms of Service(TOS) below.  
                                    If you accept the Terms of Service, check the "I accept the Terms of Service" checkbox at the bottom of the page and then click the SUBMIT button. 
                                    If you do not accept the Terms of Service, click the CANCEL button at the bottom of the page to cancel your registration.
                                </b>
                            </p>
                        </div>`;
        let tosSuffix = `<div>
                            <p>
                                <b>
                                    If you have read and understand the foregoing agreement and agree to be bound by all of its terms, check the "I accept the Terms of Service" checkbox at the bottom of the page and then click the Submit button. 
                                    If not, select Cancel.
                                </b>
                            </p>
                        </div>`;
        this.socoModalService.info({
            title: 'Terms of Service',
            body: tosPrefix + this.termsOfService + tosSuffix,
        });
    }

    cancel(): void {
        history.back();
    }

    continue(): void {
        this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private createUser(data: UserCreateData): void {
        this.isLoading = true;
        this.userService.create(data).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.isLoading = false;
            this.profileCreated = true;
        }, result => {
            this.isLoading = false;
            this.apiResultService.handleHttpErrorResponse(result, { alertId: this.alertId, form: this.form, el: this.el });
        });
    }

    private getTermsOfService(): void {
        this.appService.getTermsOfService({ alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.termsOfService = result.data;
        });
    }
}