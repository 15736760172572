export class MFAValidationCodeData {
    passcode: string = '';
    level: MultiFactorAuthLevel = MultiFactorAuthLevel.None;
}

export class MFAValidationCodeResult {
    userToken: string = '';
}

export enum MultiFactorAuthLevel {
    None = 0,
    Level3 = 4,
    Level7 = 64
}