<div class="container">
    <soco-system-message *ngIf="isLoading" messageType="Loading" message="Loading your configuration..."></soco-system-message> 
    <h2 class="page-title">Two-Factor Authentication</h2>
    <soco-alert [alertId]="alertId"></soco-alert>
    <div *ngIf="!isLoading" class="row align-items-center">
        <div class="col">
            <dl class="mb-0">
                <dt class="small font-weight-light text-muted">Primary Method</dt>
                <dd>{{(primaryOtpConfigDesc)}}</dd>
            </dl>
        </div>
        <div class="col-auto">
            <button type="button" class="mat-button" (click)="editPrimaryOtp()">Edit</button>
        </div>
    </div>
    <hr />
    <div *ngIf="!isLoading" class="row align-items-center">
        <div class="col">
            <dl class="mb-0">
                <dt class="small font-weight-light text-muted">Secondary Method</dt>
                <dd>{{(secondaryOtpConfigDesc)}}</dd>
            </dl>
        </div>
        <div class="col-auto">
            <button type="button" class="mat-button" (click)="editSecondaryOtp()">Edit</button>
        </div>
    </div>
    <hr />
    <div  class="text-center">
        <button mat-button class="mb-3 w-100" (click)="goBack()">Go Back</button>
    </div>
</div>
