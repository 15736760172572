import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MFA2FASlot } from '../../mfa-2fa-slot.enum';
import { MFA2FAMethod } from '../../mfa-2fa-method.enum';
import { MFAService } from '../../mfa.service';

@Component({
    templateUrl: 'otp-confirmation.component.html'
})
export class OtpConfirmationComponent implements  AfterViewInit, OnDestroy {
    alertId: string = 'otp-confirmation-alert';
    isLoading: boolean = false;
    userMessage: string;
    otpStateSlot: MFA2FASlot;
    otpStateSlotDesc: string;
    primaryOtpConfigMethod: MFA2FAMethod = MFA2FAMethod.None;
    secondaryOtpConfigMethod: MFA2FAMethod = MFA2FAMethod.None;
    statusPrimary: boolean = false;
    statusSecondary: boolean = false;
    private unsubscribe$ = new Subject<void>();

    constructor(
        private mfaService: MFAService,
        private router: Router
    ) {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.isLoading = true;
            this.otpStateSlot = this.mfaService.otpStateSlot();
            this.otpStateSlotDesc = this.mfaService.otpStateSlotName();
            this.getOTPConfigData();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    continueOtp(): void {
        if (!this.statusPrimary && this.statusSecondary) {
            //Secondary Configured but Primary is not, update state and then route to Primary Configuration.
            let route: string = '/mfa/otp/selection'; 
            let mfaOTPConfig: MFA2FASlot = MFA2FASlot.Primary;
            //Currently routes to login until target pages are constructed
            this.router.navigate([route], { queryParamsHandling: 'preserve' , state: { data: { otpStateSlot: mfaOTPConfig } } });
        } else if (this.statusPrimary && !this.statusSecondary) {
            //Primary Configured but Secondary is not, update state and then route to Secondary Configuration. 
            let route: string = '/mfa/otp/selection'; 
            let mfaOTPConfig: MFA2FASlot = MFA2FASlot.Secondary;
            //Currently routes to login until target pages are constructed
            this.router.navigate([route], { queryParamsHandling: 'preserve' , state: { data: { otpStateSlot: mfaOTPConfig } }});
        } else {
            //Needs to clean up state then route to login.
            this.router.navigate(['/login'], { queryParamsHandling: 'preserve' , state: { data: { otpStateSlot: null } } });
        }
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private getOTPConfigData(): void {
        //Display the ability to modify 2FA and if they currently have it enabled or disabled. 
        this.mfaService.get2faConfig({ alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.primaryOtpConfigMethod = result.data?.primary.method;
            if (this.primaryOtpConfigMethod != MFA2FAMethod.None && this.primaryOtpConfigMethod != null) {
                this.statusPrimary = true;
            }

            this.secondaryOtpConfigMethod = result.data?.secondary.method;
            if (this.secondaryOtpConfigMethod != MFA2FAMethod.None && this.secondaryOtpConfigMethod != null) {
                this.statusSecondary = true;
            }

            if (!this.statusPrimary) {
                this.userMessage = "You now need to add a primary method.";
            } else if (!this.statusSecondary) {
                this.userMessage = "You now need to add a secondary method.";
            } else {
                this.userMessage = "You're all setup!";
            }
        
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
        });
    }

}
