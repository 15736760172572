<div class="wrapper">
    <main class="body-content">
        <div class="container">
            <div id="logo">
                <div class="wa-logo"></div>
            </div>
        </div>

        <router-outlet></router-outlet>
    </main>
</div>