<div class="container">
    <h2 *ngIf="!isLoading" class="page-title">Please Validate your Email Address</h2>
    <soco-alert [alertId]="alertId"></soco-alert>
    <div *ngIf="validationEmailSent">
        <p>
            An email message was sent to the address you provided. 
            Once you receive it, click the link provided to validate your email.
        </p>
        <p>
            The email sent to you is only valid for 4 hours from the time it was sent.
            After this time you will need to request another.
        </p>
        <p>
            <strong>Please note</strong>, the validation email will come from <strong>{{fromEmailAddress}}</strong>.
            You may need to add this email address to your safe list.
        </p>
    </div>
    <div *ngIf="!isLoading">
        <a routerLink="/login" queryParamsHandling="preserve" mat-raised-button color="primary" class="w-100">Continue</a>
        <app-customer-service></app-customer-service>
    </div>
    <soco-system-message *ngIf="isLoading" messageType="Loading" message="Sending your validation email..."></soco-system-message>
</div>