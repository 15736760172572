<div class="container">
    <soco-alert [alertId]="alertId"></soco-alert>
    <soco-form *ngIf="form" [formGroup]="form" [form]="form" (formSubmitted)="formSubmitted($event)">
        <soco-form-body>
            <soco-form-section title="Configure Authenticator App">
                <p>
                    Please install an authenticator app if you do not already have one.
                    Microsoft Authenticator is available on the <a href="https://tiny.sc/apple-msft-auth-app" target="_blank">App Store</a> and <a href="https://tiny.sc/google-msft-auth-app" target="_blank">Google Play</a>.
                </p>
                <ol>
                    <li>Open your Authenticator App.
                    <li>Add an account within the app and scan the QR code below.
                        <div *ngIf="qrdata" class="text-center pr-4">
                            <qrcode [qrdata]="qrdata"></qrcode>
                            <div class="mb-2"><a [href]="sanitizer.bypassSecurityTrustUrl(qrdata)" target="_blank">If that does not work, tap here to open in your authenticator app.</a></div>
                        </div>
                </ol>
                <ol start="3">
                    <li>Provide a short description or name of your device (Optional).
                </ol>
                <mat-form-field>
                    <mat-label>Description</mat-label>
                    <input matInput type="text" maxlength="30" formControlName="description">
                    <mat-hint>Optional</mat-hint>
                    <mat-error *ngIf="form.get('description').hasError('maxlength')">Description must be {{ form.get('description').errors.maxlength.requiredLength }} characters or less</mat-error>
                </mat-form-field>
                <ol start="4">
                    <li>Enter code.
                </ol>
                <mat-form-field>
                    <mat-label>Code</mat-label>
                    <input matInput type="text" pattern="\d*" inputmode="numeric" minlength="6" maxlength="6" formControlName="password">
                    <mat-error *ngIf="form.get('password').hasError('required')">Code is required</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('minlength') || form.get('password').hasError('maxlength')">Code must be 6 digits</mat-error>
                </mat-form-field>
            </soco-form-section>
        </soco-form-body>
        <soco-form-footer class="text-center">
            <button mat-raised-button color="primary" type="submit" class="mb-3" [disabled]="!form.valid">Verify & Continue</button>
            <a mat-button queryParamsHandling="preserve" class="w-100 mb-3" (click)="goBack()">Go Back</a>
        </soco-form-footer>
    </soco-form>
    <soco-system-message *ngIf="isLoading" message="Loading..." messageType="Loading"></soco-system-message>
</div>