<div class="container">
    <soco-system-message *ngIf="isLoading" messageType="Loading" message="Loading your configuration..."></soco-system-message> 
    <h2 *ngIf="!isLoading" class="page-title">Setup {{ otpStateSlotString }} Two-Factor Authentication Method</h2>
    <a  *ngIf="!isLoading" (click)="showWhy()">Why do I need this?</a>
    <soco-alert [alertId]="alertId"></soco-alert>
    <soco-form  *ngIf="!isLoading && otpMethods && form" [formGroup]="form" [form]="form" (formSubmitted)="formSubmitted($event)">
        <soco-form-body>
            <soco-form-section>
                <mat-radio-group formControlName="preferredOTPMethod">
                    <mat-radio-button *ngFor="let otpMethod of otpMethods" [value]="otpMethod.methodType">{{ otpMethod.methodName }}</mat-radio-button>
                </mat-radio-group>
            </soco-form-section>
        </soco-form-body>
        <soco-form-footer class="text-center">
            <button mat-raised-button color="primary" type="submit" class="mb-3" [disabled]="!form.valid">Next</button>
            <a mat-button (click)="cancel()">Cancel</a>
        </soco-form-footer>
    </soco-form> 
    <soco-modal></soco-modal>
</div>





