import { Tel } from "../core/tel-input/tel-input.component";

export class UserEditData {
    userToken: string = '';
    username: string = '';
    emailAddress: string = '';
    firstName: string = '';
    lastName: string = '';
    currentPassword: string = '';
    password: string = '';
    phone1: string = '';
    phone2: string = '';
    isDisabled?: boolean;
    emailValidationCode: string = '';
    emailValidationEncryptedSecret: string = '';
    emailValidationMac: string = '';
    emailValidationCodeExpirationInUnixTimeSeconds: number = 0;
}

export class UserEditFormData {
    username: string = '';
    emailAddress: string = '';
    firstName: string = '';
    lastName: string = '';
    currentPassword: string = '';
    password: string = '';
    retypePassword: string = '';
    phone1: string = '';
    phone1Parts: Tel = null;
    emailValidationCode: string = '';
}

export class UserEditResult {
    sid: string = '';
    username: string = '';
    firstName: string = '';
    lastName: string = '';
    emailAddress: string = '';
    isEmailValidated: boolean = false;
    phone1: string = '';
    phone2: string = '';
    isDisabled?: boolean;
    passwordExpirationDate: Date = new Date();
    lastLogonDateUtc: Date = new Date();
}