<div class="container">
    <h2 class="page-title">Forgot Password</h2>
    <soco-alert [alertId]="alertId"></soco-alert>
    <soco-form *ngIf="form && !result" [formGroup]="form" [form]="form" (formSubmitted)="formSubmitted($event)">
        <soco-form-body>
            <soco-form-section>
                <p>To reset your password, please enter your username.</p>
                <mat-form-field>
                    <mat-label>Username</mat-label>
                    <input matInput formControlName="usernameOrEmail" appUsernameValidator>
                    <mat-error *ngIf="form.get('usernameOrEmail').hasError('required')">Username is required</mat-error>
                    <mat-error *ngIf="form.get('usernameOrEmail').hasError('username')">Please enter a valid username</mat-error>
                    <mat-error *ngIf="form.get('usernameOrEmail').hasError('server')">{{ form.get('usernameOrEmail').errors.server }}</mat-error>
                </mat-form-field>
            </soco-form-section>
        </soco-form-body>
        <soco-form-footer class="text-center">
            <button mat-raised-button color="primary" type="submit" class="mb-3" [disabled]="!form.valid">Continue</button>
            <div>
                <a routerLink="/login" queryParamsHandling="preserve">Return to Login</a>
                <span class="separator">|</span>
                <a routerLink="/forgot/username" queryParamsHandling="preserve">Forgot Username</a>
            </div>
        </soco-form-footer>
    </soco-form>
    <div *ngIf="result">
        <p>We have sent a password reset email to the email address we have on file for you at {{ result.partialUserEmailAddress }}. Please click the link in the email to continue the password reset process.</p>
        <p><strong><mat-icon fontIcon="fa-exclamation-triangle"></mat-icon> You will need to complete this within {{ result.tokenDuration.hours }} hours.</strong></p>
        <p>This email will be sent from {{ result.fromEmailAddress }}. You may need to add this email address to your safe list.</p>
        <a mat-button routerLink="/login" queryParamsHandling="preserve" class="w-100 mb-3">Return to Login</a>
    </div>
    <soco-system-message *ngIf="isLoading" message="Sending password reset..." messageType="Loading"></soco-system-message>
    <app-customer-service *ngIf="showCustomerSupport"></app-customer-service>
</div>