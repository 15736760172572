// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { mergeMap, map, filter } from 'rxjs/operators';

import { QueryStringService } from './core/query-string.service';

@Component({
    selector: 'my-app',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private queryStringService: QueryStringService,
        private titleService: Title
    ) { }

    ngOnInit(): void {
        this.setDocumentTitle();
        let urlParams = new URLSearchParams(window.location.search);
        let themeName = urlParams.get('AppTheme') ?? urlParams.get('WL_AppId');
        if (themeName) {
            let head = this.document.getElementsByTagName('head')[0];
            let style = this.document.createElement('link');
            style.rel = 'stylesheet';
            style.href = '/App_Themes/v6/' +  encodeURIComponent(themeName) + '/AppStyle.css';
            head.appendChild(style);
        }
    }

    private setDocumentTitle(): void {
        const titleSuffix = 'WebAuth';

        // Update document title using data.title property from the route definition
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
                let route = this.activatedRoute;
                while (route.firstChild) { route = route.firstChild; }
                return route;
            }),
            filter(route => route.outlet === 'primary'),
            mergeMap(route => route.data))
            .subscribe(event => {
                this.titleService.setTitle(event['title'] ? event['title'] + ' | ' + titleSuffix : titleSuffix);
                this.queryStringService.init();
            });
    }
}

