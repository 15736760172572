// DSS Documentation on Angular - http://tiny.sc/cgangular
import { Directive } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appEmailValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true}]
})
export class EmailValidatorDirective implements Validator  {

  validate(control: AbstractControl): ValidationErrors {
    if(!isValidEmailAddress(control.value)){
      return { 'email': {value: control.value}};
    }
    else if (/@southernco\.com$/i.test(control.value)) {
                let atIndex = control.value.indexOf('@');
                let suffix = control.value.substr(atIndex, control.value.length - atIndex);
                return { socoEmail: { suffixUsed: suffix } };
    }
    return null;
  }
}

//=============================================================================================================
//HELPER FUNCTIONS
//=============================================================================================================
export function isValidEmailAddress(emailAddress: string): boolean {
  let returnValue: boolean = false;
  if (emailAddress && emailAddress.length < 255) { // total length must not exceed 254 because MAIL/RCPT of commands (see RFC 2821)
      let atIndex = emailAddress.indexOf('@');
      if (atIndex >= 1 && atIndex <= 64) { // local part length between 1 and 64 inclusive
          let localPart = emailAddress.substr(0, atIndex);
          if (!localPart.includes('..')
              && !localPart.startsWith('.')
              && !localPart.endsWith('.')
              && !(/[^a-z0-9_.!=#$%*/?|{}`~&'+-]/i.test(localPart))) { // spec says localPart may contain "(),:;<>@[\] chars within "", but we do not allow it currently..
              let domainPart = emailAddress.substr(atIndex + 1); // Skip the @ char
              if (domainPart.length >= 3 && domainPart.length <= 255) // domain part max length 255, min length 3 *.* because we want at least 2 sub domain parts
              {
                  let subDomainParts = domainPart.split('.');
                  if (subDomainParts.length > 1) // spec does not forbid using just a TLD (no dot), but it makes sense to require at least 2 sub domain parts
                  {
                      let subDomainPartsAreValid = true;
                      for (let i = 0; i < subDomainParts.length; i++)
                      {
                          let subDomainPart = subDomainParts[i];
                          subDomainPartsAreValid =
                              subDomainPart.length >= 1 && // 0 length means the domain started with ., ended with ., or contained ..
                              subDomainPart.length <= 63 && // each label of the domain must be limited to a length of 63 characters
                              !subDomainPart.startsWith('-') && // LDH rule
                              !subDomainPart.endsWith('-') && // LDH rule
                              !(/[^a-z0-9-]/i.test(subDomainPart)); // LDH rule
                          if (!subDomainPartsAreValid)
                              break;
                      }

                      returnValue = subDomainPartsAreValid;
                  }
              }
          }
      }
  }
  return returnValue;
}
