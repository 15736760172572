<div class="container">
    <h2 *ngIf="form && phoneFields" class="page-title">Phone Numbers</h2>
    <soco-alert [alertId]="alertId"></soco-alert>
    <soco-form *ngIf="form && phoneFields" [formGroup]="form" [form]="form" (formSubmitted)="formSubmitted($event)">
        <soco-form-body>
            <soco-form-section>
                <label>Please select the number and the method to receive your access code</label>
                <mat-radio-group formControlName="fieldName" (change)="phoneFieldChanged($event)">
                    <mat-radio-button *ngFor="let phoneField of phoneFields" [value]="phoneField.fieldName">
                        {{phoneField.name}}: {{phoneField.phoneMasked}}
                    </mat-radio-button>
                </mat-radio-group>
            </soco-form-section>
            <soco-form-section>
                <mat-form-field>
                    <mat-label>Delivery Method</mat-label>
                    <mat-select formControlName="phoneContactMethod">
                        <mat-option value="SMS">SMS</mat-option>
                        <mat-option value="Voice">Voice</mat-option>
                    </mat-select>
                </mat-form-field>
            </soco-form-section>
            <soco-form-footer>
                <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">Next</button>
            </soco-form-footer>
        </soco-form-body>
    </soco-form>
    <soco-system-message *ngIf="isLoading" messageType="Loading" message="Sending your passcode..."></soco-system-message>
</div>