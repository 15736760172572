<div class="container">
    <soco-alert [alertId]="alertId"></soco-alert>
    <soco-form *ngIf="form" [formGroup]="form" [form]="form" (formSubmitted)="formSubmitted($event)">
        <soco-form-body>
            <soco-form-section [title]="title">
                <mat-form-field>
                    <mat-label>Code</mat-label>
                    <input matInput type="text" pattern="\d*" inputmode="numeric" minlength="6" maxlength="6" formControlName="password">
                    <mat-error *ngIf="form.get('password').hasError('required')">Code is required</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('minlength') || form.get('password').hasError('maxlength')">Code must be 6 digits</mat-error>
                </mat-form-field>
            </soco-form-section>
        </soco-form-body>
        <soco-form-footer class="text-center">
            <button mat-raised-button color="primary" type="submit" class="mb-3" [disabled]="!form.valid">Login</button>
            <a mat-button class="w-100 mb-3" (click)="cancel()">Cancel</a>
            <a mat-button *ngIf="!isInitialValidation" routerLink="/mfa/otp/slots" queryParamsHandling="preserve">Didn't receive a code?</a>
        </soco-form-footer>
    </soco-form>
    <soco-system-message *ngIf="isLoading" message="Loading..." messageType="Loading"></soco-system-message>
</div>