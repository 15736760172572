// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApiResultService } from '../core/api-result.service';
import { AppType } from '../app/app-type.enum';
import { QueryStringService } from '../core/query-string.service';
import { ReturnMethod } from '../core/return-method.enum';
import { TargetPage } from '../core/target-page.enum';
import { TokenService } from '../core/token.service';
import { UserLoginData, UserLoginResult } from '../user/user-login-data';
import { UserService } from '../user/user.service';
import { MFAService } from '../mfa/mfa.service';
import { MFAType } from '../mfa/mfa-required-multi-factor-type-data';
import { environment } from '../../environments/environment';
import { MFA2FAMethod } from '../mfa/mfa-2fa-method.enum';
import { MFA2FASlot } from '../mfa/mfa-2fa-slot.enum';
import { PasswordExpiredChangeMethod } from '../core/password-expired-change-method.enum';

const rememberMeKey: string = 'remember_me';

@Component({
    templateUrl: 'login.component.html'
})
export class LoginComponent implements AfterViewInit, OnDestroy {
    form: FormGroup = null;
    alertId: string = 'login-alert';
    loginTitle: string = 'Secure Log In';
    isLoading: boolean = true;
    isB2B: boolean;
    isEditingProfile: boolean;
    isValidatingEmail: boolean;
    rememberMeChecked: boolean;
    postBackUrl: string = environment.webBaseUrl + 'Navigation';
    
    private unsubscribe$ = new Subject<void>();

    @ViewChild('wlToken') wlToken: ElementRef;
    @ViewChild('wlMethod') wlMethod: ElementRef;
    @ViewChild('wlForm') wlForm: ElementRef;

    constructor(
        private apiResultService: ApiResultService,
        private mfaService: MFAService,
        public queryStringService: QueryStringService,
        private tokenService: TokenService,
        private userService: UserService,
        private el: ElementRef,
        private formBuilder: FormBuilder,
        private router: Router
    ) {
        //indicate if the login is for B2B users
        this.isB2B = this.queryStringService.type == AppType.B2B;
        
        //indicate if the login is for validating email
        this.isValidatingEmail = !!this.queryStringService.emailValidationTicket;

        //indicate if the login is going to redirect to the edit profile page
        this.isEditingProfile = this.queryStringService.targetPage == TargetPage.ProfileEdit;
        if (this.isEditingProfile) {
            this.loginTitle = 'Edit Profile / Log In';
        }
        else {
            this.loginTitle = 'Secure Log In';
        }
    }

    ngAfterViewInit(): void {
        // perform a sso check each time the user lands on /login after the view initializes
        setTimeout(() => this.verifyIfUserIsAlreadyLoggedOn());
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    init(username: string = ''): void {
        if (!username) {
            if (!!this.queryStringService.userID) {
                // if there is a WL_UserID, populate that username
                username = this.queryStringService.userID;
            } else {
                //if the user selects Remember Me, we want to autopopulate Username
                username = window.localStorage.getItem(rememberMeKey) || '';
                this.rememberMeChecked = !!username;
            }
        }

        //initialize the form
        this.form = this.formBuilder.group({
            username: [username, Validators.required],
            password: ['', Validators.required]
        });

        //if username has a value, mark the field as touched
        let usernameControl = this.form.get('username');
        if (!!usernameControl.value) {
            usernameControl.markAsTouched();
        }
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    formSubmitted(data: UserLoginData): void {
        if (this.form.valid) {
            this.saveRememberMePreferences(data);
            this.login(data);
        }
    }

    showEditProfileLogin(): void {
        this.isEditingProfile = true;
        this.loginTitle = 'Edit Profile / Log In';
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    public verifyIfUserIsAlreadyLoggedOn(): void {
        this.form = null;
        this.isLoading = true;
        this.tokenService.get().pipe(takeUntil(this.unsubscribe$)).subscribe(tokenResult => {
            if (!tokenResult || !tokenResult.token) {
                //user is not already logged in, initialize the form
                this.init();
                this.isLoading = false;
            } else {
                //clear the form and show loading while we process the login
                this.form = null;
                this.isLoading = true;

                //since this is SSO, don't consider if the user is validated their email or accepted the terms of service
                //and assume this was verified when they originally logged in
                this.handleSuccessfulLogin({ token: tokenResult.token, emailValidated: true, termsOfServiceAccepted: true });
            }
        }, error => {
            console.log('SSO: ' + error);
            this.init();
            this.isLoading = false;
        });
    }

    private login(data: UserLoginData): void {
        this.form = null;
        this.isLoading = true;
        this.userService.login(data).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.handleSuccessfulLogin(result.data);
        }, result => {
            if(result.error.statusCode == 410){ 
                this.handlePasswordExpired(data, result.error.message, PasswordExpiredChangeMethod[<string>result.error.data.passwordExpiredChangeMethod]);
                return;
            }
            this.init(data.username);
            this.isLoading = false;
            this.apiResultService.handleHttpErrorResponse(result, { alertId: this.alertId, form: this.form, el: this.el });
        });
    }

    private verifyIfMfaIsRequired(): void {
        this.mfaService.getRequiredMfaType().pipe(takeUntil(this.unsubscribe$)).subscribe(requiredMfaType => {
            switch (requiredMfaType) {
                case MFAType.RSA: {
                    this.router.navigate(['/mfa/rsa'], { queryParamsHandling: 'preserve' });
                    break;
                }
                case MFAType.Phone: { // MFAType.Phone is overloaded for Web and B2B 2fa
                    this.userService.get().pipe(takeUntil(this.unsubscribe$)).subscribe(userResult => {
                        // check for nameSpace == null first to account for CoolWebProfile behavior
                        if (userResult.data.nameSpace == null || userResult.data.nameSpace.toUpperCase() === 'SOUTHERNCO') {
                            // employee 2fa
                            this.router.navigate(['/mfa/phone-selection'], { queryParamsHandling: 'preserve' });
                        } else {
                            // web and b2b 2fa
                            this.mfaService.get2faConfig({ alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(configResult => {
                                if (configResult.data.primary.method === MFA2FAMethod.None) {
                                    // go to configure primary
                                    this.router.navigate(['/mfa/otp/selection'], { queryParamsHandling: 'preserve', state: { data: { otpStateSlot: MFA2FASlot.Primary } } });
                                } else if (configResult.data.secondary.method === MFA2FAMethod.None) {
                                    // go to configure secondary
                                    this.router.navigate(['/mfa/otp/selection'], { queryParamsHandling: 'preserve', state: { data: { otpStateSlot: MFA2FASlot.Secondary } } });
                                } else {
                                    // go to validate primary
                                    this.router.navigate(['/mfa/otp/validate'], { queryParamsHandling: 'preserve', state: { data: { otpStateSlot: MFA2FASlot.Primary, otpStateMethod: configResult.data.primary.method } } });
                                }
                            });
                        }
                    });
                    break;
                }
                case MFAType.None:
                default: {
                    this.postAndRedirect();
                    break;
                }
            }
        }, error => {
            console.log('MFA error: ' + error);
            this.init();
        });
    }

    private postAndRedirect(): void {
        // if the return method has been set to redirect we are just navigating the browser
        // the token is not getting sent and the navigation form does not get submitted in this case
        if (this.queryStringService.returnMethod == ReturnMethod.Redirect) {
            //when the user edits their profile, ReturnMethod is set to Redirect since a number of paths end up back
            //at login (EX: Edit profile may redirect to nag screen to validate email address), once you get to login 
            //we know that if ReturnMethod is Redirect, just Redirect to WL_ReturnUrl
            this.goToUrl(this.queryStringService.returnUrl);
            return;
        }

        // post the navigation form and let webauth handle sending the user to the destination
        this.tokenService.get().pipe(takeUntil(this.unsubscribe$)).subscribe(tokenResult => {
            this.wlToken.nativeElement.value = tokenResult.token;
            this.wlForm.nativeElement.submit();
        }, error => {
            console.log('login error: ' + error);
            this.init();
        });
    }

    //=============================================================================================================
    //HELPER FUNCTIONS
    //=============================================================================================================
    private saveRememberMePreferences(data: UserLoginData) {
        if (!!this.queryStringService.userID) {
            // disable remember me funcitonality when userID is present
            return;
        }
        if (this.rememberMeChecked) {
            window.localStorage.setItem(rememberMeKey, data.username);
        } else {
            window.localStorage.removeItem(rememberMeKey);
        }
    }

    private handleSuccessfulLogin(data: UserLoginResult): void {
        // use the current targetPage, and set the next targetPage to ReturnURL
        const targetPage: TargetPage = this.queryStringService.targetPage;
        this.queryStringService.targetPage = TargetPage.ReturnURL;

        // route is populated in the case that the user should stay on webauth
        let route: string = null;
        switch (targetPage) {
            case TargetPage.ProfileEdit: route = '/profile/edit'; break;
            case TargetPage.OAuthConsent: route = '/oauth/consent'; break;
            case TargetPage.TermsOfService: route = '/tos/update'; break;
            case TargetPage.ValidateEmailComplete: route = '/validate-email/complete'; break;
            case TargetPage.ReturnURL:
            default: {
                if (!data.termsOfServiceAccepted) {
                    route = '/tos/update';
                } else if (!data.emailValidated) {
                    if (!!this.queryStringService.emailValidationTicket) {
                        // backwards compatibility for redirects from v4 or v5 UI
                        route = '/validate-email/complete';
                    } else {
                        route = '/validate-email';
                    }
                }
                break;
            }
        }

        // if a route has been specified, the user is staying on webauth
        if (!!route) {
            if (targetPage === TargetPage.ReturnURL || targetPage === TargetPage.OAuthConsent) {
                this.router.navigate([route], { queryParamsHandling: 'preserve' });
            } else {
                // navigating to a target page
                // first navigate to the current page, merging the current query params set
                // targetPage has been set back to ReturnURL by this point
                // then navigate to the target page's route
                this.router.navigate([], {
                    queryParams: this.queryStringService.getQueryParams()
                })?.then(() => {
                    this.router.navigate([route], { queryParamsHandling: 'preserve' });
                });
            }
            return;
        }
        
        // route was not specified, so the user is completing the login process
        // the user is logged in, but now check if MFA is required
        // once the user meets all requirements, login is complete and user leaves webauth
        this.verifyIfMfaIsRequired();
    }
    
    //This function is called when a user's login attempt detects and expired password and navigates them to the Change Password Page.
    private handlePasswordExpired(userData: UserLoginData, errorMessage: string, passwordExpiredChangeMethod: PasswordExpiredChangeMethod): void {
        //set page route by PasswordExpiredChangeMethod
        let route: string;
        switch (passwordExpiredChangeMethod) {
            case PasswordExpiredChangeMethod.EmailReset: {
                route = '/forgot/password';
                break;
            }
            default: {
                route = '/password/change';
                break;
            }
        }
        //We are using history.state to perserve the errorMessage from the User Service, which should tell the user their password is expired. As well as their username, so it can be prepopulated on PasswordExpiredChangeMethod Page.
        this.router.navigate([route], { queryParamsHandling: 'preserve', state: { data: { expireMessage: errorMessage, userId: (userData.username || '') } } });
    }

    private goToUrl(url: string): void {
        window.location.href = url;
    }
}