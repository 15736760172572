//angular modules
//DSS Documentation on Angular - http://tiny.sc/cgangular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

//soco modules
import { SocoAlertModule } from '@soco/alert';
import { SocoCommonModule } from '@soco/common';
import { SocoFormsModule } from '@soco/forms';
import { SocoUIThemeModule } from '@soco/ui-theme';

//webauth modules
import { CoreModule } from '../core/core.module';

//components
//DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { ForgotPasswordComponent } from './password/forgot-password.component';
import { ForgotUsernameComponent } from './username/forgot-username.component';

const MODULE_DIRECTIVES = [
    ForgotPasswordComponent,
    ForgotUsernameComponent
];

@NgModule({
    imports: [
        //angular modules
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatIconModule,

        //soco modules
        SocoAlertModule,
        SocoCommonModule,
        SocoFormsModule,
        SocoUIThemeModule,

        //webauth modules
        CoreModule
    ],
    exports: MODULE_DIRECTIVES,
    declarations: MODULE_DIRECTIVES
})
export class ForgotModule { }

