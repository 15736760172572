<div class="container">
    <h2 class="page-title">Forgot Username</h2>
    <soco-alert [alertId]="alertId"></soco-alert>
    <soco-form *ngIf="form && !result" [formGroup]="form" [form]="form" (formSubmitted)="formSubmitted($event)">
        <soco-form-body>
            <soco-form-section>
                <p>To recover your username, please enter the email address associated with your profile.</p>
                <mat-form-field>
                    <mat-label>Email Address</mat-label>
                    <input matInput formControlName="emailAddress" maxlength="64" appEmailValidator>
                    <mat-error *ngIf="form.get('emailAddress').hasError('required')">Email address is required</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('maxlength')">Email Address must be {{ form.get('emailAddress').errors.maxlength.requiredLength }} characters or less</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('email') || form.get('emailAddress').hasError('socoEmailValidation')">Please enter a valid email address</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('socoEmail')">You can use your Southern Company email as your username at the Login page</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('server')">{{ form.get('emailAddress').errors.server }}</mat-error>
                </mat-form-field>
            </soco-form-section>
        </soco-form-body>
        <soco-form-footer class="text-center">
            <button mat-raised-button color="primary" type="submit" class="mb-3" [disabled]="!form.valid">Continue</button>
            <div>
                <a routerLink="/login" queryParamsHandling="preserve">Return to Login</a>
                <span class="separator">|</span>
                <a (click)="forgotEmail()">Forgot Email Address</a>
            </div>
        </soco-form-footer>
    </soco-form>
    <div *ngIf="result">
        <p>We've sent your username to {{ result.emailAddress }}.</p>
        <a mat-button routerLink="/login" queryParamsHandling="preserve" class="w-100 mb-3">Return to Login</a>
    </div>
    <soco-system-message *ngIf="isLoading" message="Sending username request..." messageType="Loading"></soco-system-message>
    <app-customer-service #customerService></app-customer-service>
</div>