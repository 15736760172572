<div class="container">
    <h2 *ngIf="!profileCreated" class="page-title">Create Profile</h2>
    <soco-alert [alertId]="alertId"></soco-alert>
    <soco-form *ngIf="!profileCreated && form" [formGroup]="form" [form]="form" (formSubmitted)="formSubmitted($event)"
        [appFormValuesAreEqualCrossValidator]="[{controls:[form.get('password'), form.get('retypePassword')]}, {controls:[form.get('emailAddress'), form.get('retypeEmailAddress')],ignoreCase:true}]"
        [appFormValuesNotEqualCrossValidator]="[{controls:[form.get('username'), form.get('password')],ignoreCase:true}]">
        <soco-form-body>
            <soco-form-section title="Personal Information" class="mb-4">
                <mat-form-field>
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="firstName" maxlength="50">
                    <mat-error *ngIf="form.get('firstName').hasError('required')">First Name is required</mat-error>
                    <mat-error *ngIf="form.get('firstName').hasError('maxlength')">First Name must be {{ form.get('firstName').errors.maxlength.requiredLength }} characters or less</mat-error>
                    <mat-error *ngIf="form.get('firstName').hasError('server')">{{ form.get('firstName').errors.server }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="lastName" maxlength="60">
                    <mat-error *ngIf="form.get('lastName').hasError('required')">Last Name is required</mat-error>
                    <mat-error *ngIf="form.get('lastName').hasError('maxlength')">Last Name must be {{ form.get('lastName').errors.maxlength.requiredLength }} characters or less</mat-error>
                    <mat-error *ngIf="form.get('lastName').hasError('server')">{{ form.get('lastName').errors.server }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="emailAddress" maxlength="64" appEmailValidator>
                    <mat-error *ngIf="form.get('emailAddress').hasError('required')">Email Address is required</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('maxlength')">Email Address must be {{ form.get('emailAddress').errors.maxlength.requiredLength }} characters or less</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('email') || form.get('emailAddress').hasError('socoEmailValidation')">Please enter a valid email address</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('socoEmail')">Southern Company employees are already registered. Enter your Southern Company email and password at the Login page.</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('formValuesAreEqualCrossValidator')">Email addresses must match</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('server')">{{ form.get('emailAddress').errors.server }}<span *ngIf="form.get('emailAddress').errors.server.includes('existing')"> Please click <a routerLink="/forgot/username" queryParamsHandling="preserve">here</a> to retrieve your username.</span></mat-error>                
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Retype Email</mat-label>
                    <input matInput formControlName="retypeEmailAddress">
                    <mat-error *ngIf="form.get('retypeEmailAddress').hasError('required')">Please retype your email address to ensure correct entry</mat-error>
                    <mat-error *ngIf="form.get('retypeEmailAddress').hasError('formValuesAreEqualCrossValidator')">Email addresses must match</mat-error>
                    <mat-error *ngIf="form.get('retypeEmailAddress').hasError('server')">{{ form.get('retypeEmailAddress').errors.server }}</mat-error>
                </mat-form-field>
            </soco-form-section>
            <soco-form-section title="Login Credentials" class="mb-4">
                <mat-form-field>
                    <mat-label>Username</mat-label>
                    <input matInput name="username" autocomplete="username" formControlName="username" maxlength="42" appUsernameValidator isNewUsername="true">
                    <mat-error *ngIf="form.get('username').hasError('required')">Username is required</mat-error>
                    <mat-error *ngIf="form.get('username').hasError('maxlength')">Username must be {{ form.get('username').errors.maxlength.requiredLength }} characters or less</mat-error>
                    <mat-error *ngIf="form.get('username').hasError('username')">Please enter a Username that contains no spaces and only contains letters and numbers, or which is a valid email address</mat-error>
                    <mat-error *ngIf="form.get('username').hasError('socoEmail')">Please enter a Username that does not end with {{form.get('username').errors.socoEmail.suffixUsed}}</mat-error>
                    <mat-error *ngIf="form.get('username').hasError('server')">{{ form.get('username').errors.server }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Password (Case-Sensitive)</mat-label>
                    <input matInput type="password" name="new-password" autocomplete="new-password" formControlName="password" maxlength="32" (input)="passwordComplexity.password = $event.target.value" appPasswordComplexityValidator>
                    <mat-error *ngIf="form.get('password').hasError('required')">Password is required</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('maxlength')">Password must be {{ form.get('password').errors.maxlength.requiredLength }} characters or less</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('pattern')">Password cannot contain spaces</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('complexity')">Password must meet complexity requirements</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('formValuesAreEqualCrossValidator')">Passwords do not match</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('formValuesNotEqualCrossValidator')">Password cannot match Username</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('server')">{{ form.get('password').errors.server }}</mat-error>
                </mat-form-field>
                
                <app-password-complexity #passwordComplexity></app-password-complexity>
                
                <mat-form-field>
                    <mat-label>Retype Password</mat-label>
                    <input matInput type="password" name="confirm-password" autocomplete="new-password" formControlName="retypePassword">
                    <mat-error *ngIf="form.get('retypePassword').hasError('required')">Please retype your password to ensure correct entry</mat-error>
                    <mat-error *ngIf="form.get('retypePassword').hasError('formValuesAreEqualCrossValidator')">Passwords do not match</mat-error>
                    <mat-error *ngIf="form.get('retypePassword').hasError('server')">{{ form.get('retypePassword').errors.server }}</mat-error>
                </mat-form-field>
            </soco-form-section>
            <soco-form-section title="Terms of Service" class="mb-4">
                <mat-checkbox formControlName="acceptTOS">I accept the <a (click)="showTOS()">Terms of Service</a></mat-checkbox>
                <mat-error *ngIf="(formSubmitAttempted || form.get('acceptTOS').dirty) && !form.get('acceptTOS').value">Please read the Terms of Service, and indicate your acceptance by checking the check box.</mat-error>
                <mat-error *ngIf="form.get('acceptTOS').hasError('server')">{{ form.get('acceptTOS').errors.server }}</mat-error>
            </soco-form-section>
            <soco-form-footer>
                <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">Submit</button>
                <a mat-button (click)="cancel()">Cancel</a>
            </soco-form-footer>
        </soco-form-body>
    </soco-form>
    <soco-system-message *ngIf="isLoading" messageType="Loading" message="Creating your profile..."></soco-system-message>
    <div *ngIf="profileCreated">
        <h2 class="page-title">How to Complete Registration</h2>
        <p>
            Your registration is almost complete.  In order to complete your registration,
            please open the email you will receive from us shortly and click on the link
            provided. This link will take you to a login page to verify that you received the email.
        </p>
        <p>
            Your current email address is <b>{{form.get('emailAddress').value}}</b>.
        </p>
        <p>
            You can continue your online transaction or enrollment prior to completing your
            registration by clicking “Continue” below.
        </p>
        <button mat-raised-button color="primary" (click)="continue()">Continue</button>
    </div>
</div>
<soco-modal></soco-modal>