export class UserSendEmailValidationCodeData {
    constructor(emailAddress: string) {
        this.emailAddress = emailAddress;
    }

    emailAddress: string = '';
}

export class UserSendEmailValidationCodeResult {
    emailValidationCodeExpirationInUnixTimeSeconds: number = 0;
    encryptedSecret: string = '';
    mac: string = '';
}