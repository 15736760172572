// DSS Documentation on Angular - http://tiny.sc/cgangular
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[autofocus]'
})
export class AutoFocusDirective implements AfterViewInit {
    private _autofocus;
    constructor(private el: ElementRef) {
    }

    ngAfterViewInit() {
        if (this._autofocus || typeof this._autofocus === "undefined") {
            this.el.nativeElement.focus();
        }
    }

    @Input() set autofocus(condition: boolean) {
        this._autofocus = condition != false;
    }
}