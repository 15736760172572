<div class="customer-service-container">
    <hr class="my-4" />
    <h4 class="text-center">Need assistance?</h4>
    <mat-expansion-panel #panel (afterExpand)="afterExpand()">
        <mat-expansion-panel-header>
            <mat-panel-title>Contact Customer Service</mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div class="customer-service">
                <ul class="list-unstyled mb-0">
                    <li *ngFor="let company of companies">
                        <h4><img [src]="company.icon" class="company-icon">{{ company.name }}</h4>
                        <div>
                            <a [href]="'tel:+' + company.phone">
                                <mat-icon fontIcon="fa-phone"></mat-icon>{{ company.phone }}
                            </a>
                        </div>
                        <div>
                            <a [href]="'mailto:' + company.email">
                                <mat-icon fontIcon="fa-envelope"></mat-icon>{{ company.email }}
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </ng-template>
    </mat-expansion-panel>
</div>
