<div class="container">
    <h2 class="page-title">Reset Password</h2>
    <soco-alert [alertId]="alertId"></soco-alert>
    <soco-form *ngIf="form && !result" [formGroup]="form" [form]="form" (formSubmitted)="formSubmitted($event)" 
    [appFormValuesAreEqualCrossValidator]="[{controls:[form.get('password'), form.get('confirmPassword')]}]" 
    [appFormValuesNotEqualCrossValidator]="[{controls:[form.get('usernameOrEmail'), form.get('password')],ignoreCase:true}]">
        <soco-form-body>
            <soco-form-section>
                <mat-form-field>
                    <mat-label>Username</mat-label>
                    <input matInput name="username" autocomplete="username" formControlName="usernameOrEmail" appUsernameValidator>
                    <mat-error *ngIf="form.get('usernameOrEmail').hasError('required')">Username is required</mat-error>
                    <mat-error *ngIf="form.get('usernameOrEmail').hasError('username')">Please enter a valid username</mat-error>
                    <mat-error *ngIf="form.get('usernameOrEmail').hasError('server')">{{ form.get('usernameOrEmail').errors.server }}</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>New Password</mat-label>
                    <input matInput type="password" name="new-password" autocomplete="new-password" formControlName="password" maxlength="32" (input)="passwordComplexity.password = $event.target.value" appPasswordComplexityValidator>
                    <mat-error *ngIf="form.get('password').hasError('required')">Password is required</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('maxlength')">Password must be {{ form.get('password').errors.maxlength.requiredLength }} characters or less</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('pattern')">Password cannot contain spaces</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('complexity')">Password must meet complexity requirements</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('formValuesAreEqualCrossValidator')">Passwords do not match</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('formValuesNotEqualCrossValidator')">Password cannot match Username</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('server')">{{ form.get('password').errors.server }}</mat-error>
                </mat-form-field>
                <app-password-complexity #passwordComplexity></app-password-complexity>
                <mat-form-field>
                    <mat-label>Re-Type New Password</mat-label>
                    <input matInput type="password" name="confirm-password" autocomplete="new-password" formControlName="confirmPassword" maxlength="32">
                    <mat-error *ngIf="form.get('confirmPassword').hasError('required')">Please retype your password to ensure correct entry</mat-error>
                    <mat-error *ngIf="form.get('confirmPassword').hasError('formValuesAreEqualCrossValidator')">Passwords do not match</mat-error>
                    <mat-error *ngIf="form.get('confirmPassword').hasError('server')">{{ form.get('confirmPassword').errors.server }}</mat-error>
                </mat-form-field>
            </soco-form-section>
        </soco-form-body>
        <soco-form-footer class="text-center">
            <button mat-raised-button color="primary" type="submit" class="mb-3" [disabled]="!form.valid">Submit</button>
        </soco-form-footer>
    </soco-form>
    <div *ngIf="result">
        <p>Your password has been reset successfully and may be used the next time you log in.</p>
    </div>
    <soco-system-message *ngIf="isLoading" message="Resetting your password..." messageType="Loading"></soco-system-message>
</div>
