// DSS Documentation on Angular - http://tiny.sc/cgangular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap, map, shareReplay } from 'rxjs/operators';

// DSS Documentation on ApiResult - http://tiny.sc/cgapiresult
import { ApiResult } from '@soco/core';

import { ApiResultFailureOptions } from '../core/api-result.service';
import { AppType } from '../app/app-type.enum';
import { QueryStringService } from '../core/query-string.service';
import { TokenService } from '../core/token.service';
import { UserCreateData, UserCreateResult } from './user-create-data';
import { UserData } from './user-data';
import { UserEditData, UserEditResult } from './user-edit-data';
import { UserForgotUsernameData } from './user-forgot-username-data';
import { UserLoginData, UserLoginResult } from './user-login-data';
import { UserPasswordChangeData, UserPasswordChangeResult } from './user-password-change-data';
import { UserPasswordResetData, UserPasswordResetResult } from './user-password-reset-data';
import { UserSendEmailValidationCodeData, UserSendEmailValidationCodeResult } from './user-send-email-validation-code-data';
import { UserSendPasswordResetResult, UserSendPasswordResetData } from './user-send-password-reset-data';
import { UserSendValidateEmailData, UserSendValidateEmailResult } from './user-send-validate-email-data';
import { UserTOSData, UserTOSResult } from './user-tos-data';
import { WebAuthHttpService } from '../core/webauth-http.service';
import { UserValidateEmailData } from './user-validate-email-data';
import { PasswordComplexityRule } from '../core/password-complexity/password-complexity-rule';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private passwordComplexityRules: Observable<ApiResult<PasswordComplexityRule[]>> = null;

    constructor(
        private http: WebAuthHttpService,
        private queryStringService: QueryStringService,
        private tokenService: TokenService
    ) { }

    private getBaseUrl(): string {
        return this.queryStringService.type == AppType.B2B ? 'B2BUser' : 'WebUser';
    }

    //=============================================================================================================
    //FORGOT
    //=============================================================================================================
    /**
     *  Sends an email to the specified email address with the list of usernames tied to that email address
     *  @param data The data required to send the forgot username email
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    sendForgotUsername(data: UserForgotUsernameData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<UserForgotUsernameData>> {
        return this.http.post<UserForgotUsernameData>({
            url: this.getBaseUrl() + '/SendForgotUsername',
            data: data,
            failureOptions: failureOptions
        });
    }

    /**
     *  Sends an email to the specified email address with the password reset link
     *  @param data The data required to send the password reset email
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    sendPasswordReset(data: UserSendPasswordResetData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<UserSendPasswordResetResult>> {
        return this.http.post<UserSendPasswordResetResult>({
            url: this.getBaseUrl() + '/SendPasswordReset',
            data: data,
            failureOptions: failureOptions
        });
    }

    //=============================================================================================================
    //LOGIN
    //=============================================================================================================
    /**
     *  Gets a token from the user's credentials
     *  @param data The data required to log the user in
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    login(data: UserLoginData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<UserLoginResult>> {
        return this.http.post<UserLoginResult>({
            url: this.getBaseUrl() + '/Login',
            data: data,
            failureOptions: failureOptions
        }).pipe(map(result => {
            this.tokenService.set(result.data.token);
            return result;
        }));
    }

    //=============================================================================================================
    //PASSWORD
    //=============================================================================================================
    /**
     * Changes a password
     *  @param data The data required to change a password
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    changePassword(data: UserPasswordChangeData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<UserPasswordChangeResult>> {
        return this.http.patch<UserPasswordChangeResult>({
            url: this.getBaseUrl() + '/ChangePassword',
            data: data,
            failureOptions: failureOptions
        });
    }

    /**
     * Resets the user's password to the specified new password
     *  @param data The data required to reset a password
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    resetPassword(data: UserPasswordResetData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<UserPasswordResetResult>> {
        data.ticket = this.queryStringService.ticket;
        return this.http.patch<UserPasswordResetResult>({
            url: this.getBaseUrl() + '/ResetPassword',
            data: data,
            failureOptions: failureOptions
        });
    }

    /**
     * Retrieves the password complexity requirements rules list
     * @param failureOptions The optional parameters that can be used to handle failures
     */
    getPasswordComplexityRules(failureOptions?: ApiResultFailureOptions): Observable<ApiResult<PasswordComplexityRule[]>> {
        if (this.passwordComplexityRules === null) {
            this.passwordComplexityRules = this.http.get<PasswordComplexityRule[]>({
                url: this.getBaseUrl() + '/PasswordRules',
                failureOptions: failureOptions
            }).pipe(shareReplay(1));
        }
        return this.passwordComplexityRules.pipe(map(result => {
            // always return a deep copy
            result = <ApiResult<PasswordComplexityRule[]>>JSON.parse(JSON.stringify(result));
            for (var i = 0; i < result.data.length; i++) {
                // new up a PasswordComplexityRule for the validate function
                const rule = new PasswordComplexityRule();
                rule.description = result.data[i].description;
                rule.pattern = result.data[i].pattern;
                result.data[i] = rule;
            }
            return result;
        }));
    }

    //=============================================================================================================
    //PROFILE
    //=============================================================================================================
    /**
     *  Retrieves information about the current user
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    get(failureOptions?: ApiResultFailureOptions): Observable<ApiResult<UserData>> {
        return this.tokenService.get().pipe(mergeMap(tokenResult => this.http.get<UserData>({
            url: this.getBaseUrl(),
            token: tokenResult.token,
            failureOptions: failureOptions
        })));
    }

    /**
     *  Create's a profile with the provided information
     *  @param data The data required to create a new user's profile
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    create(data: UserCreateData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<UserCreateResult>> {
        return this.http.post<UserCreateResult>({
            url: 'WebUser', //C2C should be used to create B2B users, only SOCOWEB users can self-service create profiles
            data: data,
            failureOptions: failureOptions
        }).pipe(map(result => {
            this.tokenService.set(result.data.token);
            return result;
        }));
    }

    /**
     *  Edits a profile with the provided information
     *  @param data The data required to edit a user's profile
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    edit(data: UserEditData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<UserEditResult>> {
        return this.tokenService.get().pipe(mergeMap(tokenResult => 
            this.http.patch<UserEditResult>({
            url: this.getBaseUrl(),
            data: data,
            token: tokenResult.token,
            failureOptions: failureOptions
        })));
    }

    /**
     *  Send an email validation code to the new email address specified by the user. You should store the emailValidationCodeExpirationInUnixTimeSeconds,
     *  encryptedSecret, and mac returned to be used along with the email validation code to validate the new email address. 
     *  @param data The data required to send an email validation code to a new email address
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
     sendEmailValidationCode(data: UserSendEmailValidationCodeData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<UserSendEmailValidationCodeResult>> {
        return this.tokenService.get().pipe(mergeMap(tokenResult => 
            this.http.post<UserSendEmailValidationCodeResult>({
            url: this.getBaseUrl() + '/SendEmailValidationCode',
            data: data,
            token: tokenResult.token,
            failureOptions: failureOptions
        })));
    }
    
    //=============================================================================================================
    //TERMS OF SERVICE
    //=============================================================================================================
    /**
     *  Updates the version of the terms of service that the user has accepted
     *  @param data The data required to update the terms of service
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    acceptTOS(data: UserTOSData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<UserTOSResult>> {
        return this.tokenService.get().pipe(mergeMap(tokenResult => this.http.post<UserTOSResult>({
            url: this.getBaseUrl() + '/AcceptTOS',
            data: data,
            token: tokenResult.token,
            failureOptions: failureOptions
        })));
    }

    //=============================================================================================================
    //VALIDATE EMAIL
    //=============================================================================================================
    /**
     *  Sends an email to the user to validate their email address
     *  @param data The data required to email the user so that they can validate their email address
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    sendValidateEmail(data: UserSendValidateEmailData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<UserSendValidateEmailResult>> {
        return this.tokenService.get().pipe(mergeMap(tokenResult =>this.http.post<UserSendValidateEmailResult>({
            url: this.getBaseUrl() + '/SendValidateEmail',
            data: data,
            token: tokenResult.token,
            failureOptions: failureOptions
        })));
    }

    /**
     *  Validates the email address belongs to the current user
     *  @param data The data required to validate the email address
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    validateEmail(data: UserValidateEmailData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<UserValidateEmailData>> {
        return this.tokenService.get().pipe(mergeMap(tokenResult =>this.http.post<UserValidateEmailData>({
            url: this.getBaseUrl() + '/ValidateEmail',
            data: data,
            token: tokenResult.token,
            failureOptions: failureOptions
        })));
    }

}