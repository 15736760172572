// DSS Documentation on Angular - http://tiny.sc/cgangular
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PasswordComplexityRule } from './password-complexity/password-complexity-rule';
import { UserService } from '../user/user.service';

@Directive({
    selector: '[appPasswordComplexityValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PasswordComplexityValidatorDirective, multi: true }]
})
export class PasswordComplexityValidatorDirective implements Validator, OnDestroy, OnInit {
    private rules: PasswordComplexityRule[] = [];
    private unsubscribe$ = new Subject<void>();

    constructor(private userService: UserService) {}

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {
        this.userService.getPasswordComplexityRules().pipe(takeUntil(this.unsubscribe$)).subscribe(rulesResult => {
            this.rules = rulesResult.data;
        });
    }

    validate(control: AbstractControl): ValidationErrors {
        const password: string = control.value;
        if (!password) {
            return null;
        }
        for (const rule of this.rules) {
            rule.validate(password);
            if (!rule.valid) {
                return { 'complexity': { value: rule.description } };
            }
        }
        return null;
    }
}
