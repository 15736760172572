// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// DSS Documentation on Angular Reuse - http://tiny.sc/cgangularreuse
// DSS Documentation on SocoAlertService - http://tiny.sc/cgsocoalertservice
import { SocoAlertService } from '@soco/alert';

import { ApiResultService } from '../../core/api-result.service';
import { QueryStringService } from '../../core/query-string.service';
import { UserService } from '../../user/user.service';
import { UserValidateEmailData } from '../../user/user-validate-email-data';

@Component({
  templateUrl: 'validate-email-complete.component.html'
})
export class ValidateEmailCompleteComponent implements OnInit {
    alertId: string = 'validate-email-complete-alert';
    isLoading: boolean = true;
    emailAddressValidated: boolean = false;
    ticket: string = this.queryStringService.emailValidationTicket;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private apiResultService: ApiResultService,
        private queryStringService: QueryStringService,
        private userService: UserService,
        private socoAlertService: SocoAlertService
    ) { }

    ngOnInit() {
        if (this.ticket) {
            this.validateEmail(new UserValidateEmailData(this.ticket));
        }
        else {
            setTimeout(() => {
                this.isLoading = false;
                this.socoAlertService.error({ id: this.alertId, message: 'EmailValidationTicket is required' });
            });
        }
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private validateEmail(data: UserValidateEmailData): void {
        this.userService.validateEmail(data).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.isLoading = false;
            this.emailAddressValidated = true;
            setTimeout(() => {
                this.socoAlertService.success({ id: this.alertId, message: 'Email address validation complete!' });
            });
        }, result => {
            this.isLoading = false;
            if (result && result.status == 409) {
                //if the api returns 409 the email has already been validated so just show the user
                //the message indicating email address validation is complete
                this.emailAddressValidated = true;
                setTimeout(() => {
                    this.socoAlertService.success({ id: this.alertId, message: 'Email address validation complete!' });
                });
            }
            else {
                this.apiResultService.handleHttpErrorResponse(result, { alertId: this.alertId });
            }
        });
    }
}
