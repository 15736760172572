// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// DSS Documentation on Angular Reuse - http://tiny.sc/cgangularreuse
// DSS Documentation on SocoAlertService - http://tiny.sc/cgsocoalertservice
import { SocoAlertService } from '@soco/alert'

import { ApiResultService } from '../../../core/api-result.service';
import { MFAService } from '../../mfa.service';
import { MFAPhoneListData, PhoneField } from '../../mfa-phone-list-data';
import { MFASendCodeData, MFASendCodeFormData } from '../../mfa-send-code-data';

@Component({
    templateUrl: 'mfa-phone-selection.component.html'
})
export class MFAPhoneSelectionComponent implements OnInit, OnDestroy {
    form: FormGroup;
    alertId: string = 'mfa-phone-selection';
    isLoading: boolean = false;
    phoneFields: Array<PhoneField> = null;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private apiResultService: ApiResultService,
        private mfaService: MFAService, 
        private socoAlertService: SocoAlertService,
        private formBuilder: FormBuilder,
        private router: Router
        ) { }

    ngOnInit(): void {
        this.getPhoneList(new MFAPhoneListData());
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    formSubmitted(data: MFASendCodeFormData): void {
        if (this.form.valid) {
            this.sendCode(new MFASendCodeData(this.phoneFields.find(field => field.fieldName == data.fieldName).phoneMasked, data));
        }
    }

    phoneFieldChanged(): void {
        let fieldName = this.form.get('fieldName').value;
        let deliveryMethod = this.phoneFields.find(field => field.fieldName == fieldName).deliveryMethod;
        this.form.get('phoneContactMethod').setValue(deliveryMethod);
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================    
    private getPhoneList(data: MFAPhoneListData): void {
        this.mfaService.getPhoneList(data, { alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            if (result.data.phoneList && result.data.phoneList.length > 0) {
                this.phoneFields = result.data.phoneList;
                let cellPhoneField = this.phoneFields.find(field => field.name == "Cell");
                if (cellPhoneField) {
                    this.form = this.formBuilder.group({
                        fieldName: [cellPhoneField.fieldName],
                        phoneContactMethod: [cellPhoneField.deliveryMethod]
                    });
                }
                else {
                    this.form = this.formBuilder.group({
                        fieldName: [this.phoneFields[0].fieldName],
                        phoneContactMethod: [this.phoneFields[0].deliveryMethod]
                    });
                }
            }
            else {
                this.socoAlertService.error({
                    id: this.alertId,
                    message: 'No phone numbers were found to be used for the second factor that is required to login'
                });
            }
        });
    }

    private sendCode(data: MFASendCodeData): void {
        this.isLoading = true;
        this.mfaService.sendCode(data, { alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.router.navigate(['/mfa/phone'], { queryParamsHandling: 'preserve' });
        }, result => {
            this.isLoading = false;
            this.apiResultService.handleHttpErrorResponse(result, { alertId: this.alertId, form: this.form });
        });
    }
}