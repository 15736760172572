<div class="container">
    <soco-alert [alertId]="alertId"></soco-alert>
    <soco-form *ngIf="form" [formGroup]="form" [form]="form" (formSubmitted)="formSubmitted($event)">
        <soco-form-body>
            <soco-form-section [title]="loginTitle">
                <mat-form-field>
                    <mat-label>Username</mat-label>
                    <input matInput name="username" autocomplete="username" formControlName="username" appUsernameValidator [autofocus]="!form.get('username').value">
                    <mat-error *ngIf="form.get('username').hasError('required')">Username is required</mat-error>
                    <mat-error *ngIf="form.get('username').hasError('username')">Please enter a valid username</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input matInput type="password" name="password" autocomplete="current-password" formControlName="password" [autofocus]="!!form.get('username').value">
                    <mat-error *ngIf="form.get('password').hasError('required')">Password is required</mat-error>
                </mat-form-field>
            </soco-form-section>
        </soco-form-body>
        <soco-form-footer class="text-center">
            <mat-checkbox *ngIf="!queryStringService.userID" class="login-remember-me-centered" [checked]="rememberMeChecked" (change)="rememberMeChecked = $event.checked">Remember Me</mat-checkbox>
            <button mat-raised-button color="primary" type="submit" class="mb-3">Login</button>
            <div *ngIf="!isValidatingEmail && !isEditingProfile">
                <a routerLink="/forgot/username" queryParamsHandling="preserve">Forgot Username</a>
                <span class="separator">|</span>
                <a routerLink="/forgot/password" queryParamsHandling="preserve">Forgot Password</a>
            </div>
            <ng-container *ngIf="!isB2B && !isValidatingEmail && !isEditingProfile">
                <hr class="my-4" />
                <div class="mb-4">
                    <h4>New User?</h4>
                    <a mat-raised-button routerLink="/profile/create" queryParamsHandling="preserve">Register Now</a>
                </div>
            </ng-container>
            <ng-container *ngIf="!isValidatingEmail && !isEditingProfile">
                <hr class="my-4" />
                <div>
                    <a routerLink="/profile/edit" queryParamsHandling="preserve" (click)="showEditProfileLogin()">Edit Profile/Change Password</a>
                </div>
            </ng-container>
        </soco-form-footer>
    </soco-form>
    <soco-system-message *ngIf="isLoading" message="Logging in..." messageType="Loading"></soco-system-message>
    <form #wlForm [action]="postBackUrl" method="POST">
        <input #wlToken type="hidden" name="Token" value="">
        <input type="hidden" name="ReturnUrl" [value]="queryStringService.returnUrl">
        <input type="hidden" name="ReturnMethod" [value]="queryStringService.returnMethod">
    </form>
</div>