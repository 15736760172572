// angular modules
// DSS Documentation on Angular - http://tiny.sc/cgangular
import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// angular material
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';

// soco modules
import { SocoAlertModule } from '@soco/alert';
import { SocoCommonModule } from '@soco/common';
import { SocoConfigurationModule } from '@soco/configuration';
import { SocoCoreModule } from '@soco/core';
import { SocoFormsModule } from '@soco/forms';
import { SocoModalModule } from '@soco/modal';
import { SocoUIThemeModule } from '@soco/ui-theme';

// app modules
import { CoreModule } from './core/core.module';
import { ForgotModule } from './forgot/forgot.module';
import { LoginModule } from './login/login.module';
import { MfaModule } from './mfa/mfa.module';
import { OAuthModule } from './oauth/oauth.module';
import { PasswordModule } from './password/password.module';
import { ProfileModule } from './profile/profile.module';
import { TermsOfServiceModule } from './tos/terms-of-service.module';
import { ValidateEmailModule } from './validate-email/validate-email.module';

// app component and routing
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
    imports: [
        // angular modules
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,

        // soco modules
        SocoAlertModule,
        SocoCommonModule,
        SocoConfigurationModule,
        SocoCoreModule,
        SocoFormsModule,
        SocoModalModule,
        SocoUIThemeModule,

        // app modules
        CoreModule,
        ForgotModule,
        LoginModule,
        MfaModule,
        OAuthModule,
        PasswordModule,
        ProfileModule,
        TermsOfServiceModule,
        ValidateEmailModule,

        // routing module
        AppRoutingModule
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/SPA/default/' },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }
    ],
    declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        private matIconRegistry: MatIconRegistry
    ) {
        matIconRegistry.setDefaultFontSetClass('fal');
    }
}
