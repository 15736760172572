<div class="container">
    <h2 class="page-title">Terms of Service</h2>
    <soco-alert [alertId]="alertId"></soco-alert>
    <soco-form *ngIf="form && showTOS" [formGroup]="form" [form]="form" (formSubmitted)="formSubmitted($event)" class="tos-form">
        <soco-form-section>
            <div>
                <p>
                    We have recently updated our terms of service.  In order to continue to use this Web site, you must review and accept the Terms of Service(TOS) below.
                </p>
                <div [innerHtml]="termsOfService"></div>
                <p>
                    <b>
                        If you have read and understand the foregoing agreement and agree to be bound by all of its terms, check the "I accept the Terms of Service" checkbox at the bottom of the page and then click the Submit button. 
                    </b>
                </p>
            </div>
            <div class="mb-3">
                <mat-checkbox formControlName="acceptTOS">I accept the Terms of Service</mat-checkbox>
                <mat-error *ngIf="(formSubmitAttempted || form.get('acceptTOS').dirty) && !form.get('acceptTOS').value">Please read the Terms of Service, and indicate your acceptance by checking the check box.</mat-error>
                <mat-error *ngIf="form.get('acceptTOS').hasError('server')">{{ form.get('acceptTOS').errors.server }}</mat-error>
            </div>
        </soco-form-section>
        <soco-form-footer>
            <button mat-raised-button color="primary" type="submit" [disabled]="(formSubmitAttempted || form.get('acceptTOS').dirty) && !form.get('acceptTOS').value">Submit</button>
        </soco-form-footer>
    </soco-form>
    <soco-system-message *ngIf="isLoading" messageType="Loading" message="Accepting the Terms of Service..."></soco-system-message>
</div>