<div class="container">
    <soco-alert [alertId]="alertId"></soco-alert>
    <soco-form *ngIf="form" [formGroup]="form" [form]="form" (formSubmitted)="formSubmitted($event)">
        <soco-form-body>
            <soco-form-section title="Enter Phone Number">
                <mat-form-field>
                    <mat-label>Description</mat-label>
                    <input matInput type="text" maxlength="30" formControlName="description">
                    <mat-hint>Optional</mat-hint>
                    <mat-error *ngIf="form.get('description').hasError('maxlength')">Description must be {{ form.get('description').errors.maxlength.requiredLength }} characters or less</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Phone Number</mat-label>
                    <app-tel-input formControlName="tel" required></app-tel-input>
                    <mat-icon fontIcon="fa-phone" matSuffix></mat-icon>
                    <mat-hint>Include area code</mat-hint>
                </mat-form-field>
            </soco-form-section>
        </soco-form-body>
        <soco-form-footer class="text-center">
            <button mat-raised-button color="primary" type="submit" class="mb-3">Send Code</button>
            <a mat-button queryParamsHandling="preserve" class="w-100 mb-3" (click)="goBack()">Go Back</a>
        </soco-form-footer>
    </soco-form>
    <soco-system-message *ngIf="isLoading" message="Loading..." messageType="Loading"></soco-system-message>
</div>