// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { QueryStringService } from '../../core/query-string.service';
import { OAuthConsentService } from './oauth-consent.service';
import { OAuthConsentInput } from './oauth-consent-input';
import { OAuthConsentView } from './oauth-consent-view';

@Component({
  templateUrl: 'oauth-consent.component.html'
})
export class OAuthConsentComponent implements OnInit, OnDestroy {
    form: FormGroup;
    consentView: OAuthConsentView = null;
    isLoading: boolean = true;
    private unsubscribe$ = new Subject<void>();

    constructor(
        private queryStringService: QueryStringService,
        private oAuthConsentService: OAuthConsentService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        this.isLoading = true;
        this.getConsentViewModel(this.queryStringService.oAuthConsentReturnUrl);
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    formSubmitted(): void {
        this.processConsentInputModel(this.getConsentInputModel('yes'));
    }

    deny(): void {
        this.processConsentInputModel(this.getConsentInputModel('no'));
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private getConsentViewModel(returnUrl: string): void {
        this.oAuthConsentService.viewModel(returnUrl).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.isLoading = false;
            this.consentView = result.data;
            this.form = this.formBuilder.group({
                description: '',
                rememberConsent: false,
                identityScopes: this.formBuilder.array([]),
                apiScopes: this.formBuilder.array([])
            });
            if (this.consentView.identityScopes) {
                this.consentView.identityScopes.forEach(x => 
                    this.identityScopesFormArray.push(new FormControl({ value: x.checked, disabled: x.required })
                ));
            }
            if (this.consentView.apiScopes) {
                this.consentView.apiScopes.forEach(x => 
                    this.apiScopesFormArray.push(new FormControl({ value: x.checked, disabled: x.required })
                ));
            }
        });
    }

    private processConsentInputModel(consentInputModel: OAuthConsentInput): void {
        this.isLoading = true;
        this.oAuthConsentService.process(consentInputModel).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            if (result.data.isRedirect) {
                this.goToUrl(environment.webAuthBaseUrl + result.data.redirectUri);
            }
        });
    }

    //=============================================================================================================
    //HELPER FUNCTIONS
    //=============================================================================================================
    get identityScopesFormArray() {
        return this.form.controls.identityScopes as FormArray;
    }

    get apiScopesFormArray() {
        return this.form.controls.apiScopes as FormArray;
    }

    private getConsentInputModel(button: string) {
        let formValue = this.form.getRawValue();
        let scopesConsented = [];
        if (formValue.identityScopes) {
            scopesConsented = scopesConsented.concat(formValue.identityScopes
                .map((checked, i) => checked ? this.consentView.identityScopes[i].value : null)
                .filter(v => v !== null));
        }
        if (formValue.apiScopes) {
            scopesConsented = scopesConsented.concat(formValue.apiScopes
                .map((checked, i) => checked ? this.consentView.apiScopes[i].value : null)
                .filter(v => v !== null));
        }
        return new OAuthConsentInput(button, scopesConsented, formValue.rememberConsent, this.queryStringService.oAuthConsentReturnUrl, formValue.description);
    }
    
    private goToUrl(url: string): void {
        window.location.href = url;
    }
}