// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApiResultService } from '../../core/api-result.service';
import { MFAValidationCodeData, MultiFactorAuthLevel } from '../mfa-validation-code-data';
import { MFAService } from '../mfa.service';

@Component({
    templateUrl: 'mfa-rsa-pass-code.component.html'
})
export class MFARsaPassCodeComponent implements OnInit, OnDestroy {
    form: FormGroup;
    alertId: string = 'mfa-rsa-alert';
    isLoading: boolean = false;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private apiResultService: ApiResultService,
        private mfaService: MFAService,
        private formBuilder: FormBuilder,
        private router: Router
        ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            passcode: ['', [Validators.required, Validators.maxLength(8), Validators.pattern(/^\S*$/)]]
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    formSubmitted(data: MFAValidationCodeData): void {
        if (this.form.valid) {
            data.level = MultiFactorAuthLevel.Level7;
            this.validateCode(data);
        }
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private validateCode(data: MFAValidationCodeData): void {
        this.isLoading = true;
        this.mfaService.validateCode(data).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
        }, result => {
            this.isLoading = false;
            this.apiResultService.handleHttpErrorResponse(result, { alertId: this.alertId, form: this.form });
        });
    }
}