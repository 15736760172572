<div class="container">
    <h2 class="page-title">RSA Passcode</h2>
    <soco-alert [alertId]="alertId"></soco-alert>
    <soco-form *ngIf="form" [formGroup]="form" [form]="form" (formSubmitted)="formSubmitted($event)">
        <soco-form-body>
            <soco-form-section>
                <mat-form-field>
                    <mat-label>RSA Passcode</mat-label>
                    <input matInput formControlName="passcode" maxlength="8" type="password">
                    <mat-error *ngIf="form.get('passcode').hasError('required')">RSA Passcode is required</mat-error>
                    <mat-error *ngIf="form.get('passcode').hasError('maxlength')">RSA Passcode must be {{ form.get('passcode').errors.maxlength.requiredLength }} digits or less</mat-error>
                    <mat-error *ngIf="form.get('passcode').hasError('pattern')">RSA Passcode must not contain spaces</mat-error>
                    <mat-error *ngIf="form.get('passcode').hasError('server')">{{ form.get('passcode').errors.server }}</mat-error>
                </mat-form-field>
            </soco-form-section>
            <soco-form-footer>
                <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">Login</button>
            </soco-form-footer>
        </soco-form-body>
    </soco-form>
    <soco-system-message *ngIf="isLoading" messageType="Loading" message="Validating your passcode..."></soco-system-message>
</div>