// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApiResultService } from '../../core/api-result.service';
import { UserService } from '../../user/user.service';
import { UserSendValidateEmailData } from '../../user/user-send-validate-email-data';

@Component({
  templateUrl: 'validate-email-send.component.html'
})
export class ValidateEmailSendComponent implements OnInit, OnDestroy {
    alertId: string = 'validate-email-send-alert';
    isLoading: boolean = false;
    validationEmailSent: boolean = false;
    fromEmailAddress: string = 'WebAuthentication@southernco.com';

    private unsubscribe$ = new Subject<void>();

    constructor(
        private apiResultService: ApiResultService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.sendValidateEmail(new UserSendValidateEmailData());
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private sendValidateEmail(data: UserSendValidateEmailData): void {
        this.isLoading = true;
        this.userService.sendValidateEmail(data).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.validationEmailSent = true;
            if (result.data.fromEmailAddress) {
                this.fromEmailAddress = result.data.fromEmailAddress;
            }
            this.isLoading = false;
        }, result => {
            this.isLoading = false;
            this.apiResultService.handleHttpErrorResponse(result, { alertId: this.alertId });
        });
    }
}
