export class MFARequiredMultiFactorTypeData {
    mfaConfigInternal: number = 0;
    mfaConfigUnknown: number = 0;
    mfaExcludeInternal: string = '';
    mfaExcludeUnknown: string = '';
}

export enum MFAType {
    None = 0,
    RSA = 1,
    Phone = 2
}