// DSS Documentation on Angular - http://tiny.sc/cgangular
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { AppType } from '../app/app-type.enum';
import { ReturnMethod } from './return-method.enum';
import { TargetPage } from './target-page.enum';

@Injectable({
    providedIn: 'root'
})
export class QueryStringService {
    private _type: AppType = AppType.External;
    private _appId: string = null;
    private _company: string = null;
    private _appTheme: string = null;
    private _returnUrl: string = null;
    private _cancelUrl: string = null;
    private _appBannerUrl: string = null; 
    private _addProfileLink: boolean = true;
    private _editProfileLink: boolean = true;
    private _forgotPasswordLink: boolean = true;
    private _forgotInfoLink: boolean = false;
    private _forgotInfoText: string = 'Forgot Login Info?';
    private _newUserHeaderText: string = 'New User? Register Here!';
    private _newUserText: string = null;
    private _southerncoApplication: boolean = true;
    private _compactDisplay: boolean = false;
    private _errorDisplayType: string = 'Classic';
    private _origin: string = null;
    private _browserContextTarget: string = null;
    private _returnMethod: ReturnMethod = ReturnMethod.Post;
    private _tokenEncoding: string = null;
    private _mfaConfigUnknown: number = null;
    private _mfaConfigInternal: number = null;
    private _mfaExcludeUnknown: string = null;
    private _mfaExcludeInternal: string = null;
    private _targetPage: TargetPage = TargetPage.ReturnURL;
    private _ticket: string = null;
    private _emailValidationTicket: string = null;
    private _userID: string = null;
    private _oAuthConsentReturnUrl: string = null;

    constructor() {
        this.init();
    }

    init(): void {
        let urlParams = new URLSearchParams(window.location.search);
        
        this._appId = urlParams.get('WL_AppId');
        this._appTheme = urlParams.get('AppTheme');
        this._type = AppType.parse(urlParams.get('WL_Type'));
        this._company = urlParams.get('Company');

        this._returnUrl = urlParams.get('WL_ReturnUrl');
        this._cancelUrl = urlParams.get('WL_CancelUrl');
        this._appBannerUrl = urlParams.get('AppBannerUrl');
        
        this._addProfileLink = !(/^hide$/i.test(urlParams.get('AddProfileLink')));
        this._editProfileLink = !(/^hide$/i.test(urlParams.get('EditProfileLink')));
        let forgotPwdLink = urlParams.get('ForgotPasswordLink ');
        if (/^show$/i.test(forgotPwdLink)) {
            this._forgotPasswordLink = true;
        }
        else if (/^hide$/i.test(forgotPwdLink)) {
            this._forgotPasswordLink = false;
        }
        else if (this._type == AppType.Internal) {
            this._forgotPasswordLink = false;
        }
        else {
            this._forgotPasswordLink = true;
        }

        this._forgotInfoLink = /^show$/i.test(urlParams.get('ForgotInfoLink'));
        let forgotInfoTxt = urlParams.get('ForgotInfoText');
        if (forgotInfoTxt) {
            this._forgotInfoText = forgotInfoTxt;
        }

        let newUserHeaderTxt = urlParams.get('NewUserHeaderText');
        if (newUserHeaderTxt) {
            this._newUserHeaderText = newUserHeaderTxt;
        }
        else if (this._type == AppType.Internal) {
            this._newUserHeaderText = 'New User? Sign Up!';
        }
        else {
            this._newUserHeaderText = 'New User? Register Here!';
        }
        let newUserTxt = urlParams.get('NewUserText');
        if (newUserTxt) {
            this._newUserText = newUserTxt;
        }

        this._southerncoApplication = !(/^false$/i.test(urlParams.get('SoutherncoApplication')));
        this._compactDisplay = /^true$/i.test(urlParams.get('CompactDisplay'));

        let errDisplayType = urlParams.get('ErrorDisplayType');
        if (/^modal$/i.test(errDisplayType)) {
            this._errorDisplayType = 'Modal';
        }
        else {
            this._errorDisplayType = 'Classic';
        }

        this._origin = urlParams.get('Origin');
        this._browserContextTarget = urlParams.get('BrowserContextTarget');

        let returnMethod = urlParams.get('ReturnMethod');
        if (returnMethod) {
            this._returnMethod = ReturnMethod[returnMethod];
        }

        let tknEncoding = urlParams.get('TokenEncoding');
        if (/^base64$/i.test(tknEncoding)) {
            this._tokenEncoding = 'Base64';
        }

        let mfaCfgUnknown = parseInt(urlParams.get('MFAConfigUnknown'));
        if (!isNaN(mfaCfgUnknown) && (mfaCfgUnknown == 4 || mfaCfgUnknown == 64 || mfaCfgUnknown == 68)) {
            this._mfaConfigUnknown = mfaCfgUnknown;
        }
        let mfaCfgInternal = parseInt(urlParams.get('MFAConfigInternal'));
        if (!isNaN(mfaCfgInternal) && (mfaCfgInternal == 4 || mfaCfgInternal == 64 || mfaCfgInternal == 68)) {
            this._mfaConfigInternal = mfaCfgInternal;
        }
        let mfaExUnknown = urlParams.get('MFAExcludeUnknown');
        if (mfaExUnknown) {
            this._mfaExcludeUnknown = mfaExUnknown;
        }
        let mfaExInternal = urlParams.get('MFAExcludeInternal');
        if (mfaExInternal) {
            this._mfaExcludeInternal = mfaExInternal;
        }

        let targetPage = urlParams.get('WL_TargetPage');
        if (targetPage) {
            this._targetPage = TargetPage[targetPage];
        }

        this._ticket = urlParams.get('Ticket');
        this._emailValidationTicket = urlParams.get('EmailValidationTicket');

        this._userID = urlParams.get('WL_UserID');

        this._oAuthConsentReturnUrl = urlParams.get('returnUrl');
    }

    //=============================================================================================================
    //PUBLIC FUNCTIONS
    //=============================================================================================================
    public isValid(): boolean {
        if (!this.returnUrl) {
            return false;
        }
        if (!this.appId) {
            return false;
        }

        return true;
    }

    public oAuthConsentIsValid(): boolean {
        if (!this.oAuthConsentReturnUrl) {
            return false;
        }
        return true;
    }

    public getQueryParams(): Params {
        let queryParams = {};
        if (this.type) {
            queryParams['WL_Type'] = AppType.toQueryStringValue(this.type);
        }
        if (this.appId) {
            queryParams['WL_AppId'] = this.appId;
        }
        if (this.company) {
            queryParams['Company'] = this.company;
        }
        if (this.appTheme) {
            queryParams['AppTheme'] = this.appTheme;
        }
        if (this.returnUrl) {
            queryParams['WL_ReturnUrl'] = this.returnUrl;
        }
        if (this.cancelUrl) {
            queryParams['WL_CancelUrl'] = this.cancelUrl;
        }
        if (this.appBannerUrl) {
            queryParams['AppBannerUrl'] = this.appBannerUrl;
        }
        queryParams['AddProfileLink'] = this.addProfileLink ? 'Show' : 'Hide';
        queryParams['EditProfileLink'] = this.editProfileLink ? 'Show' : 'Hide';
        queryParams['ForgotPasswordLink'] = this.forgotPasswordLink ? 'Show' : 'Hide';
        queryParams['ForgotInfoLink'] = this.forgotInfoLink ? 'Show' : 'Hide';
        if (this.forgotInfoText) {
            queryParams['ForgotInfoText'] = this.forgotInfoText;
        }
        if (this.newUserHeaderText) {
            queryParams['NewUserHeaderText'] = this.newUserHeaderText;
        }
        if (this.newUserText) {
            queryParams['NewUserText'] = this.newUserText;
        }
        queryParams['SoutherncoApplication'] = this.southerncoApplication ? 'True' : 'False';
        queryParams['CompactDisplay'] = this.compactDisplay ? 'True' : 'False';
        if (this.errorDisplayType) {
            queryParams['ErrorDisplayType'] = this.errorDisplayType;
        }
        if (this.origin) {
            queryParams['Origin'] = this.origin;
        }
        if (this.browserContextTarget) {
            queryParams['BrowserContextTarget'] = this.browserContextTarget;
        }
        queryParams['ReturnMethod'] = this.returnMethod == ReturnMethod.Post ? null : ReturnMethod[this.returnMethod];
        if (this.tokenEncoding) {
            queryParams['TokenEncoding'] = this.tokenEncoding;
        }
        if (this.mfaConfigUnknown) {
            queryParams['MFAConfigUnknown'] = this.mfaConfigUnknown;
        }
        if (this.mfaConfigInternal) {
            queryParams['MFAConfigInternal'] = this.mfaConfigInternal;
        }
        if (this.mfaExcludeUnknown) {
            queryParams['MFAExcludeUnknown'] = this.mfaExcludeUnknown;
        }
        if (this.mfaExcludeInternal) {
            queryParams['MFAExcludeInternal'] = this.mfaExcludeInternal;
        }
        if (this.emailValidationTicket) {
            queryParams['EmailValidationTicket'] = this.emailValidationTicket;
        }
        queryParams['WL_TargetPage'] = this.targetPage == TargetPage.ReturnURL ? null : TargetPage[this.targetPage];
        return queryParams;
    }

    //=============================================================================================================
    //GETTERS
    //=============================================================================================================
    public get type(): AppType {
        return this._type;
    }
    public get appId(): string {
        return this._appId;
    }
    public get company(): string {
        return this._company;
    }
    public get appTheme(): string {
        return this._appTheme;
    }
    public get returnUrl(): string {
        return this._returnUrl;
    }
    public get cancelUrl(): string {
        return this._cancelUrl;
    }
    public get appBannerUrl(): string {
        return this._appBannerUrl;
    } 
    public get addProfileLink(): boolean {
        return this._addProfileLink;
    }
    public get editProfileLink(): boolean {
        return this._editProfileLink;
    }
    public get forgotPasswordLink(): boolean {
        return this._forgotPasswordLink;
    }
    public get forgotInfoLink(): boolean {
        return this._forgotInfoLink;
    }
    public get forgotInfoText(): string {
        return this._forgotInfoText;
    }
    public get newUserHeaderText(): string {
        return this._newUserHeaderText;
    }
    public get newUserText(): string {
        return this._newUserText;
    }
    public get southerncoApplication(): boolean {
        return this._southerncoApplication;
    }
    public get compactDisplay(): boolean {
        return this._compactDisplay;
    }
    public get errorDisplayType(): string {
        return this._errorDisplayType;
    }
    public get origin(): string {
        return this._origin;
    }
    public get browserContextTarget(): string {
        return this._browserContextTarget;
    }
    public get returnMethod(): ReturnMethod {
        return this._returnMethod;
    }
    public set returnMethod(method: ReturnMethod) {
        this._returnMethod = method;
    }
    public get tokenEncoding(): string {
        return this._tokenEncoding;
    }
    public get mfaConfigUnknown(): number {
        return this._mfaConfigUnknown;
    }
    public get mfaConfigInternal(): number {
        return this._mfaConfigInternal;
    }
    public get mfaExcludeUnknown(): string {
        return this._mfaExcludeUnknown;
    }
    public get mfaExcludeInternal(): string {
        return this._mfaExcludeInternal;
    }
    public get targetPage(): TargetPage {
        return this._targetPage;
    }
    public set targetPage(page: TargetPage) {
        this._targetPage = page;
    }
    public get ticket(): string {
        return this._ticket;
    }
    public get emailValidationTicket(): string {
        return this._emailValidationTicket;
    }
    public get userID(): string {
        return this._userID;
    }
    public get oAuthConsentReturnUrl(): string {
        return this._oAuthConsentReturnUrl;
    }
}