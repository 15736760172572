// DSS Documentation on Angular - http://tiny.sc/cgangular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// DSS Documentation on ApiResult - http://tiny.sc/cgapiresult
import { ApiResult } from '@soco/core';

import { ApiResultFailureOptions } from '../../core/api-result.service';
import { environment } from '../../../environments/environment';
import { OAuthConsentInput } from './oauth-consent-input';
import { OAuthConsentResult } from './oauth-consent-result';
import { OAuthConsentView } from './oauth-consent-view';
import { WebAuthHttpService } from '../../core/webauth-http.service';

@Injectable({
    providedIn: 'root'
})
export class OAuthConsentService {
    private baseUrl = environment.oauthBaseUrl + '/api/Consent';

    constructor(
        private http: WebAuthHttpService
    ) { }

    /**
     *  Gets the data needed to display the scopes the application is requesting the user consent to
     *  @param returnUrl The URL to redirect to after the user has indicated their consent
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    viewModel(returnUrl: string, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<OAuthConsentView>> {
        return this.http.get<OAuthConsentView>({
            url: this.baseUrl + '/ViewModel',
            excludeWebApiBaseUrl: true,
            withCredentials: true,
            params: { returnUrl: returnUrl },
            failureOptions: failureOptions
        });
    }

    /**
     *  Processes the user's decision about consenting to the requested scopes
     *  @param consentInputModel The data needed to indicate which scopes the user consented to
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    process(consentInputModel: OAuthConsentInput, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<OAuthConsentResult>> {
        return this.http.post<OAuthConsentResult>({
            url: this.baseUrl + '/Process',
            excludeWebApiBaseUrl: true,
            excludeDataAddCommonData: true,
            withCredentials: true,
            data: consentInputModel,
            failureOptions: failureOptions
        });
    }
}