<div class="container">
    <h2 *ngIf="form">{{title}}</h2>
    <soco-alert [alertId]="alertId"></soco-alert>
    <div class="mb-4" *ngIf="isSouthernCo">
        <div>
            <a href="https://tiny.sc/webauthsoutherncoresetpassword" target="_blank">Reset Password</a>
        </div>
        <div>
            <a href="https://tiny.sc/webauthsoutherncochangephonenumbers" target="_blank">
                Change Phone Numbers
            </a>
        </div>
    </div>
    <soco-form *ngIf="form" [formGroup]="form" [form]="form" (formSubmitted)="formSubmitted($event, myForm)" #myForm="ngForm"
        [appFormValuesAreEqualCrossValidator]="[{controls:[form.get('password'), form.get('retypePassword')]}]"
        [appFormValuesNotEqualCrossValidator]="[{controls:[form.get('username'), form.get('password')],ignoreCase:true}]">
        <soco-form-body  *ngIf="!isSouthernCo && !visibleSection">
            <soco-form-section>
                <div class="mb-4">
                    <hr>
                    <div class="d-flex align-items-center">
                        <div>
                            <div class="font-weight-normal">Name</div>
                            <div>{{form.get('firstName').value}} {{form.get('lastName').value}}</div>
                        </div>
                        <div class="ml-auto">
                            <a (click)="setVisibleSection('Name')">Edit</a>
                        </div>
                    </div>
                    <div *ngIf="isSocoB2b">
                        <hr>
                        <div class="d-flex align-items-center">
                            <div>
                                <div class="font-weight-normal">Phone</div>
                                <div>{{form.get('phone1').value || 'N/A'}}</div>
                            </div>
                            <div class="ml-auto">
                                <a (click)="setVisibleSection('Phone Number')">Edit</a>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="d-flex align-items-center">
                        <div>
                            <div class="font-weight-normal">Email</div>
                            <div>{{form.get('emailAddress').value}}</div>
                            <div *ngIf="!isSocoB2b || (form.get('username').value != form.get('emailAddress').value)" class="small font-weight-light">Used for password reset</div>
                            <div *ngIf="isSocoB2b && (form.get('username').value == form.get('emailAddress').value)" class="small font-weight-light">Used for username & password reset</div>
                        </div>
                        <div class="ml-auto">
                            <a (click)="setVisibleSection('Email')">Edit</a>
                        </div>
                    </div>
                    <div *ngIf="!isSocoB2b || (form.get('username').value != form.get('emailAddress').value)">
                        <hr>
                        <div class="d-flex align-items-center">
                            <div>
                                <div class="font-weight-normal">Username</div>
                                <div>{{form.get('username').value}}</div>
                            </div>
                            <div class="ml-auto">
                                <a (click)="setVisibleSection('Username')">Edit</a>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="d-flex align-items-center">
                        <div>
                            <div class="font-weight-normal">Password</div>
                            <div>●●●●●●●●●●●●●●●●</div>
                        </div>
                        <div class="ml-auto">
                            <a (click)="setVisibleSection('Password')">Edit</a>
                        </div>
                    </div>
                    <div *ngIf="isSocoB2b">
                        <hr>
                        <div class="d-flex align-items-center">
                            <div>
                                <div class="font-weight-normal">Two-Factor Authentication</div>
                                <i class="fa mr-2" [ngClass]="{ 'fa-check-circle text-success': is2faEnabled, 'fa-times-circle text-danger': !is2faEnabled }"></i>{{ ( is2faEnabled ? 'Enabled' : 'Disabled' ) }}
                            </div>
                            <div class="ml-auto">
                                <a class="float-right" routerLink="/mfa/otp/edit" queryParamsHandling="preserve">Edit</a>
                            </div>
                        </div>
                    </div>
                </div>
            </soco-form-section>
        </soco-form-body>
        <soco-form-body *ngIf="!isSouthernCo && visibleSection">
            <soco-form-section class="mb-4">
                <div *ngIf="isSectionVisible('Name')">
                    <mat-form-field>
                        <mat-label>First Name</mat-label>
                        <input matInput formControlName="firstName" maxlength="50">
                        <mat-error *ngIf="form.get('firstName').hasError('required')">First Name is required</mat-error>
                        <mat-error *ngIf="form.get('firstName').hasError('maxlength')">First Name must be {{ form.get('firstName').errors.maxlength.requiredLength }} characters or less</mat-error>
                        <mat-error *ngIf="form.get('firstName').hasError('server')">{{ form.get('firstName').errors.server }}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Last Name</mat-label>
                        <input matInput formControlName="lastName" maxlength="60">
                        <mat-error *ngIf="form.get('lastName').hasError('required')">Last Name is required</mat-error>
                        <mat-error *ngIf="form.get('lastName').hasError('maxlength')">Last Name must be {{ form.get('lastName').errors.maxlength.requiredLength }} characters or less</mat-error>
                        <mat-error *ngIf="form.get('lastName').hasError('server')">{{ form.get('lastName').errors.server }}</mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field *ngIf="isSectionVisible('Phone Number')">
                    <mat-label>Phone Number</mat-label>
                    <app-tel-input formControlName="phone1Parts" required></app-tel-input>
                    <mat-icon fontIcon="fa-phone" matSuffix></mat-icon>
                    <mat-hint>Include area code</mat-hint>
                    <mat-error *ngIf="form.get('phone1').hasError('required')">Phone Number is required</mat-error>
                    <mat-error *ngIf="form.get('phone1').hasError('server')">{{ form.get('phone1').errors.server }}</mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="isSectionVisible('Email')">
                    <mat-label>New Email</mat-label>
                    <input matInput formControlName="emailAddress" maxlength="64" appEmailValidator>
                    <mat-error *ngIf="form.get('emailAddress').hasError('required')">Email Address is required</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('maxlength')">Email Address must be {{ form.get('emailAddress').errors.maxlength.requiredLength }} characters or less</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('email') || form.get('emailAddress').hasError('socoEmailValidation')">Please enter a valid email address</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('socoEmail')">Southern Company employees do not need to enter new user data. Enter your Southern Company email and password at the Login page.</mat-error>
                    <mat-error *ngIf="form.get('emailAddress').hasError('server')">{{ form.get('emailAddress').errors.server }}<span *ngIf="form.get('emailAddress').errors.server.includes('existing')"> Please click <a routerLink="/forgot/username" queryParamsHandling="preserve">here</a> to retrieve your username.</span>
                    </mat-error>
                </mat-form-field>
                <div *ngIf="isSectionVisible('Email Validation Code')">
                    <h3>Enter code recieved at <strong>{{form.get('emailAddress').value}}</strong></h3>
                    <div class="mb-4">
                        <a (click)="resendCodeOrEditEmail()">Resend code or edit email</a>
                    </div>
                    <mat-form-field> 
                        <mat-label>One-Time Code</mat-label>
                        <input matInput formControlName="emailValidationCode">
                        <mat-error *ngIf="form.get('emailValidationCode').hasError('required')">One-Time Code is required</mat-error>
                        <mat-error *ngIf="form.get('emailValidationCode').hasError('server')">{{ form.get('emailValidationCode').errors.server }}</mat-error>
                    </mat-form-field>
                </div>

                <mat-form-field *ngIf="isSectionVisible('Username')"> 
                    <mat-label>Username</mat-label>
                    <input matInput formControlName="username" maxlength="42" appUsernameValidator isNewUsername="true">
                    <mat-error *ngIf="form.get('username').hasError('required')">Username is required</mat-error>
                    <mat-error *ngIf="form.get('username').hasError('maxlength')">Username must be {{ form.get('username').errors.maxlength.requiredLength }} characters or less</mat-error>
                    <mat-error *ngIf="form.get('username').hasError('username')">Please enter a Username that contains no spaces and only contains letters and numbers, or which is a valid email address</mat-error>
                    <mat-error *ngIf="form.get('username').hasError('socoEmail')">Please enter a Username that does not end with {{form.get('username').errors.socoEmail.suffixUsed}}</mat-error>
                    <mat-error *ngIf="form.get('username').hasError('server')">{{ form.get('username').errors.server }}</mat-error>
                </mat-form-field>

                <div *ngIf="isSectionVisible('Password')">
                    <mat-form-field >
                        <mat-label>Current Password</mat-label>
                        <input matInput type="password" name="current-password" autocomplete="current-password" formControlName="currentPassword" maxlength="32" (input)="passwordChanged()">
                        <mat-error *ngIf="form.get('currentPassword').hasError('required')">Current Password is required to update your password</mat-error>
                        <mat-error *ngIf="form.get('currentPassword').hasError('server')">{{ form.get('currentPassword').errors.server }}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Password (Case-Sensitive)</mat-label>
                        <input matInput type="password" name="new-password" autocomplete="new-password" formControlName="password" maxlength="32" (input)="passwordComplexity.password = $event.target.value; passwordChanged()" appPasswordComplexityValidator>
                        <mat-error *ngIf="form.get('password').hasError('required')">Password is required</mat-error>
                        <mat-error *ngIf="form.get('password').hasError('maxlength')">Password must be {{ form.get('password').errors.maxlength.requiredLength }} characters or less</mat-error>
                        <mat-error *ngIf="form.get('password').hasError('pattern')">Password cannot contain spaces</mat-error>
                        <mat-error *ngIf="form.get('password').hasError('complexity')">Password must meet complexity requirements</mat-error>
                        <mat-error *ngIf="form.get('password').hasError('formValuesAreEqualCrossValidator')">Passwords do not match</mat-error>
                        <mat-error *ngIf="form.get('password').hasError('formValuesNotEqualCrossValidator')">Password cannot match Username</mat-error>
                        <mat-error *ngIf="form.get('password').hasError('server')">{{ form.get('password').errors.server }}</mat-error>
                    </mat-form-field>
                    <app-password-complexity #passwordComplexity></app-password-complexity>
                    <mat-form-field>
                        <mat-label>Retype Password</mat-label>
                        <input matInput type="password" name="confirm-password" autocomplete="new-password" formControlName="retypePassword" (input)="passwordChanged()">
                        <mat-error *ngIf="form.get('retypePassword').hasError('required')">Please retype your password to ensure correct entry</mat-error>
                        <mat-error *ngIf="form.get('retypePassword').hasError('formValuesAreEqualCrossValidator')">Passwords do not match</mat-error>
                        <mat-error *ngIf="form.get('retypePassword').hasError('server')">{{ form.get('retypePassword').errors.server }}</mat-error>
                    </mat-form-field>
                </div>
            </soco-form-section>
        </soco-form-body>
        <soco-form-footer>
            <button mat-raised-button *ngIf="!isSouthernCo" color="primary" type="submit">{{visibleSection ? (visibleSection == 'Email' ? 'Next' : 'Submit') : 'Continue'}}</button>
            <a *ngIf="queryStringService.cancelUrl || visibleSection" mat-button (click)="cancel(myForm)">Cancel</a>
        </soco-form-footer>
    </soco-form>
    <soco-system-message *ngIf="isLoading" messageType="Loading" message="Submitting Request..."></soco-system-message>
</div>