// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-customer-service',
  templateUrl: 'customer-service.component.html'
})
export class CustomerServiceComponent {
  public companies: any[] = [
    {
      name: 'Alabama Power',
      phone: '1-800-245-2244',
      email: 'CustomerService@AlabamaPower.com',
      icon: 'favicon.png'
    },
    {
      name: 'Atlanta Gas Light',
      phone: '1-800-427-5463',
      email: 'g2custserv@southernco.com',
      icon: 'favicon.png'
    },
    {
      name: 'Georgia Power',
      phone: '1-888-660-5890',
      email: 'CustomerService@GeorgiaPower.com',
      icon: 'favicon.png'
    },
    {
      name: 'Gulf Power',
      phone: '1-800-225-5797',
      email: 'CustomerService@GulfPower.com',
      icon: 'favicon.png'
    },
    {
      name: 'Mississippi Power',
      phone: '1-866-251-1943',
      email: 'CustomerService@MississippiPower.com',
      icon: 'favicon.png'
    },
    {
      name: 'Southern Company',
      phone: '1-888-660-5890',
      email: 'CustomerService@SouthernCompany.com',
      icon: 'favicon.png'
    }
  ];
  public _expanded: boolean;
  @Input() set expanded(value: boolean) {
    this._expanded = value;
    if (this.expanded) {
        this.panel.open();
    } else {
        this.panel.close();
    }
  }
  get expanded(): boolean {
    return this._expanded;
  }
  
  @ViewChild('panel') public panel: MatExpansionPanel;

  constructor(public el: ElementRef) { }

  //=============================================================================================================
  //EVENT HANDLERS
  //=============================================================================================================
  afterExpand(): void {
    this.el.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center'
    });
  }
}
