// DSS Documentation on Angular - http://tiny.sc/cgangular
//DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApiResultService } from '../core/api-result.service';
import { AppService } from '../app/app.service';
import { UserService } from '../user/user.service';
import { UserTOSData, UserTOSWithConfirmData } from '../user/user-tos-data';

@Component({
    templateUrl: 'terms-of-service-update.component.html'
})
export class TermsOfServiceUpdateComponent implements OnInit, OnDestroy {
    form: FormGroup;
    alertId: string = 'tos-update-alert';
    isLoading: boolean = false;
    termsOfService: string = null;
    showTOS: boolean = false;
    formSubmitAttempted: boolean = false;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private apiResultService: ApiResultService,
        private appService: AppService, 
        private userService: UserService,
        private formBuilder: FormBuilder,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({ acceptTOS: false });
        this.getTermsOfService();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    formSubmitted(data: UserTOSWithConfirmData): void {
        this.formSubmitAttempted = true;

        if (this.form.valid && data.acceptTOS) {
            this.acceptTermsOfService(new UserTOSData());
        }
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================    
    private getTermsOfService(): void {
        this.appService.getTermsOfService({ alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.termsOfService = result.data;
            this.showTOS = true;
        });
    }

    private acceptTermsOfService(data: UserTOSData): void {
        this.isLoading = true;
        this.userService.acceptTOS(data).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
        }, result => {
            this.isLoading = false;
            this.apiResultService.handleHttpErrorResponse(result, { alertId: this.alertId, form: this.form });
        });
    }
}