// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MFAService } from '../../../mfa.service';
import { MFAOTPSecretData } from '../../../mfa-otp-secret-data';
import { Tel } from '../../../../core/tel-input/tel-input.component';
import { SocoAlertService } from '@soco/alert';

@Component({
    templateUrl: 'otp-phone-new.component.html'
})
export class OtpPhoneNewComponent implements AfterViewInit, OnDestroy {
    form: FormGroup;
    alertId: string = 'otp-phone-new-alert';
    isLoading: boolean = false;
    qrdata: string = null;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private socoAlertService: SocoAlertService,
        private mfaService: MFAService,
        private formBuilder: FormBuilder,
        private router: Router) { }

    ngAfterViewInit(): void {
        //initialize the form
        setTimeout(() => {
            this.form = this.formBuilder.group({
                description: ['', Validators.maxLength(30)],
                tel: new FormControl(null)
            });
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    formSubmitted(): void {
        if (this.form.valid) {
            let tel: Tel = this.form.controls['tel'].value;
            // validate that the tel values match the expected patterns
            if (!/\d{3}/.test(tel.area) || !/\d{3}/.test(tel.exchange) || !/\d{4}/.test(tel.subscriber)) {
                this.socoAlertService.error({ message: 'Please enter a valid phone number.', id: this.alertId, scrollToAlert: true });
                return;
            }

            let data: MFAOTPSecretData = {
                slot: this.mfaService.otpStateSlot(),
                method: this.mfaService.otpStateMethod(),
                phoneNumber: `${tel.area}-${tel.exchange}-${tel.subscriber}`
            };
            this.isLoading = true;
            this.mfaService.newOtpSecret(data, { alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(otpSecretResult => {
                // pass as state to validate page
                this.router.navigate(['/mfa/otp/validate'], {
                    queryParamsHandling: 'preserve',
                    state: {
                        data: {
                            otpStateSlot: this.mfaService.otpStateSlot(),
                            otpStateMethod: this.mfaService.otpStateMethod(),
                            otpStateSecretResult: otpSecretResult.data,
                            otpStateDescription: this.form.controls['description'].value || ''
                        }
                    }
                });
            }, error => {
                this.isLoading = false;
            });
        }
    }

    goBack(): void {
        history.back();
    }
}
