
<div class="container">
    <h2 class="page-title">Please Validate you Email Address</h2>
    <soco-alert [alertId]="alertId"></soco-alert>
    <div *ngIf="profile">
        <p>
            We have enhanced our login process, and we need your help in validating the email address we have on file for you.
            Please click on the "Validate Email Address" button below.
        </p>
        <p>
            We will send an email shortly to <strong>{{ profile.emailAddress }}</strong>.
            When you receive the email, please click the link in the email to continue the validation process.
            <span *ngIf="!emailRecentlyChangedViaEditProfile">(If the email address is incorrect, click the "Edit Email Address" button below to update your email address.)</span>
            <strong>You will need to complete this within 4 hours.</strong>
        </p>
        <p>
            If you would like to validate your email address at a later time, click "Remind Me Later".
        </p>

        <div id="validate-email-submit" class="wa_group _group">
            <fieldset class="wa_item">
                <button center mat-raised-button color="primary" routerLink="/validate-email/send" queryParamsHandling="preserve" class="w-100 mb-3">Validate Email Address</button>
            </fieldset>
            <fieldset *ngIf="!emailRecentlyChangedViaEditProfile" class="wa_item">
                <button center mat-raised-button color="primary" routerLink="/validate-email/edit" queryParamsHandling="preserve"  class="w-100 mb-3">Edit Email Address</button>       
            </fieldset>
            <fieldset class="wa_item">
                <button center  mat-raised-button color="primary" routerLink="/validate-email/later" queryParamsHandling="preserve" class="w-100 mb-3">Remind Me Later</button>
            </fieldset>
        </div>
    </div>
</div>