// DSS Documentation on Angular - http://tiny.sc/cgangular
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

// DSS Documentation on ApiResult - http://tiny.sc/cgapiresult
import { ApiResult } from '@soco/core';

import { ApiResultFailureOptions } from '../core/api-result.service';
import { MFA2FAConfigResult } from './mfa-2fa-config-data';
import { MFAOTPSecretData, MFAOTPSecretResult } from './mfa-otp-secret-data';
import { MFAValidateOTPData } from './mfa-validate-otp-data';
import { MFAPhoneOTPData } from './mfa-phone-otp-data';
import { MFAPhoneListResult, MFAPhoneListData } from './mfa-phone-list-data';
import { MFASendCodeData } from './mfa-send-code-data';
import { MFARequiredMultiFactorTypeData, MFAType } from './mfa-required-multi-factor-type-data';
import { MFAValidationCodeData, MFAValidationCodeResult } from './mfa-validation-code-data';
import { QueryStringService } from '../core/query-string.service';
import { TokenService } from '../core/token.service';
import { WebAuthHttpService } from '../core/webauth-http.service';
import { MFA2FASlot } from './mfa-2fa-slot.enum';
import { MFA2FAMethod } from './mfa-2fa-method.enum';

@Injectable({
    providedIn: 'root'
})
export class MFAService {
    baseUrl: string = 'MFA';
    private mfa2faConfig: MFA2FAConfigResult = null;

    constructor(
        private queryStringService: QueryStringService,
        private tokenService: TokenService,
        private http: WebAuthHttpService
    ) { }

    //=============================================================================================================
    //LOGIN
    //=============================================================================================================
    /**
     * Returns the MFA type required by the current MFA config
     */
    getRequiredMfaType(): Observable<MFAType> {
        let data = new MFARequiredMultiFactorTypeData();
        data.mfaConfigInternal = this.queryStringService.mfaConfigInternal;
        data.mfaConfigUnknown = this.queryStringService.mfaConfigUnknown;
        data.mfaExcludeInternal = this.queryStringService.mfaExcludeInternal;
        data.mfaExcludeUnknown = this.queryStringService.mfaExcludeUnknown;

        if (data.mfaConfigInternal == null && data.mfaConfigUnknown == null) {
            return of(MFAType.None);
        }
        else if (data.mfaConfigInternal == null) {
            data.mfaConfigInternal = 0;
        }
        else if (data.mfaConfigUnknown == null) {
            data.mfaConfigUnknown = 0;
        }

        return this.getRequiredMultiFactorType(data).pipe(map(result => {
            if (result.status) {
                return MFAType[result.data];
            }
            return MFAType.None;
        }), catchError(err => {
            throw 'Failed to retrieve the required multi-factor type';
        }));
    }

    /**
     *  
     *  @param data The data needed to determine which MFA type (if any) is needed
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    getRequiredMultiFactorType(data: MFARequiredMultiFactorTypeData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<string>> {
        return this.tokenService.get().pipe(mergeMap(tokenResult => 
            this.http.get<string>({
                url: this.baseUrl + '/GetRequiredMultiFactorType',
                params: data,
                token: tokenResult.token,
                failureOptions: failureOptions
            })
        ));
    }

    //=============================================================================================================
    //MFA PHONE SELECTION
    //=============================================================================================================
    /**
     *  
     *  @param data The data needed to retrieve the list of phone numbers available for the user
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    getPhoneList(data: MFAPhoneListData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<MFAPhoneListResult>> {
        return this.tokenService.get().pipe(mergeMap(tokenResult => 
            this.http.get<MFAPhoneListResult>({
                url: this.baseUrl + '/GetPhoneList',
                params: data,
                token: tokenResult.token,
                failureOptions: failureOptions
            })
        ));
    }

    //=============================================================================================================
    //MFA PHONE AND RSA
    //=============================================================================================================
    /**
     *  
     *  @param data Indicates how and where to send the passcode
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    sendCode(data: MFASendCodeData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<MFASendCodeData>> {
        return this.tokenService.get().pipe(mergeMap(tokenResult => 
            this.http.post<MFASendCodeData>({
                url: this.baseUrl + '/SendCode',
                token: tokenResult.token,
                data: data,
                failureOptions: failureOptions
            })
        ));
    }

    /**
     *  
     *  @param data The code to validate
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    validateCode(data: MFAValidationCodeData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<MFAValidationCodeResult>> {
        return this.tokenService.get().pipe(mergeMap(tokenResult => 
            this.http.post<MFAValidationCodeResult>({
                url: this.baseUrl + '/ValidateCode',
                token: tokenResult.token,
                data: data,
                failureOptions: failureOptions
            })
        )).pipe(map(result => {
            this.tokenService.set(result.data.userToken);
            return result;
        }));
    }

    //=============================================================================================================
    //MFA OTP
    //=============================================================================================================
    /**
     *  
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    get2faConfig(failureOptions?: ApiResultFailureOptions): Observable<ApiResult<MFA2FAConfigResult>> {
        if (!!this.mfa2faConfig) {
            return of({
                status: true,
                statusCode: 200,
                data: this.mfa2faConfig,
                message: null,
                modelErrors: null
            });
        }
        return this.tokenService.get().pipe(mergeMap(tokenResult => 
            this.http.get<MFA2FAConfigResult>({
                url: this.baseUrl + '/Get2FAConfig',
                token: tokenResult.token,
                failureOptions: failureOptions
            }).pipe(map(result => {
                this.mfa2faConfig = result.data;
                return result;
            }))
        ));
    }

    /**
     * 
     * @param data Specifies how to create the new OTP secret
     * @param failureOptions The optional parameters that can be used to handle failures
     */
    newOtpSecret(data: MFAOTPSecretData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<MFAOTPSecretResult>> {
        return this.tokenService.get().pipe(mergeMap(tokenResult => 
            this.http.get<MFAOTPSecretResult>({
                url: this.baseUrl + '/NewOTPSecret',
                token: tokenResult.token,
                failureOptions: failureOptions,
                params: data
            })
        ));
    }

    /**
     * 
     * @param data Information to validate including the OTP
     * @param failureOptions The optional parameters that can be used to handle failures
     */
    validateOtp(data: MFAValidateOTPData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<string>> {
        return this.tokenService.get().pipe(mergeMap(tokenResult => 
            this.http.post<string>({
                url: this.baseUrl + '/ValidateOTP',
                token: tokenResult.token,
                failureOptions: failureOptions,
                data: data
            })
        )).pipe(map(result => {
            if (!!data.secret && !!this.mfa2faConfig) {
                // update the cached 2fa config
                if (data.slot === MFA2FASlot.Primary) {
                    this.mfa2faConfig.primary.description = data.description;
                    this.mfa2faConfig.primary.method = data.method;
                } else if (data.slot === MFA2FASlot.Secondary) {
                    this.mfa2faConfig.secondary.description = data.description;
                    this.mfa2faConfig.secondary.method = data.method;
                }
            }
            if (!!result.data) {
                this.tokenService.set(result.data);
            }
            return result;
        }));
    }

    /**
     * 
     * @param data Indicates which 2fa slot to send a phone OTP to
     * @param failureOptions The optional parameters that can be used to handle failures
     */
    sendPhoneOtp(data: MFAPhoneOTPData, failureOptions?: ApiResultFailureOptions): Observable<ApiResult<MFAPhoneOTPData>> {
        return this.tokenService.get().pipe(mergeMap(tokenResult => 
            this.http.post<MFAPhoneOTPData>({
                url: this.baseUrl + '/SendPhoneOTP',
                token: tokenResult.token,
                failureOptions: failureOptions,
                data: data
            })
        ));
    }

    /**
     * 
     * @param type Valid types are hotp or totp
     * @param username The account name of the user
     * @param secret The otp secret
     * @param issuer The name of the issuing party
     * @returns An otpauth scheme uri
     */
    otpAuthUri(type: 'totp' | 'hotp', username: string, secret: string, issuer: string = 'Southern Company', algorithm: 'SHA1' | 'SHA256' | 'SHA512' = 'SHA1', digits: 6 | 8 = 6, counter: number = 0, period: number = 30): string {
        // issuer prefix and account name should be separated by literal or url-encoded colon
        let label = encodeURIComponent(issuer + ':' + username);
        // secret should be unchanged here since it is expected to be base32
        secret = encodeURIComponent(secret);
        // it is recommended to provide issuer both in the label prefix and as the issuer parameter
        issuer = encodeURIComponent(issuer);
        let uri = 'otpauth://' + type + '/' + label + '?secret=' + secret + '&issuer=' + issuer;
        // if algorithm is the default SHA1, the parameter does not need to be included
        if (algorithm !== 'SHA1') {
            uri += '&algorithm=' + algorithm;
        }
        // if digits is the default 6, the parameter does not need to be included
        if (digits !== 6) {
            uri += '&digits=' + digits;
        }
        // counter parameter is required if type is hotp
        if (type == 'hotp') {
            uri += '&counter=' + counter;
        }
        // if type is not totp or period is the default 30, the parameter does not need to be included
        if (type == 'totp' && period !== 30) {
            uri += '&period=' + period;
        }
        return uri;
    }

    /**
     * 
     * @returns The current state's 2fa slot
     */
    otpStateSlot(): MFA2FASlot {
        return history.state?.data?.otpStateSlot || MFA2FASlot.None;
    }

    /**
     * 
     * @returns The current state's 2fa slot name
     */
    otpStateSlotName(): string {
        return MFA2FASlot[this.otpStateSlot()];
    }

    /**
     * 
     * @returns The current state's 2fa method
     */
    otpStateMethod(): MFA2FAMethod {
        return history.state?.data?.otpStateMethod || MFA2FAMethod.None;
    }

    /**
     * 
     * @returns The current state's 2fa method name
     */
    otpStateMethodName(): string {
        return MFA2FAMethod[this.otpStateMethod()];
    }

    /**
     * 
     * @returns The current state's otp secret result
     */
    otpStateSecretResult(): MFAOTPSecretResult {
        return history.state?.data?.otpStateSecretResult;
    }

    /**
     * 
     * @returns The current state's otp description
     */
    otpStateDescription(): string {
        return history.state?.data?.otpStateDescription || '';
    }
}