// angular modules
// DSS Documentation on Angular - http://tiny.sc/cgangular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

// soco modules
import { SocoAlertModule } from '@soco/alert';
import { SocoCommonModule } from '@soco/common';
import { SocoConfigurationModule } from '@soco/configuration';
import { SocoFormsModule } from '@soco/forms';

// components
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { AutoFocusDirective } from './auto-focus.directive';
import { CustomerServiceComponent } from './customer-service/customer-service.component';
import { EmailValidatorDirective } from './email-validator.directive';
import { FormValuesAreEqualCrossValidatorDirective } from './form-values-are-equal-cross-validator.directive';
import { FormValuesNotEqualCrossValidatorDirective } from './form-values-not-equal-cross-validator.directive';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PasswordComplexityComponent } from './password-complexity/password-complexity.component';
import { RequestNotValidComponent } from './request-not-valid/request-not-valid.component';
import { PasswordComplexityValidatorDirective } from './password-complexity-validator.directive';
import { UsernameValidatorDirective } from './username-validator.directive';
import { TelInputComponent } from './tel-input/tel-input.component';

const MODULE_DIRECTIVES = [
    AutoFocusDirective,
    CustomerServiceComponent,
    EmailValidatorDirective,
    FormValuesAreEqualCrossValidatorDirective,
    FormValuesNotEqualCrossValidatorDirective,
    PageNotFoundComponent,
    PasswordComplexityComponent,
    RequestNotValidComponent,
    PasswordComplexityValidatorDirective,
    UsernameValidatorDirective,
    TelInputComponent
];

@NgModule({
    imports: [
        // angular modules
        CommonModule,
        RouterModule,
        MatExpansionModule,
        MatIconModule,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,

        // soco modules
        SocoAlertModule,
        SocoCommonModule,
        SocoConfigurationModule,
        SocoFormsModule
    ],
    exports: MODULE_DIRECTIVES,
    declarations: MODULE_DIRECTIVES
})
export class CoreModule { }

