//angular modules
//DSS Documentation on Angular - http://tiny.sc/cgangular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//soco modules
import { SocoAlertModule } from '@soco/alert';
import { SocoCommonModule } from '@soco/common';
import { SocoFormsModule } from '@soco/forms';
import { SocoUIThemeModule } from '@soco/ui-theme';

//webauth modules
import { CoreModule } from '../core/core.module';

//components
//DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { OAuthConsentComponent } from './consent/oauth-consent.component';
import { OAuthGrantsComponent } from './grants/oauth-grants.component';

const MODULE_DIRECTIVES = [
    OAuthConsentComponent,
    OAuthGrantsComponent
];

@NgModule({
    imports: [
        //angular modules
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,

        //soco modules
        SocoAlertModule,
        SocoCommonModule,
        SocoFormsModule,
        SocoUIThemeModule,

        //webauth modules
        CoreModule
    ],
    exports: MODULE_DIRECTIVES,
    declarations: MODULE_DIRECTIVES
})
export class OAuthModule { }