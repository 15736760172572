<div class="container">
    <div *ngIf="consentView">
        <div>
            <div class="mb-4 text-center" *ngIf="consentView.clientLogoUrl"><img src="{{consentView.clientLogoUrl}}" /></div>
            <div class="mb-4"><h2>{{consentView.clientName}} is requesting your permission</h2></div>
            <div class="mb-4">Uncheck the permissions you do not wish to grant.</div>
        </div>
        <soco-form *ngIf="form" [formGroup]="form" [form]="form" (formSubmitted)="formSubmitted($event)">
            <soco-form-body>
                <soco-form-section *ngIf="consentView.identityScopes && consentView.identityScopes.length > 0" title="Personal Information" class="mb-4">
                    <ng-container formArrayName="identityScopes">
                        <div *ngFor="let apiScope of identityScopesFormArray.controls; let i = index" >
                           <mat-checkbox [formControlName]="i">
                               <mat-icon *ngIf="consentView.identityScopes[i].emphasize" fontIcon="fa-exclamation-circle"></mat-icon>
                               {{consentView.identityScopes[i].displayName}}
                               <span *ngIf="consentView.identityScopes[i].required"><em>(required)</em></span>
                               <span *ngIf="consentView.identityScopes[i].description"> - {{consentView.identityScopes[i].description}}</span>
                            </mat-checkbox>
                        </div>
                     </ng-container>
                </soco-form-section>    
                <soco-form-section *ngIf="consentView.apiScopes && consentView.apiScopes.length > 0" title="Application Access" class="mb-4">
                    <ng-container formArrayName="apiScopes">
                        <div *ngFor="let apiScope of apiScopesFormArray.controls; let i = index" >
                           <mat-checkbox [formControlName]="i">
                               <mat-icon *ngIf="consentView.apiScopes[i].emphasize" fontIcon="fa-exclamation-circle"></mat-icon>
                               {{consentView.apiScopes[i].displayName}}
                               <span *ngIf="consentView.apiScopes[i].required"><em>(required)</em></span>
                               <span *ngIf="consentView.apiScopes[i].description"> - {{consentView.apiScopes[i].description}}</span>
                            </mat-checkbox>
                        </div>
                     </ng-container>
                </soco-form-section>   
                <soco-form-section title="Description" class="mb-4">
                    <mat-form-field>
                        <mat-label>Description or name of device</mat-label>
                        <input matInput formControlName="description">
                    </mat-form-field>
                    <mat-checkbox *ngIf="consentView.allowRememberConsent" formControlName="rememberConsent">Remember My Decision</mat-checkbox>
                </soco-form-section>    
            </soco-form-body>   
            <soco-form-footer>
                <button mat-raised-button color="primary" type="submit">Yes, Allow</button>
                <a mat-button (click)="deny()">No, Do Not Allow</a>
        </soco-form-footer>
        </soco-form>
    </div>
    <soco-system-message *ngIf="isLoading" messageType="Loading" message="One moment please..."></soco-system-message>
</div> 