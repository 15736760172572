import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// DSS Documentation on ApiResult - http://tiny.sc/cgapiresult
import { ApiResult } from '@soco/core';

import { ApiResultFailureOptions } from '../core/api-result.service';
import { WebAuthHttpService } from '../core/webauth-http.service';
import { QueryStringService } from '../core/query-string.service';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    baseUrl: string = 'App';

    constructor(
        private http: WebAuthHttpService,
        private queryStringService: QueryStringService
    ) { }

    /**
     *  Gets the terms of service
     *  @param failureOptions The optional parameters that can be used to handle failures
     */
    getTermsOfService(failureOptions?: ApiResultFailureOptions): Observable<ApiResult<string>> {
        return this.http.get<string>({
            url: this.baseUrl + '/TermsOfService',
            failureOptions: failureOptions
        });
    }

}