//angular modules
//DSS Documentation on Angular - http://tiny.sc/cgangular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//soco modules
import { SocoAlertModule } from '@soco/alert';
import { SocoCommonModule } from '@soco/common';
import { SocoFormsModule } from '@soco/forms';
import { SocoUIThemeModule } from '@soco/ui-theme';

//components
//DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { ValidateEmailComponent } from './validate-email.component';
import { ValidateEmailCompleteComponent } from './complete/validate-email-complete.component';
import { ValidateEmailEditComponent } from './edit/validate-email-edit.component';
import { ValidateEmailLaterComponent } from './later/validate-email-later.component';
import { ValidateEmailSendComponent } from './send/validate-email-send.component';
import { CoreModule } from '../core/core.module';

const MODULE_DIRECTIVES = [
    ValidateEmailComponent, 
    ValidateEmailCompleteComponent,
    ValidateEmailEditComponent,
    ValidateEmailLaterComponent,
    ValidateEmailSendComponent
];

@NgModule({
    imports: [
        //angular modules
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,

        //soco modules
        SocoAlertModule,
        SocoCommonModule,
        SocoFormsModule,
        SocoUIThemeModule,

        CoreModule
    ],
    exports: MODULE_DIRECTIVES,
    declarations: MODULE_DIRECTIVES
})
export class ValidateEmailModule { }