// DSS Documentation on Angular - http://tiny.sc/cgangular
import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, AbstractControl } from '@angular/forms';
import { CrossValidatorDefinition } from './cross-validator-definition';

const ErrorName = 'formValuesAreEqualCrossValidator';

@Directive({
  selector: '[appFormValuesAreEqualCrossValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: FormValuesAreEqualCrossValidatorDirective, multi: true}]
})
export class FormValuesAreEqualCrossValidatorDirective implements Validator {
  @Input() private appFormValuesAreEqualCrossValidator: CrossValidatorDefinition[];

  validate(control: AbstractControl): ValidationErrors {
    this.appFormValuesAreEqualCrossValidator.forEach(equalSet => {
      const a = equalSet.controls[0];
      const b = equalSet.controls[1];
      let aValue: any = a.value;
      if (typeof aValue === 'string' && equalSet.ignoreCase)
        aValue = (<string>aValue).toLowerCase();
      let bValue: any = b.value;
      if (typeof bValue === 'string' && equalSet.ignoreCase)
        bValue = (<string>bValue).toLowerCase();
      if (!!aValue && !!bValue && aValue !== bValue) {
        let errors: ValidationErrors
        errors = b.errors || {};
        errors[ErrorName] = a;
        b.setErrors(errors);
      } else {
        if (b.hasError(ErrorName)) {
          delete b.errors[ErrorName];
          b.updateValueAndValidity();
        }
      }
    });
    return null;
  }
}
