// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// DSS Documentation on Angular Reuse - http://tiny.sc/cgangularreuse
// DSS Documentation on SocoFormService - http://tiny.sc/cgsocoformservice
import { SocoAlertService } from '@soco/alert';
import { SocoFormService } from '@soco/forms';

import { AppService } from 'src/app/app/app.service';
import { ApiResultService } from '../../core/api-result.service';
import { QueryStringService } from '../../core/query-string.service';
import { UserPasswordResetResult, UserPasswordResetData } from '../../user/user-password-reset-data';
import { UserService } from '../../user/user.service';

@Component({
  templateUrl: 'password-reset.component.html'
})
export class PasswordResetComponent implements OnInit, OnDestroy {
    form: FormGroup;
    alertId: string = 'password-reset-alert';
    isLoading: boolean = false;
    result: UserPasswordResetResult = null;
    ticket: string = this.queryStringService.ticket;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private appService: AppService,
        private apiResultService: ApiResultService,
        private queryStringService: QueryStringService,
        private userService: UserService,
        private socoAlertService: SocoAlertService,
        private socoFormService: SocoFormService,
        private el: ElementRef,
        private formBuilder: FormBuilder,
        private router: Router
    ) { }

    ngOnInit() {
        if (!this.ticket) {
            this.router.navigate(['/forgot/password'], { queryParamsHandling: 'preserve' });
            return;
        }
        this.form = this.formBuilder.group({
            usernameOrEmail: ['', Validators.required],
            password: ['', [Validators.required, Validators.maxLength(32), Validators.pattern(/^\S*$/)]],
            confirmPassword: ['', Validators.required]
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    formSubmitted(data: UserPasswordResetData): void {
        if (!this.form.valid) {
            this.socoFormService.scrollToFirstInvalidControl(this.el);
            return;
        }

        this.resetPassword(data);
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private resetPassword(data: UserPasswordResetData): void {
        this.isLoading = true;
        this.userService.resetPassword(data).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.result = result.data;
            if (this.result) {
                this.socoAlertService.success({ id: this.alertId, message: 'Your password has been reset!' });
            }
            this.isLoading = false;
        }, result => {
            this.isLoading = false;
            this.apiResultService.handleHttpErrorResponse(result, { alertId: this.alertId, form: this.form, el: this.el });
        });
    }
}
