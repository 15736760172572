//angular modules
//DSS Documentation on Angular - http://tiny.sc/cgangular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { QRCodeModule } from 'angularx-qrcode';

//soco modules
import { SocoAlertModule } from '@soco/alert';
import { SocoCommonModule } from '@soco/common';
import { SocoFormsModule } from '@soco/forms';
import { SocoUIThemeModule } from '@soco/ui-theme';
import { SocoModalModule } from '@soco/modal';

//components
//DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { MFAPhonePassCodeComponent } from './phone/pass-code/mfa-phone-pass-code.component';
import { MFAPhoneSelectionComponent } from './phone/selection/mfa-phone-selection.component';
import { MFARsaPassCodeComponent } from './rsa/mfa-rsa-pass-code.component';
import { OtpSelectionComponent } from './otp/selection/otp-selection.component'
import { OtpAuthenticatorNewComponent } from './otp/authenticator/new/otp-authenticator-new.component';
import { OtpPhoneNewComponent } from './otp/phone/new/otp-phone-new.component';
import { OtpEditMainComponent } from './otp/edit/otp-edit-main.component';
import { OtpConfirmationComponent } from './otp/confirmation/otp-confirmation.component';
import { OtpValidateComponent } from './otp/validate/otp-validate.component';
import { OtpSlotsComponent } from './otp/slots/otp-slots.component';
import { CoreModule } from '../core/core.module';

const MODULE_DIRECTIVES = [
    MFAPhonePassCodeComponent,
    MFAPhoneSelectionComponent,
    MFARsaPassCodeComponent,
    OtpSelectionComponent,
    OtpAuthenticatorNewComponent,
    OtpPhoneNewComponent,
    OtpEditMainComponent,
    OtpConfirmationComponent,
    OtpValidateComponent,
    OtpSlotsComponent
];

@NgModule({
    imports: [
        //angular modules
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatInputModule,
        MatIconModule,
        QRCodeModule,

        //soco modules
        SocoAlertModule,
        SocoCommonModule,
        SocoFormsModule,
        SocoUIThemeModule,
        SocoModalModule,

        //app modules
        CoreModule
    ],
    exports: MODULE_DIRECTIVES,
    declarations: MODULE_DIRECTIVES
})
export class MfaModule { }

