// DSS Documentation on Angular - http://tiny.sc/cgangular
// DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// DSS Documentation on Angular Reuse - http://tiny.sc/cgangularreuse
// DSS Documentation on SocoAlertService - http://tiny.sc/cgsocoalertservice
import { SocoAlertService } from '@soco/alert';

import { ApiResultService } from '../../core/api-result.service';
import { CustomerServiceComponent } from '../../core/customer-service/customer-service.component';
import { UserService } from '../../user/user.service';
import { UserForgotUsernameData } from '../../user/user-forgot-username-data';

@Component({
    templateUrl: 'forgot-username.component.html'
})
export class ForgotUsernameComponent implements OnInit, OnDestroy {
    form: FormGroup;
    alertId: string = 'forgot-username-alert';
    isLoading: boolean = false;
    result: UserForgotUsernameData = null;

    private unsubscribe$ = new Subject<void>();

    @ViewChild("customerService") customerService: CustomerServiceComponent;

    constructor(
        private apiResultService: ApiResultService,
        private userService: UserService,
        private socoAlertService: SocoAlertService,
        private formBuilder: FormBuilder
        ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            emailAddress: ['', [Validators.required, Validators.email]]
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================
    formSubmitted(data: UserForgotUsernameData): void {
        if (this.form.valid) {
            data.queryString = window.location.search;
            this.sendForgotUsername(data);
        }
    }

    forgotEmail(): void {
        this.customerService.expanded = true;
        this.socoAlertService.error({ id: this.alertId, scrollToAlert: true, message: 'If you do not know your email address, please contact customer service.' });
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private sendForgotUsername(data: UserForgotUsernameData): void {
        this.isLoading = true;
        this.userService.sendForgotUsername(data).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.result = result.data;
            if (this.result) {
                this.socoAlertService.success({ id: this.alertId, message: 'Username has been emailed!' });
            }
            this.isLoading = false;
        }, result => {
            this.isLoading = false;
            this.apiResultService.handleHttpErrorResponse(result, { alertId: this.alertId, form: this.form });
        });
    }
}
