
export class UserCreateData {
    constructor(data: UserCreateFormData) {
        this.username = data.username;
        this.password = data.password;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.emailAddress = data.emailAddress;
        this.acceptTOS = data.acceptTOS;
    }

    username: string = '';
    firstName: string = '';
    lastName: string = '';
    emailAddress: string = '';
    acceptTOS: boolean = false;
    password: string = '';
}

export class UserCreateFormData {
    username: string = '';
    password: string = '';
    retypePassword: string = '';
    firstName: string = '';
    lastName: string = '';
    emailAddress: string = '';
    retypeEmailAddress: string = '';
    acceptTOS: boolean = false;
}

export class UserCreateResult {
    sid: string = '';
    token: string = '';
}