export class MFAPhoneListData {
}

export class MFAPhoneListResult {
    phoneList: Array<PhoneField> = null;
}

export class PhoneField {
    name: string = '';
    fieldName: string = '';
    deliveryMethod: string = '';
    phoneMasked: string = '';
}