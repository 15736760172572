<div class="container">
    <div *ngIf="grantsView">
        <div>
            <h2>Client Application Permissions</h2>
            <p>Below is the list of applications you have given permission to and the resources they have access to.</p>
        </div>
        <soco-alert [alertId]="alertId"></soco-alert>
        <div *ngIf="grantsView.grants && grantsView.grants.length > 0">
            <div class="card mb-4" *ngFor="let grant of grantsView.grants">
                <div class="card-header">
                    <div class="mb-4 text-center" *ngIf="grant.clientLogoUrl"><img src="{{grant.clientLogoUrl}}" /></div>
                    <strong>{{grant.clientName}}</strong>
                </div>
                <div class="card-body">
                    <p class="card-text" *ngIf="grant.description"><label>Description:</label> {{grant.description}}</p>
                    <p class="card-text"><label>Created:</label> {{grant.created | date:'MM/dd/yyyy hh:mm a'}}</p>
                    <p class="card-text" *ngIf="grant.expires"><label>Expires:</label> {{grant.expires | date:'MM/dd/yyyy hh:mm a'}}</p>
                    <div *ngIf="grant.identityGrantNames && grant.identityGrantNames.length > 0">
                        <h5 class="card-title">Identity Grants</h5>
                        <ul>
                            <li *ngFor="let identityGrantName of grant.identityGrantNames">{{identityGrantName}}</li>
                        </ul>
                    </div>
                    <div *ngIf="grant.apiGrantNames && grant.apiGrantNames.length > 0">
                        <h5 class="card-title">API Grants</h5>
                        <ul>
                            <li *ngFor="let apiGrantName of grant.apiGrantNames">{{apiGrantName}}</li>
                        </ul>
                    </div>
                </div>
                <div class="card-footer">
                    <button mat-raised-button color="primary" (click)="revoke(grant)">Revoke Access</button>
                </div>
            </div>
        </div>
    </div>
    <soco-system-message *ngIf="isLoading" messageType="Loading" message="One moment please..."></soco-system-message>
</div>