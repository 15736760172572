// DSS Documentation on Angular - http://tiny.sc/cgangular
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpParams, HttpEventType, HttpResponse, HttpErrorResponse, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, filter } from 'rxjs/operators';
import { v4 } from 'uuid';

// DSS Documentation on ApiResult - http://tiny.sc/cgapiresult
import { ApiResult } from '@soco/core';

import { ApiResultService, ApiResultFailureOptions } from './api-result.service';
import { AppType } from '../app/app-type.enum';
import { environment } from '../../environments/environment';
import { QueryStringService } from './query-string.service';

@Injectable({
    providedIn: 'root'
})
export class WebAuthHttpService {

    constructor(
        private httpClient: HttpClient,
        private apiResultService: ApiResultService,
        private queryStringService: QueryStringService,
        private tokenService: HttpXsrfTokenExtractor
    ) { }

    /**
     *  Does an HTTP GET
     *  @param options The options to be used in the HTTP GET
     */
    get<T>(options: WebAuthHttpServiceOptions): Observable<ApiResult<T>> {
        options.verb = 'get';
        return this.webMethod<T>(options);
    }
    /**
     *  Does an HTTP POST
     *  @param options The options to be used in the HTTP POST
     */
    post<T>(options: WebAuthHttpServiceOptions): Observable<ApiResult<T>> {
        options.verb = 'post';
        return this.webMethod<T>(options);
    }
    /**
     *  Does an HTTP PUT
     *  @param options The options to be used in the HTTP PUT
     */
    put<T>(options: WebAuthHttpServiceOptions): Observable<ApiResult<T>> {
        options.verb = 'put';
        return this.webMethod<T>(options);
    }
    /**
     *  Does an HTTP PATCH
     *  @param options The options to be used in the HTTP PATCH
     */
    patch<T>(options: WebAuthHttpServiceOptions): Observable<ApiResult<T>> {
        options.verb = 'patch';
        return this.webMethod<T>(options);
    }
    /**
     *  Does an HTTP DELETE
     *  @param options The options to be used in the HTTP DELETE
     */
    del<T>(options: WebAuthHttpServiceOptions): Observable<ApiResult<T>> {
        options.verb = 'delete';
        return this.webMethod<T>(options);
    }

    private webMethod<T>(options: WebAuthHttpServiceOptions): Observable<ApiResult<T>> {
        // add base data
        if (options.verb === 'get' || options.verb === 'delete') {
            if (!options.params) {
                options.params = {};
            }
            options.params['WL_Type'] = AppType.toQueryStringValue(this.queryStringService.type);
            if (this.queryStringService.appId) {
                options.params['WL_AppId'] = this.queryStringService.appId
            }
            if (this.queryStringService.company) {
                options.params['Company'] = this.queryStringService.company;
            }
        } else if (!options.excludeDataAddCommonData) {
            if (!options.data) {
                options.data = {};
            }
            options.data['applicationType'] = AppType.toQueryStringValue(this.queryStringService.type);
            if (this.queryStringService.appId) {
                options.data['applicationId'] = this.queryStringService.appId
            }
            if (this.queryStringService.company) {
                options.data['company'] = this.queryStringService.company;
            }
        }

        // add the token and transaction id
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'x-transaction-id': this.getGuid(),
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache'
        });
        if (options.token) {
            headers = headers.append('Authorization', 'Bearer ' + options.token);
        }
        const xsrfToken = this.tokenService.getToken();
        if (xsrfToken !== null) {
            headers = headers.append('X-XSRF-TOKEN', xsrfToken);
        }

        // add the WebApiBaseUrl to the front of every URL unless we were told not to
        if (!options.excludeWebApiBaseUrl) {
            options.url = environment.webApiBaseUrl + options.url;
        }

        // include the params
        let params = new HttpParams();
        if (options.params) {
            for (const param in options.params) {
                if (options.params.hasOwnProperty(param) && options.params[param]) {
                    params = params.set(param, options.params[param]);
                }
            }
        }

        // build the request
        const httpRequest = new HttpRequest(options.verb, options.url, JSON.stringify(options.data), {
            headers,
            params,
            responseType: options.responseType,
            withCredentials: options.withCredentials
        });

        // send the request
        return this.httpClient.request<ApiResult<T>>(httpRequest)
            .pipe(
                filter(event => event.type === HttpEventType.Response)
            )
            .pipe(
                map((response: HttpResponse<ApiResult<T>>) => {
                    const result = response.body;
                    return result;
                }),
                catchError((err: HttpErrorResponse) => {
                    if (options.failureOptions) {
                        this.apiResultService.handleHttpErrorResponse(err, options.failureOptions);
                    }
                    return throwError(err);
                })
            );
    }

    getGuid(): string {
        const hexlist = '0123456789abcdef';
        const b64list = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

        function guidToBase64(guid: string, shouldUseLittleEndian: boolean): string {
            let parsedGuid = guid.replace(/[^0-9a-f]/ig, '').toLowerCase();
            if (parsedGuid.length !== 32) {
                return '';
            }

            if (shouldUseLittleEndian) {
                parsedGuid = parsedGuid.slice(6, 8) + parsedGuid.slice(4, 6) + parsedGuid.slice(2, 4) + parsedGuid.slice(0, 2) +
                    parsedGuid.slice(10, 12) + parsedGuid.slice(8, 10) +
                    parsedGuid.slice(14, 16) + parsedGuid.slice(12, 14) +
                    parsedGuid.slice(16);
            }
            parsedGuid += '0';

            let result = '';
            let i = 0;
            let a: any;
            let p: any;
            let q: any;
            while (i < 33) {
                a = (hexlist.indexOf(parsedGuid.charAt(i++)) << 8) |
                    (hexlist.indexOf(parsedGuid.charAt(i++)) << 4) |
                    (hexlist.indexOf(parsedGuid.charAt(i++)));

                p = a >> 6;
                q = a & 63;

                result += b64list.charAt(p) + b64list.charAt(q);
            }
            result += '==';

            return result;
        }

        // generate a guid
        const newGuid = v4();

        // base-64 encode the guid using Little-endian
        const b64encoded = guidToBase64(newGuid, true);

        // return the 22 character guid
        return b64encoded.replace('/', '_').replace('+', '-').substring(0, 22);
    }
}


export interface WebAuthHttpServiceOptions {
    /**
     *  The url of the API call being made
     */
    url: string;
    /**
     *  You do not need to specify this. When you use the appropriate function
     *  on SocoHttpService it will set the verb appropriately.
     */
    verb?: string;
    /**
     *  Data to be sent as the request message data.
     */
    data?: any;
    /**
     *  Map of strings or objects which will be serialized with the paramSerializer and appended as GET parameters.
     */
    params?: any;
    /**
     * The expected response type of the server.
     *
     * This is used to parse the response appropriately before returning it to
     * the requestee.
    */
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
    /**
     * Indicates whether or not cross-site Access-Control requests should be made using
     * credentials such as cookies
     */
    withCredentials?: boolean;
    /**
     *  All of your API calls should communicate with your Web API project where your API
     *  controllers are. To avoid having to prefix all of your URLs with that URL SocoHttpService
     *  does it for you. However, if you are calling some other API you can specify that you
     *  don't want SocoHttpService to prefix the URL with the Web API URL.
     */
    excludeWebApiBaseUrl?: boolean;
    /**
     *  Most of the API calls with a request body will need to include application id, application type
     * and company. If you do not wish to include those values set this to true. 
     */
    excludeDataAddCommonData?: boolean;
    /**
     * The user's token with WebAuth audience set
     */
    token?: string;
    /**
     * Options to use when handling failures
     */
    failureOptions?: ApiResultFailureOptions;
}