//DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MFA2FASlot } from '../../mfa-2fa-slot.enum';
import { MFA2FAMethod } from '../../mfa-2fa-method.enum';
import { MFAService } from '../../mfa.service';

@Component({
    templateUrl: 'otp-edit-main.component.html'
})
export class OtpEditMainComponent implements AfterViewInit, OnDestroy  {
    alertId: string = 'otp-edit-main-alert';
    isLoading: boolean = false;
    primaryOtpConfigMethod: MFA2FAMethod;
    secondaryOtpConfigMethod: MFA2FAMethod;
    primaryOtpConfigDesc: string = "Not configured";
    secondaryOtpConfigDesc: string = "Not configured";
    statusPrimary: boolean = false;
    statusSecondary: boolean = false;

    private unsubscribe$ = new Subject<void>();
    constructor(
        private mfaService: MFAService,
        private router: Router
    ) { }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.isLoading = true;
            this.getOTPConfigData();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    //=============================================================================================================
    //EVENT HANDLERS
    //=============================================================================================================

    //Navigate to Edit Individual OTP by passing the State as Primary.
    editPrimaryOtp(): void {
        //set page route to Edit Configuration
        //Need to UPDATE TO route to Config Edit Individual
        let route: string = '/mfa/otp/selection';
        let mfaOTPConfig: MFA2FASlot = MFA2FASlot.Primary;
        this.router.navigate([route], { queryParamsHandling: 'preserve', state: { data: { otpStateSlot: mfaOTPConfig } } });
    }

    //Navigate to Edit Individual OTP by passing the State as Secondary.
    editSecondaryOtp(): void {
        //set page route to Edit Configuration
        //Need to UPDATE TO route to Config Edit Individual
        let route: string = '/mfa/otp/selection';
        let mfaOTPConfig: MFA2FASlot = MFA2FASlot.Secondary;
        this.router.navigate([route], { queryParamsHandling: 'preserve', state: { data: { otpStateSlot: mfaOTPConfig } } });
    }

    //Go back to previous page.
    goBack(): void {
        history.back();
    }

    //=============================================================================================================
    //WEB METHODS
    //=============================================================================================================
    private getOTPConfigData(): void {
        //Display the ability to modify 2FA and if they currently have it enabled or disabled. 
        this.mfaService.get2faConfig({ alertId: this.alertId }).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.primaryOtpConfigMethod = result.data?.primary.method;
            if(this.primaryOtpConfigMethod != MFA2FAMethod.None && this.primaryOtpConfigMethod != null){
                this.statusPrimary = true;
            }
            if(result.data?.primary.description != null && result.data?.primary.description != ''){
                this.primaryOtpConfigDesc = result.data?.primary.description;
            }
            this.secondaryOtpConfigMethod = result.data?.secondary.method;
            if(this.secondaryOtpConfigMethod != MFA2FAMethod.None && this.secondaryOtpConfigMethod != null){
                this.statusSecondary = true;
            }
            if(result.data?.secondary.description != null && result.data?.secondary.description != ''){
                this.secondaryOtpConfigDesc = result.data?.secondary.description;
            }
            this.isLoading = false;
        });
    }

}
