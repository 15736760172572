<div class="container">
    <soco-alert [alertId]="alertId"></soco-alert>
    <soco-form *ngIf="form" [formGroup]="form" [form]="!isLoading && form" (formSubmitted)="formSubmitted($event)">
        <soco-form-body>
            <soco-form-section title="Select Method">
                <mat-radio-group formControlName="slot">
                    <mat-radio-button *ngFor="let otpSlot of otpSlots" [value]="otpSlot">
                        <div>{{ otpMethodPhrase(otpSlot) }}</div>
                        <div *ngIf="otpDescription(otpSlot)" class="small font-weight-light text-muted">{{ otpDescription(otpSlot) }}</div>
                    </mat-radio-button>
                </mat-radio-group>
            </soco-form-section>
        </soco-form-body>
        <soco-form-footer class="text-center">
            <button mat-raised-button color="primary" type="submit" class="mb-3" [disabled]="!form.valid">Next</button>
            <a mat-button class="w-100 mb-3" (click)="cancel()">Cancel</a>
        </soco-form-footer>
    </soco-form>
    <soco-system-message *ngIf="isLoading" message="Loading..." messageType="Loading"></soco-system-message>
</div>