export class OAuthConsentInput {

    constructor(button: string, scopesConsented: string[], rememberConsent: boolean, returnUrl: string, description: string) {
        this.button = button;
        this.scopesConsented = scopesConsented;
        this.rememberConsent = rememberConsent;
        this.returnUrl = returnUrl;
        this.description = description;
    }

    button: string = '';
    scopesConsented: string[] = [];
    rememberConsent: boolean = false;
    returnUrl: string = '';
    description: string = '';
}