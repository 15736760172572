//angular modules
//DSS Documentation on Angular - http://tiny.sc/cgangular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

//soco modules
import { SocoAlertModule } from '@soco/alert';
import { SocoCommonModule } from '@soco/common';
import { SocoFormsModule } from '@soco/forms';
import { SocoUIThemeModule } from '@soco/ui-theme';

//webauth modules
import { CoreModule } from '../core/core.module';

//components
//DSS Documentation on Angular Components - http://tiny.sc/cgangularcomponent
import { LoginComponent } from './login.component';

const MODULE_DIRECTIVES = [
    LoginComponent
];

@NgModule({
    imports: [
        //angular modules
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatCheckboxModule,

        //soco modules
        SocoAlertModule,
        SocoCommonModule,
        SocoFormsModule,
        SocoUIThemeModule,

        //webauth modules
        CoreModule
    ],
    exports: MODULE_DIRECTIVES,
    declarations: MODULE_DIRECTIVES
})
export class LoginModule { }

